// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { ref, onUnmounted } from "vue";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC6OPy8XfFMCS5LFP9SR2o1ljD89LmoS7A",
    authDomain: "landing-provinsi-gorontalo.firebaseapp.com",
    projectId: "landing-provinsi-gorontalo",
    storageBucket: "landing-provinsi-gorontalo.appspot.com",
    messagingSenderId: "1012811593498",
    appId: "1:1012811593498:web:7683862ab762c9f71bdea3",
};

// Initialize Firebase
export const FirebaseApp = initializeApp(firebaseConfig);
// export const dbFireStore = FirebaseApp.firestore();
export const dbFireStore = getFirestore(FirebaseApp);
export const authFire = getAuth(FirebaseApp);

// export