<script>
import ApiLaporan from "@/store/api/laporan";
import { onMounted, reactive, toRefs } from "vue";
import { isLoading } from "@/store/reactive.js";
import { useRoute } from "vue-router";
import { getCurrentInstance } from "vue";

export default {
    name: "Laporan All",
    setup() {
        const app = getCurrentInstance();
        const globalProperties = app.appContext.config.globalProperties;

        const route = useRoute();
        const state = reactive({
            datas: [],
            dataCategorys: [],
            form: {
                category: "",
            },
            page: 1,
            isNext: false,
        });
        onMounted(async () => {
            if (route.query.category) {
                state.form.category = route.query.category;
            }
            await getData();
        });
        const getData = async () => {
            isLoading.value = true;
            try {
                const page = state.page;
                const formCategory = state.form.category;
                const { data, category, paginate } = await ApiLaporan.getAll(
                    page,
                    formCategory
                );
                state.datas = [...state.datas, ...data];
                state.dataCategorys = category;
                state.page = state.page + 1;
                state.isNext = paginate.isNext;
            } catch (error) {
                globalProperties.$wkToast(error, {
                    className: "wk-alert",
                });
            } finally {
                isLoading.value = false;
            }
        };
        const hendelLink = (data) => {
            console.log("data", data);
            if (data.in_link) {
                router.push(data.in_link);
            } else {
                return (window.location = data.link);
            }
        };
        const hendelCategory = async () => {
            state.page = 1;
            state.datas = [];
            await getData();
        };
        const hendelNext = async () => {
            await getData();
        };
        return {
            ...toRefs(state),
            isLoading,
            hendelLink,
            hendelCategory,
            hendelNext,
        };
    },
};
</script>
<template>
    <section class="text-gray-600 body-font overflow-hidden mx-20">
        <div class="container py-10">
            <div class="flex flex-col items-center justify-center gap-10">
                <div class="w-full">
                    <select
                        @change="hendelCategory"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        v-model="form.category"
                    >
                        <option value="">-- Pilih Kategory --</option>
                        <option
                            v-for="category in dataCategorys"
                            :key="category"
                            :value="category.id"
                        >
                            {{ category.name }}
                        </option>
                    </select>
                </div>
                <div class="w-full">
                    <div class="grid lg:grid-cols-4 gap-5">
                        <div
                            class="w-full"
                            v-for="(data, key) in datas"
                            :key="`dataLaporan${key}`"
                        >
                            <div class="max-w-sm mx-auto">
                                <div
                                    class="mb-6 max-w-max overflow-hidden rounded-xl h-64 w-full"
                                >
                                    <img
                                        class="transform hover:scale-105 transition ease-in-out duration-1000"
                                        :src="data.image"
                                        alt=""
                                        data-config-id="auto-img-1-2"
                                    />
                                </div>
                                <a
                                    class="mb-2 inline-block hover:text-gray-800 hover:underline"
                                >
                                    <h3
                                        class="text-xl font-bold font-heading leading-normal dark:text-slate-300"
                                        data-config-id="auto-txt-5-2"
                                    >
                                        <a
                                            @click="hendelLink(data)"
                                            @mouseenter="hendelBaca"
                                            >{{ data.name }}</a
                                        >
                                    </h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full flex flex-start justify-center">
                    <div>
                        <button
                            v-if="isNext && !isLoading"
                            @click="hendelNext"
                            class="bg-blue-600 text-white py-2 px-5 rounded-lg"
                        >
                            Selanjutnya
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
