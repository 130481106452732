import { dbFireStore } from "@/firebase.js";
import {
    collection,
    query,
    getDocs,
    addDoc,
    Timestamp,
    deleteDoc,
    doc,
    updateDoc,
    deleteField,
    setDoc,
    where,
    orderBy,
} from "firebase/firestore";
// import Toast from "vue3-toast-single";
// import { ref, onUnmounted } from "vue";

const nameTable = "grups";
const grupColletion = collection(dbFireStore, nameTable);
// const q = (cari = "" , ) => {
//     return query(grupColletion , where());
// };

export const createGrup = async(grup) => {
    if (grup.name == "") {
        throw "Name tidak boleh ksong";
        // return false;
    }
    try {
        const dataInsert = {
            name: grup.name,
            logo: grup.logo,
            color_logo: grup.color_logo,
            waktuInser: Timestamp.fromDate(new Date()),
            status: grup.status,
            show: grup.show,
        };
        const simpan = await addDoc(grupColletion, dataInsert);
        return simpan.id;
    } catch (error) {
        const errorCode = error.code;
        // const errorMessage = error.message;
        throw errorCode;
    }
};

export const useLoadGrups = async() => {
    let grups = [];
    try {
        const close = await getDocs(
            query(grupColletion, orderBy("waktuInser", "desc"))
        );
        close.forEach((doc) => {
            let data = {
                id: doc.id,
                ...doc.data(),
            };
            grups.push(data);
        });
        return grups;
    } catch (error) {
        const errorCode = error.code;
        // const errorMessage = error.message;
        throw errorCode;
    }
};
export const useLoadGrupsPublic = async() => {
    let grups = [];
    try {
        const close = await getDocs(
            query(
                grupColletion,
                where("status", "==", "active"),
                where("show", "==", "active")
            )
        );
        close.forEach((doc) => {
            let data = {
                id: doc.id,
                ...doc.data(),
            };
            grups.push(data);
        });
        return grups;
    } catch (error) {
        const errorCode = error.code;
        // const errorMessage = error.message;
        throw errorCode;
    }
};

export const deleteGrup = async(id) => {
    try {
        // const hapusFilde = "";
        await updateDoc(doc(dbFireStore, nameTable, id), {
            color_logo: deleteField(),
            logo: deleteField(),
            waktuInser: deleteField(),
            name: deleteField(),
            status: deleteField(),
            show: deleteField(),
        });
        await deleteDoc(doc(dbFireStore, nameTable, id));
        return true;
    } catch (error) {
        const errorCode = error.code;
        throw errorCode;
    }
};

export const importGrups = async(data = []) => {
    for (let index = 0; index < data.length; index++) {
        try {
            await setDoc(doc(dbFireStore, nameTable, data[index].id), {
                name: data[index].name,
                logo: data[index].logo,
                color_logo: data[index].color_logo,
                waktuInser: data[index].waktuInser,
                status: data[index].status,
                show: data[index].show,
            });
        } catch (error) {
            const errorCode = error.code;
            // const errorMessage = error.message;
            throw errorCode;
        }
    }
    return "Berhasil import";
};

export const updateGrup = async(id, grup) => {
    if (grup.name == "") {
        throw "Name tidak boleh kosong";
    }
    try {
        await setDoc(doc(dbFireStore, nameTable, id), {
            color_logo: grup.color_logo,
            logo: grup.logo,
            waktuInser: Timestamp.fromDate(new Date()),
            name: grup.name,
            status: grup.status,
            show: grup.show,
        });
        return true;
        // return update;
    } catch (error) {
        const errorCode = error.code;
        // const errorMessage = error.message;
        throw errorCode;
    }
    // return grupColletion.doc(id).update(grup);
};

// export const getGrup = async(id) => {
//     const grup = await grupColletion.doc(id).get();
//     return grup.exists ? grup.data() : null;
// };