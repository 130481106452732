<script>
import Loading from "@/components/Loading/Default.vue";
import BelakanNavbar from "@/components/Navbars/BelakanNavbar.vue";
import BelakanSidebar from "@/components/Sidebar/BelakanSidebar.vue";
import BelakanFooter from "@/components/Footers/BelakanFooter.vue";

export default {
    name: "admin-layout",
    components: {
        BelakanNavbar,
        BelakanSidebar,
        BelakanFooter,
        Loading,
    },
};
</script>

<template>
    <div>
        <Loading />
        <BelakanSidebar />
        <div class="relative md:ml-64 bg-blueGray-100">
            <BelakanNavbar />
            <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12"></div>
            <!-- <div id="teleportasi"></div> -->
            <div class="px-4 md:px-10 mx-auto w-full -m-24">
                <router-view />

                <BelakanFooter />
            </div>
        </div>
    </div>
</template>
