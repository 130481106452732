<script>
import { RouterLink, RouterView } from "vue-router";
export default {};
// import HelloWorld from "@/components/HelloWorld.vue";
</script>
<template>
    <div id="app">
        <RouterView />
    </div>
</template>
