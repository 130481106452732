<script>
import Slaider from "../../Slaider/v2/Default.vue";
import Helper from "@/store/helper";
import InformasiDanLayanan from "@/store/api/informasiDanLayanan";
import SvgPanahComponent from "../../Svg/Panah.vue";
import InformasiDanLayanaJpg from "@/assets/jpg/Informasi-dan-layanan.jpg";

export default {
    setup() {
        return { InformasiDanLayanaJpg };
    },
    components: {
        Slaider,
        SvgPanahComponent
    },
    data() {
        return {
            datas: [],
            dataDetail: null
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.datas = [];
            try {
                const data = await InformasiDanLayanan.getData();
                const datas = data.data;
                if (datas.length > 0) {
                    if (datas[0].sub_category_layanans.length > 0) {
                        this.setDataDetail(datas[0].sub_category_layanans[0]);
                    }
                }
                this.datas = datas;
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert"
                });
            }
        },
        setDataDetail(data) {
            this.dataDetail = data;
        },
        hendelDetailLayanan(id, id2) {
            console.log("id ,id2", id, id2);
            const dataTampung = JSON.parse(JSON.stringify(this.datas));
            const dataSemetara = dataTampung.find(el => {
                return el.id == id;
            });

            const dataSemtara2 = dataSemetara.sub_category_layanans.find(
                el2 => {
                    return el2.id == id2;
                }
            );
            this.setDataDetail(dataSemtara2);
            // this.dataDetail = dataSemtara2;
        },
        hendelBaca(e) {
            if (!this.$store.state.voice.isVoice) {
                return true;
            }
            let text = e.target.textContent;
            if (!text.trim()) {
                text = e.target.getAttribute("alt");
                if (!text) {
                    text = e.target.getAttribute("title");
                }
            }
            Helper.startVoice(text);
        },
        hendelDetailInformasi(category, id) {
            if (
                category == this.dataDetail.category_id &&
                id == this.dataDetail.id
            ) {
                return true;
            }
            return false;
        }
    }
};
</script>

<template>
    <section
        data-section-id="1"
        data-share
        data-category="footers"
        data-component-id="7fd1b1dd_04_awz"
        class="relative bg-indigo-600 dark:bg-indigo-600/40 overflow-hidden"
        style=" background-position: center;"
        :style="`background-image: url('${InformasiDanLayanaJpg}');`"
    >
        <h2
            class="mb-4 mt-10 md:text-5xl text-2xl text-center font-bold font-heading tracking-px-n leading-tight text-white dark:text-slate-200"
        >Informasi Dan Layanan</h2>
        <!-- media sqreen di atas laptop -->
        <div class="mt-5 hidden xl:block">
            <div class="flex md:flex-row flex-col gap-5 md:mx-32 mx-2 items-start justify-center">
                <div class="w-full overflow-y-auto h-96">
                    <template v-for="(data, key) in datas" :key="`infromasiDanLayananData${key}`">
                        <h5
                            v-if="data.sub_category_layanans.length > 0"
                            class="text-white font-bold mb-2 text-2xl dark:text-slate-300"
                        >{{ data.name }}</h5>
                        <div class="grid md:grid-cols-2 grid-cols gap-4">
                            <button
                                v-for="(sub, key) in data.sub_category_layanans"
                                :key="`subKategory${key}`"
                                @click="hendelDetailLayanan(data.id, sub.id)"
                                @mouseenter="hendelBaca"
                                class="bg-white py-3 rounded-lg text-center dark:bg-gray-900 dark:text-slate-200 hover:opacity-70 dark:hover:opacity-40"
                            >{{ sub.name }}</button>
                        </div>
                    </template>
                </div>

                <div class="w-full" v-if="dataDetail">
                    <div class>
                        <div class="w-full flex flex-col sm:flex-row justify-center gap-2 h-96">
                            <div
                                class="w-full min-h-50 mt-10 bg-white bg-no-repeat bg-center bg-cover overflow-hidden"
                                :style="`background-image: url('${dataDetail.image}');border-radius:10px`"
                            ></div>
                        </div>
                    </div>
                </div>

                <div class="w-full md:w-1/2 mt-10" v-if="dataDetail">
                    <h3 class="text-2xl font-semibold text-white dark:text-white">
                        <router-link
                            :to="`/informasi-layanan/${dataDetail.id}/${dataDetail.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')}`"
                        >{{ dataDetail.name }}</router-link>
                    </h3>
                    <hr class="h-px mb-8 bg-gray-200 border-0 dark:bg-gray-700" />
                    <ul
                        class="max-w-md space-y-1 text-white list-none list-inside dark:text-gray-400 overflow-y-auto h-96"
                    >
                        <li
                            class="flex items-center"
                            v-for="(
                                            dataLayana, key
                                        ) in dataDetail.layanans"
                            :key="`datalayananDetail${key}`"
                        >
                            <svg
                                class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                            <template v-if="dataLayana.in_link">
                                <router-link :to="dataLayana.in_link">{{ dataLayana.name }}</router-link>
                            </template>
                            <template v-if="!dataLayana.in_link">
                                <a
                                    target="_blank"
                                    :href="dataLayana.link"
                                    class="tetx-4xl"
                                >{{ dataLayana.name }}</a>
                            </template>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- media sqreen di bawah -->
        <div class="mt-5 xl:hidden block">
            <div class="mb-11 flex flex-wrap -m-1 px-5">
                <template v-for="(data, key) in datas" :key="`infromasiDanLayananDataMobile${key}`">
                    <div
                        class="w-full p-1"
                        v-for="(sub, key) in data.sub_category_layanans"
                        :key="`subKategoryMobile${key}`"
                    >
                        <a @click="hendelDetailLayanan(data.id, sub.id)">
                            <div
                                class="py-5 px-8 bg-white items-center border-2 border-indigo-600 rounded-2xl shadow-10xl"
                            >
                                <div class="flex flex-wrap justify-between -m-2">
                                    <div class="flex-1 p-2">
                                        <h3
                                            class="md:text-lg text-sm font-semibold leading-normal"
                                            data-config-id="auto-txt-3-1"
                                        >{{ sub.name }}</h3>
                                        <div
                                            v-if="hendelDetailInformasi(data.id, sub.id)"
                                            class="text-gray-600 font-medium"
                                            data-config-id="auto-txt-4-1"
                                        >
                                            <ul
                                                class="max-w-md space-y-1 text-white list-none list-inside dark:text-gray-400"
                                            >
                                                <li
                                                    class="flex items-center md:text-base text-sm"
                                                    v-for="(
                                            dataLayana, key
                                        ) in dataDetail.layanans"
                                                    :key="`datalayananDetailMobile${key}`"
                                                >
                                                    <svg
                                                        class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        />
                                                    </svg>
                                                    <template v-if="dataLayana.in_link">
                                                        <router-link
                                                            class="tetx-4xl text-slate-800"
                                                            :to="dataLayana.in_link"
                                                        >{{ dataLayana.name }}</router-link>
                                                    </template>
                                                    <template v-if="!dataLayana.in_link">
                                                        <a
                                                            target="_blank"
                                                            :href="dataLayana.link"
                                                            class="tetx-4xl text-slate-800"
                                                        >{{ dataLayana.name }}</a>
                                                    </template>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="w-auto p-2">
                                        <SvgPanahComponent
                                            :type="hendelDetailInformasi(data.id, sub.id)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>
