<script>
import { createPopper } from "@popperjs/core";
import image from "@/assets/img/team-1-800x800.jpg";
import gravatar from "gravatar";

export default {
    setup() {
        return { gravatar };
    },
    data() {
        return {
            dropdownPopoverShow: false,
            image: image,
        };
    },
    methods: {
        toggleDropdown: function (event) {
            event.preventDefault();
            if (this.dropdownPopoverShow) {
                this.dropdownPopoverShow = false;
            } else {
                this.dropdownPopoverShow = true;
                createPopper(
                    this.$refs.btnDropdownRef,
                    this.$refs.popoverDropdownRef,
                    {
                        placement: "bottom-start",
                    }
                );
            }
        },
        logout() {
            this.$store.dispatch("logout");
        },
    },
};
</script>
<template>
    <div>
        <a
            class="text-blueGray-500 block"
            href="#pablo"
            ref="btnDropdownRef"
            v-on:click="toggleDropdown($event)"
        >
            <div class="items-center flex">
                <span
                    class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
                >
                    <img
                        alt="..."
                        class="w-full rounded-full align-middle border-none shadow-lg"
                        :src="
                            $store.getters.user
                                ? gravatar.url($store.getters.user.email, {
                                      s: '100',
                                      r: 'pg',
                                  })
                                : ''
                        "
                    />
                </span>
            </div>
        </a>
        <div
            ref="popoverDropdownRef"
            class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
            v-bind:class="{
                hidden: !dropdownPopoverShow,
                block: dropdownPopoverShow,
            }"
        >
            <a
                href="javascript:void(0);"
                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
                Profil ({{
                    $store.getters.user ? $store.getters.user.email : ""
                }})
            </a>
            <div class="h-0 my-2 border border-solid border-blueGray-100" />
            <a
                @click="logout"
                href="javascript:void(0);"
                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
                Logout
            </a>
        </div>
    </div>
</template>
