<script>
import IndexGrup from "@/components/Belakan/Grup/Index.vue";

export default {
    components: {
        IndexGrup,
    },
};
</script>
<template>
    <div>
        <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
                <IndexGrup />
            </div>
        </div>
    </div>
</template>
