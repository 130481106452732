import { useStorage } from "vue3-storage";
export const storage = useStorage();

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const Chache = {
    // set(key, data, time = 60 * 60) {
    set(key, data, time = 10 * 1) {
        // 10 detik
        storage.setStorageSync(key, data);
        cookies.set(key, key, time);
        return true;
    },
    get(key) {
        if (cookies.get(key)) {
            const data = storage.getStorageSync(key);
            if (data != undefined) {
                return data;
            }
            return false;
        }
        return false;
    },
    remove(key) {
        storage.removeStorageSync(key);
        return true;
    },
};

export default Chache;
