<script>
import ApiBerita from "@/store/api/berita.js";
import ApiMajalah from "@/store/api/majalah.js";
import { onMounted, reactive, toRefs } from "vue";
import IgSlaider from "./IgSlaider.vue";
import ComponenBerita from "./Berita.vue";
import CompoenenMajalah from "./MajalahSlaider.vue";
import CompoenenInfoPublic from "./InfoPublicSlaider.vue";
import GPRWidged from "@/components/Sidebar/GPRWidged.vue";
import ComponentKlipingBerita from "./KlipingBerita.vue";


import { useRouter } from "vue-router";

export default {
    components: {
        IgSlaider,
        ComponenBerita,
        CompoenenMajalah,
        CompoenenInfoPublic,
        GPRWidged,
        ComponentKlipingBerita
    },
    setup() {
        const router = useRouter();
        const state = reactive({
            datas: [],
            datasMajalah: []
        });
        onMounted(async () => {
            await getDataMajalah();
            await getData();
        });
        const getDataMajalah = async () => {
            state.datasMajalah = [];
            try {
                const { data } = await ApiMajalah.getData();
                state.datasMajalah = data;
                // console.log("dataMajalah", data);
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert"
                });
            }
        };
        const getData = async () => {
            state.datas = [];
            try {
                const data = await ApiBerita.feed();
                state.datas = data.data.slice(0, 5);
                // console.log("dataBerita", data);
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert"
                });
            }
        };
        const hendelLink = data => {
            // console.log("data", data);
            if (data.in_link) {
                router.push(data.in_link);
            } else {
                return (window.location = data.link);
            }
        };
        return {
            ...toRefs(state),
            hendelLink
        };
    }
};
</script>

<template>
    <div class="mx-2 md:mx-20 lg:mx-52">
        <h2
            class="mb-10 mt-10 md:text-5xl text-3x text-center font-bold font-heading tracking-px-n leading-tight dark:text-slate-200"
        >Berita dan Majalah</h2>
        <div class="flex flex-col lg:flex-row items-start justify-center gap-10">
            <ComponenBerita :datas="datas" />
            <div class="lg:w-1/2 w-full flex flex-col items-center justify-center gap-5">
                <CompoenenMajalah :datas="datasMajalah" />
                <!-- <CompoenenInfoPublic /> -->
                <GPRWidged />
            </div>
        </div>
        <div class="flex flex-col lg:flex-row items-start justify-center gap-10">
            <ComponentKlipingBerita/>
        </div>
    </div>
</template>
