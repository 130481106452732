const style = {
    namespaced: true,
    state: {
        color: "bg-emerald-300",
        logo: "fas fa-heart",
        fontSize: "",
        abuAbu: "",
        kontraks: "",
        klise: "",
        penerangan: "",
        garisBawah: "",
        textTegas: "",
    },
    mutations: {
        SET_LOGO(state, color) {
            state.logo = color;
        },
        SET_COLOR(state, color) {
            state.color = color;
        },
        SET_FONT_SIZE(state, ukuran) {
            if (ukuran < 1) {
                return (state.fontSize = "");
            }

            ukuran = ukuran * 10 + 120;
            const nilai = `all-resize-font-${ukuran}`;
            state.fontSize = nilai;
        },
        SET_ABU_ABU(state, bool) {
            state.abuAbu = "";
            if (bool) {
                state.abuAbu = "grayscale";
            }
        },
        SET_KONTRAKS(state, bool) {
            state.kontraks = "";
            if (bool) {
                state.kontraks = "pojo-a11y-high-contrast";
            }
        },
        SET_KLISE(state, bool) {
            state.klise = "";
            if (bool) {
                state.klise = "pojo-a11y-negative-contrast";
            }
        },
        SET_PENERANGAN(state, bool) {
            state.penerangan = "";
            if (bool) {
                state.penerangan = "pojo-a11y-light-background";
            }
        },
        SET_GARIS_BAWAH(state, bool) {
            state.garisBawah = "";
            if (bool) {
                state.garisBawah = "pojo-a11y-links-underline";
            }
        },
        SET_TEXT_TEGAS(state, bool) {
            state.textTegas = "";
            if (bool) {
                state.textTegas = "pojo-a11y-readable-font";
            }
        },
    },
    actions: {
        setColor({ commit }, data) {
            commit("SET_COLOR", data);
        },
        setLogo({ commit }, data) {
            commit("SET_LOGO", data);
        },
        setFontSize({ commit }, data) {
            commit("SET_FONT_SIZE", data);
        },
        setAbuAbu({ commit }, data) {
            commit("SET_ABU_ABU", data);
        },
        setKontraks({ commit }, data) {
            commit("SET_KONTRAKS", data);
        },
        setKlise({ commit }, data) {
            commit("SET_KLISE", data);
        },
        setPenerangan({ commit }, data) {
            commit("SET_PENERANGAN", data);
        },
        setGarisBawah({ commit }, data) {
            commit("SET_GARIS_BAWAH", data);
        },
        setTextTegas({ commit }, data) {
            commit("SET_TEXT_TEGAS", data);
        },
    },
};

export default style;
