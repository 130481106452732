<script>
import IndexAplikasi from "@/components/Belakan/Aplikasi/Index.vue";

export default {
    components: {
        IndexAplikasi,
    },
};
</script>
<template>
    <div>
        <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
                <IndexAplikasi />
            </div>
        </div>
    </div>
</template>
