const Helper = {
    startVoice(text = "") {
        if ("speechSynthesis" in window) {
            // responsiveVoice.speak(text, "Indonesian Female");
            // console.log("tes", text);
            const lang = document.documentElement.getAttribute("lang");
            // console.log(lang, "lang 1");
            const synth = window.speechSynthesis;
            const voices = synth.getVoices();
            // console.log('voices', voices);
            const utterThis = new SpeechSynthesisUtterance(text);
            utterThis.volume = 1; // Volume
            // utterThis.rate = 1; // 0.1 sampai 10
            utterThis.pitch = 1; // 0 sampai 2
            if (lang !== "en") {
                utterThis.lang = "id-ID"; // Volume
                utterThis.voice = voices[6];
            } else {
                utterThis.voice = voices[10];
            }
            synth.cancel();
            synth.speak(utterThis);
            // console.log("synth", synth);
        } else {
            alert("Mode Suara Tidak Suport Dengan Browser Anda ");
        }
    },
    getText() {
        let text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type != "Control") {
            text = document.selection.createRange().text;
        }
        return text;
    },
};

export default Helper;
