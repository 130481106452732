<script>
import { useRouter } from "vue-router";
import { Carousel, Navigation, Slide } from "vue3-carousel";
export default {
    name: "Slaider Majalah",
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    props: {
        datas: {
            type: Array,
            default: [],
        },
    },
    setup() {
        const router = useRouter();
        const hendelLink = (data) => {
            console.log("data", data);
            router.push(`/majalah/${data.id}/baca`);
            // if (data.in_link) {
            // } else {
            //     return window.open(data.link, "_blank");
            // }
        };
        return { hendelLink };
    },
};
</script>
<template>
    <div
        class="w-full flex flex-col items-center justify-center shadow-lg rounded-md pt-2 px-2 gap-2 bg-white dark:bg-gray-700"
    >
        
        <div
            class="w-full flex flex-col items-start justify-center rounded-md py-5 px-2 gap-2"
        >
            <div class="w-full" style="text-align: -webkit-center">
                <div class="grid grid-cols-1">
                    <!-- style="width: 300px" -->
                    <Carousel
                        :settings="{
                            itemsToShow: 1,
                            snapAlign: 'center',
                        }"
                        :autoplay="5000"
                        :wrap-around="true"
                    >
                        <template #slides>
                            <Slide v-for="majalah in datas" :key="majalah">
                                <img
                                    @click="hendelLink(majalah)"
                                    class="carousel__item bg-white bg-no-repeat bg-center bg-cover"
                                    :src="majalah.image"
                                />
                            </Slide>
                        </template>

                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.carousel__item {
    /* min-height: 150px; */
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding: 10px;
}
</style>
