<script>
import InfoTerkini from "@/store/api/infoTerkini";
import ModalInfo from "../../Modal/ModalInfo.vue";
import { Carousel, Navigation, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
export default {
    components: {
        ModalInfo,
        Carousel,
        Navigation,
        Slide,
        Pagination,
    },
    data() {
        return {
            datas: [],
            image: "",
            link: "",
            in_link: "",
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.datas = [];
            try {
                const data = await InfoTerkini.getDataLimit();
                if (data.data.length > 0) {
                    this.image = data.data[0].image;
                    this.link = data.data[0].link;
                    this.in_link = data.data[0].in_link;
                    this.datas = data.data.filter((e) => {
                        return e.id != data.data[0].id;
                        // return e.id == data.data[0].id;
                        // return e;
                    });
                }

                // console.log("data info terkini", this.datas);
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
    },
};
</script>
<template>
    <ModalInfo :image="image" :link="link" :in_link="in_link" />
    <!-- <div class="md:mx-60 md:my-10 mx-5 my-2" v-if="datas.length > 0">
        <Carousel
            :settings="{
                itemsToShow: 1,
                snapAlign: 'center',
            }"
            :autoplay="15000"
            :wrap-around="true"
        >
            <Slide
                v-for="(slider, key) in datas"
                :key="`dataInforTerkini${key}`"
            >
                <div class="carousel__item w-full">
                    <a class="group">
                        <div
                            class="flex items-start content-start gap-10 md:flex-row flex-col"
                        >
                            <div class="w-full h-80 md:h-96 rounded-lg">
                                <div
                                    class="h-80 md:h-96 bg-center bg-cover rounded-lg"
                                    :style="`
                                background-image: url('${slider.image}');
                            `"
                                ></div>
                            </div>
                            <div class="w-full">
                                <div class="overflow-y-auto h-96">
                                    <h3
                                        class="text-left mb-4 text-4xl font-bold font-heading leading-snug dark:text-slate-300"
                                        data-config-id="auto-txt-3-6 "
                                    >
                                        <template v-if="slider.in_link">
                                            <router-link :to="slider.in_link">
                                                {{ slider.name }}
                                            </router-link>
                                        </template>
                                        <template v-if="!slider.in_link">
                                            <a :href="slider.link">
                                                {{ slider.name }}
                                            </a>
                                        </template>
                                    </h3>
                                    <p
                                        class="mb-4 font-medium leading-relaxed text-justify text-base dark:text-slate-200"
                                        data-config-id="auto-txt-4-6"
                                        v-html="slider.body"
                                    ></p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </Slide>

            <template #addons>
                <Pagination />
                <Navigation />
            </template>
        </Carousel>
    </div> -->
</template>
