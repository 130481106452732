<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { isLoading } from "@/store/reactive.js";

export default {
    setup() {
        return { isLoading };
    },
    props: {
        status: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Loading,
    },
};
</script>

<template>
    <div class="vld-parent">
        <loading :z-index="999999" v-model:active="isLoading" color="#276756" />
    </div>
</template>
