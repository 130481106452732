<script>
import Previwe from "@/components/Depan/Grup.vue";
import LogoBedge from "@/components/Bedge/LogoBedge2.vue";
import { isLoading } from "@/store/reactive.js";
import TabelSeting from "@/store/fire/setting.js";

export default {
    setup() {
        return { isLoading };
    },
    components: {
        LogoBedge,
        Previwe,
    },
    data() {
        return {
            form: {
                judul: "",
                sub_judul: "",
                email: "",
                alamat: "",
                link_background: "",
            },
            key: 0,
            sosial_media: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            isLoading.value = true;
            try {
                const data = await TabelSeting.getData("data");
                this.form.judul = data.judul;
                this.form.sub_judul = data.sub_judul;
                this.form.email = data.email;
                this.form.alamat = data.alamat;
                this.form.link_background = data.link_background;
                this.sosial_media = data.sosial_media;
                isLoading.value = false;
            } catch (error) {
                isLoading.value = false;
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
        setLogo(logo, key) {
            this.sosial_media[key].logo = logo;
        },
        addSosial() {
            const data = JSON.parse(JSON.stringify(this.sosial_media));
            const add = {
                nama: "",
                link: "",
                logo: "",
            };
            this.sosial_media = data.concat(add);
        },
        delSosial(key) {
            const data = this.sosial_media.filter((el, keyTo) => {
                return key != keyTo;
            });
            this.sosial_media = data;
        },
        async simpan() {
            isLoading.value = true;
            const { judul, sub_judul, email, alamat, link_background } =
                this.form;
            const sosialSimpan =
                this.sosial_media.length > 0
                    ? this.sosial_media.filter(
                          (el) => el.nama && el.link && el.logo
                      )
                    : [];
            const sosial_media = { sosial_media: sosialSimpan };
            const set = {
                judul,
                sub_judul,
                email,
                alamat,
                link_background,
                ...sosial_media,
            };
            try {
                const simpan = await TabelSeting.setData(set);
                // console.log("simpan", simpan);
                isLoading.value = false;
                this.$wkToast("Berhasil simpan Data");
            } catch (error) {
                isLoading.value = false;
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
    },
};
</script>

<template>
    <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
    >
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">My Web</h6>
            </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
                <h6
                    class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"
                >
                    Web Informasi
                </h6>
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                Judul
                            </label>
                            <input
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Judul"
                                v-model="form.judul"
                            />
                        </div>
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                Sub Judul
                            </label>
                            <textarea
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Sub Judul"
                                v-model="form.sub_judul"
                            />
                        </div>
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                Email
                            </label>
                            <input
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Email"
                                v-model="form.email"
                            />
                        </div>
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                Alamat
                            </label>
                            <textarea
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Alamat"
                                v-model="form.alamat"
                            />
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            Link Background
                        </label>
                        <input
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Link Background"
                            v-model="form.link_background"
                        />
                    </div>
                </div>
                <hr class="mt-6 border-b-1 border-blueGray-300" />
                <!-- Sosial Media -->
                <h6
                    class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"
                >
                    Sosial Media
                </h6>
                <hr class="mt-6 mb-6 border-b-1 border-blueGray-300" />
                <button
                    :disabled="isLoading"
                    @click="addSosial"
                    title="Tambah Sosial Media"
                    class="btn-success-sm"
                    type="button"
                >
                    <i class="fas fa-plus"></i> {{ sosial_media.length }}
                </button>
                <hr class="mt-6 mb-6 border-b-1 border-blueGray-300" />

                <div v-for="(sosial, key) in sosial_media" :key="key">
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-3/12 px-4">
                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Nama
                                </label>
                                <input
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Nama"
                                    v-model="sosial_media[key].nama"
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4">
                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    LINK
                                </label>
                                <input
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Link"
                                    v-model="sosial_media[key].link"
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4">
                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Logo
                                </label>
                                <input
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Logo"
                                    v-model="sosial_media[key].logo"
                                />
                                <LogoBedge
                                    @get="(data) => setLogo(data, key)"
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-2/12 px-4">
                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Priview
                                </label>
                                <Previwe
                                    :data="{
                                        name: sosial_media[key].nama,
                                        color_logo:
                                            $store.state.styleClass.color,
                                        logo: sosial_media[key].logo,
                                    }"
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-1/12 px-4">
                            <div class="relative w-full mb-3">
                                <button
                                    title="hapus"
                                    type="button"
                                    class="btn-danger-sm"
                                    :disabled="isLoading"
                                    @click="delSosial(key)"
                                >
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="mt-6 mb-6 border-b-1 border-blueGray-300" />
                <button
                    :disabled="isLoading"
                    @click="addSosial"
                    title="Tambah Sosial Media"
                    class="btn-success-sm"
                    type="button"
                >
                    <i class="fas fa-plus"></i> {{ sosial_media.length }}
                </button>
                <hr class="mt-6 mb-6 border-b-1 border-blueGray-300" />
                <button
                    title="simpna"
                    :disabled="isLoading"
                    @click="simpan"
                    class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md focus:outline-none mr-1 ease-linear transition-all duration-150 disabled:opacity-50 w-full fixed bottom-0 right-0"
                    type="submit"
                >
                    Simpan
                </button>
            </form>
        </div>
    </div>
</template>
