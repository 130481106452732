<script>
import Previwe from "@/components/Depan/Aplikasi.vue";
import TableAplikasi from "@/store/fire/aplikasi";
import striptags from "striptags";
import { isLoading } from "@/store/reactive.js";
// { createGrup, updateGrup }
export default {
    name: "modal-aplikasi",
    setup() {
        return { striptags, isLoading };
    },
    components: {
        Previwe,
    },
    data() {
        return {
            showModal: false,
            loading: false,
            form: {
                ids: "",
                name: "",
                show: "active",
                status: "active",
                image_url: "",
                link: "",
                keterangan: "",
                grup: "",
            },
        };
    },
    methods: {
        async toggleModal(createOrUdate = "", data = null) {
            this.form.ids = "";
            this.form.name = "";
            if (createOrUdate == "edit") {
                this.form.ids = data.id;
                this.form.name = data.name;
                this.form.status = data.status;
                this.form.show = data.show;
                this.form.link = data.link;
                this.form.image_url = data.image_url;
                this.form.keterangan = data.keterangan;
                this.form.grup = data.grup;
            }
            if (this.$store.getters["grupArray/data"].length > 0) {
                this.showModal = !this.showModal;
                return false;
            }
            isLoading.value = true;
            try {
                await this.$store.dispatch("grupArray/getAll");
                this.showModal = !this.showModal;
                isLoading.value = false;
            } catch (error) {
                isLoading.value = false;
            }
        },
        async update(id, data) {
            isLoading.value = true;
            try {
                const update = await TableAplikasi.update(id, data);
                const dataAdd = {
                    id,
                    waktuInser: update.waktuInser,
                    ...data,
                    perubahan: true,
                };
                this.$wkToast("Berhasil Simpan data");
                if (update) {
                    this.$emit("edit-data", dataAdd);
                    this.showModal = false;
                    this.clear();
                }
                isLoading.value = false;
            } catch (error) {
                isLoading.value = false;
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
        async simpan() {
            const grup = this.form;
            if (this.form.ids != "") {
                return this.update(this.form.ids, grup);
            }
            isLoading.value = true;

            try {
                const simpanData = await TableAplikasi.create(grup);
                const dataAdd = {
                    id: simpanData.id,
                    waktuInser: simpanData.waktuInser,
                    ...grup,
                    perubahan: true,
                };
                this.$wkToast("Berhasil Simpan data");
                if (simpanData) {
                    this.$emit("tambah-data", dataAdd);
                    this.showModal = false;
                    this.clear();
                }
                isLoading.value = false;
            } catch (error) {
                isLoading.value = false;
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
        clear() {
            this.form = {
                ids: "",
                name: "",
                show: "active",
                status: "active",
                image_url: "",
                link: "",
                keterangan: "",
                grup: "",
            };
        },
    },
};
</script>
<template>
    <div>
        <div v-if="showModal" class="modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <!--header-->
                    <div class="modal-header">
                        <h6 class="text-blueGray-700 text-xl font-bold">
                            Form Aplikasi
                        </h6>
                        <button
                            class="btn-danger-sm"
                            :disabled="isLoading"
                            type="button"
                            @click="toggleModal()"
                        >
                            <span> × </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="modal-body">
                        <form>
                            <h6
                                class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"
                            >
                                Aplikasi Informasi
                            </h6>
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Name Aplikasi"
                                            v-model="form.name"
                                        />
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Show
                                        </label>
                                        <select
                                            v-model="form.show"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            <option value="active">
                                                active
                                            </option>
                                            <option value="non-active">
                                                non-active
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Status
                                        </label>
                                        <select
                                            v-model="form.status"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            <option value="active">
                                                active
                                            </option>
                                            <option value="non-active">
                                                non-active
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Grup
                                        </label>
                                        <select
                                            v-model="form.grup"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            <option value="">
                                                -- Pilih Grup --
                                            </option>
                                            <option
                                                v-for="dataGrup in $store
                                                    .getters['grupArray/data']"
                                                :key="dataGrup.id"
                                                :value="dataGrup.id"
                                            >
                                                {{ dataGrup.name }}
                                                ({{ dataGrup.status }})
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            link
                                        </label>
                                        <input
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="https://nama-domain.co.id"
                                            v-model="form.link"
                                        />
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            image url
                                        </label>
                                        <input
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="https://url-image.png"
                                            v-model="form.image_url"
                                        />
                                    </div>
                                </div>
                                <div class="w-full lg:w-12/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Keterangan
                                        </label>
                                        <textarea
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Keterangan"
                                            v-model="form.keterangan"
                                            rows="5"
                                        />
                                    </div>
                                </div>

                                <div class="w-full lg:w-4/12 px-4">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Preview
                                    </label>
                                    <Previwe
                                        target="_blank"
                                        :data="{
                                            name: form.name,
                                            image_url: form.image_url,
                                            link: form.link,
                                            keterangan: striptags(
                                                form.keterangan
                                            ),
                                        }"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--footer-->
                    <div class="modal-footer">
                        <div class="p-2">
                            <button
                                @click="toggleModal()"
                                class="btn-danger"
                                :disabled="isLoading"
                                type="button"
                            >
                                Keluar
                            </button>
                            <button
                                @click="simpan"
                                class="btn-success"
                                :disabled="isLoading"
                                type="button"
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
