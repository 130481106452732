<script>
import Previwe from "@/components/Depan/Aplikasi.vue";
import ButtonTable from "@/components/Table/ButtonTable.vue";
import TableAplikasi from "@/store/fire/aplikasi";
import { isLoading } from "@/store/reactive.js";

export default {
    setup() {
        return { isLoading };
    },
    components: {
        ButtonTable,
        Previwe,
    },
    props: {
        datas: {
            type: Array,
            default: null,
        },
        color: {
            default: "light",
            validator: function (value) {
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        edit(data) {
            this.$emit("edit", data);
        },
        async hapus(data) {
            if (isLoading.value) {
                return false;
            }

            let con = confirm(
                "anda Yakin unutk Menghapus aplikasi " + data.name.toUpperCase()
            );
            if (!con) {
                return false;
            }
            isLoading.value = true;
            try {
                const hapus = await TableAplikasi.delete(data.id);
                this.$wkToast("Berhasil Di hapus");
                this.$emit("hapus-data", data.id);
                isLoading.value = false;
            } catch (error) {
                isLoading.value = false;
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
    },
};
</script>
<template>
    <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
        :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
        <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3
                        class="font-semibold text-lg"
                        :class="[
                            color === 'light'
                                ? 'text-blueGray-700'
                                : 'text-white',
                        ]"
                    >
                        Data Aplikasi
                    </h3>
                </div>
            </div>
        </div>
        <div class="block w-full overflow-x-auto">
            <!-- Projects table -->
            <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                    <tr>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                                color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'blueGray-900 text-emerald-300 border-emerald-700',
                            ]"
                        >
                            SHOW/STATUS
                        </th>

                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                                color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'blueGray-900 text-emerald-300 border-emerald-700',
                            ]"
                        >
                            Previw
                        </th>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                                color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'blueGray-900 text-emerald-300 border-emerald-700',
                            ]"
                        ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="data in datas"
                        :key="data.id"
                        :class="data.perubahan ? 'bg-emerald-300' : ''"
                        :id="data.id"
                        :ref="data.id"
                    >
                        <td
                            class="border-t-0 px-6 text-sm border-l-0 border-r-0 text-xs whitespace-nowrap text-left"
                        >
                            {{ data.show }}/{{ data.status }}
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-2"
                        >
                            <div class="w-full px-4">
                                <Previwe
                                    target="_blank"
                                    :data="{
                                        name: data.name,
                                        image_url: data.image_url,
                                        link: data.link,
                                        keterangan: data.keterangan,
                                    }"
                                    :key="data.id"
                                />
                            </div>
                        </td>

                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-right"
                        >
                            <ButtonTable
                                :target="data"
                                @hapus="hapus"
                                @edit="edit"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
