<script>
import { ZoomQuestionIcon } from "vue-tabler-icons";
import { isLoading } from "@/store/reactive.js";
import Artike from "../../store/api/artikel";
import Helper from "@/store/helper";

export default {
    setup() {
        return { isLoading };
    },
    components: {
        ZoomQuestionIcon,
    },
    beforeRouteUpdate(to, from) {
        this.setCari(to.params.judul);
    },
    mounted() {
        this.setCari(this.$route.params.judul);
    },
    data() {
        return {
            form: {
                cari: null,
            },
            datas: null,
        };
    },
    methods: {
        setCari(data) {
            this.form.cari = data;
            this.getData(data);
        },
        async getData(slug) {
            isLoading.value = true;
            this.datas = null;
            try {
                const data = await Artike.getArtike(slug);
                this.datas = data;
                // console.log("data", data);
            } catch (error) {
            } finally {
                isLoading.value = false;
            }
        },
        hendelBaca(e) {
            if (!this.$store.state.voice.isVoice) {
                return true;
            }
            let text = e.target.textContent;
            if (!text.trim()) {
                text = e.target.getAttribute("alt");
                if (!text) {
                    text = e.target.getAttribute("title");
                }
            }
            Helper.startVoice(text);
        },
        hendelCari(e) {
            e.preventDefault();
            this.getData(this.form.cari);
        },
    },
};
</script>
<template>
    <section class="text-gray-600 body-font overflow-hidden mx-20">
        <div class="container py-10">
            <div class="flex flex-col items-center justify-center">
                <div class="w-full my-10">
                    <div class="relative">
                        <div
                            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                        >
                            <ZoomQuestionIcon />
                        </div>
                        <form @submit="hendelCari">
                            <input
                                v-model="form.cari"
                                type="search"
                                id="default-search"
                                class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Apa Yang Anda Cari ?"
                            />
                            <button
                                type="submit"
                                title="Tombol Aksi Pencarian"
                                class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                <div class="inline-flex">Cari</div>
                            </button>
                        </form>
                    </div>
                </div>
                <div class="w-full">
                    <div class="grid lg:grid-cols-4 gap-5">
                        <div
                            class="w-full"
                            v-for="(data, key) in datas"
                            :key="`dataArtike${key}`"
                        >
                            <div class="max-w-sm mx-auto">
                                <div
                                    class="mb-6 max-w-max overflow-hidden rounded-xl h-44"
                                >
                                    <img
                                        class="transform hover:scale-105 transition ease-in-out duration-1000"
                                        :src="data.image"
                                        alt=""
                                        data-config-id="auto-img-1-2"
                                    />
                                </div>
                                <p
                                    v-if="data.category"
                                    class="mb-4 font-sans max-w-max px-3 py-1.5 text-sm text-indigo-600 font-semibold bg-indigo-50 uppercase rounded-md"
                                    data-config-id="auto-txt-4-2"
                                >
                                    {{ data.category.name }}
                                </p>
                                <a
                                    class="mb-2 inline-block hover:text-gray-800 hover:underline"
                                    href="#"
                                >
                                    <h3
                                        class="text-xl font-bold font-heading leading-normal dark:text-slate-300"
                                        data-config-id="auto-txt-5-2"
                                    >
                                        <router-link
                                            @mouseenter="hendelBaca"
                                            :to="`/artikel/${data.slug}`"
                                            >{{ data.title }}</router-link
                                        >
                                    </h3>
                                </a>
                                <p
                                    class="text-gray-600 font-medium leading-relaxed dark:text-slate-400"
                                    data-config-id="auto-txt-6-2"
                                >
                                    {{ data.body_text.substr(0, 100) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
