<script>
import { useRoute } from "vue-router";
import { getCurrentInstance } from "vue";
import { onMounted, reactive, toRefs } from "vue";
import InformasiDanLayanan from "@/store/api/informasiDanLayanan";
import { isLoading } from "../../store/reactive";
import SvgPanahComponent from "../../components/Svg/Panah.vue";

export default {
    components: {
        SvgPanahComponent
    },
    setup() {
        const app = getCurrentInstance();
        const globalProperties = app.appContext.config.globalProperties;
        const route = useRoute();

        const state = reactive({
            datas: null
        });
        onMounted(async () => {
            const id = route.params.id;
            await getData(id);
        });

        const getData = async id => {
            isLoading.value = true;
            try {
                const {
                    data
                } = await InformasiDanLayanan.getDataSubCategoryById(id);
                state.datas = data;
            } catch (error) {
            } finally {
                isLoading.value = false;
            }
        };
        return {
            ...toRefs(state)
        };
    }
};
</script>
<template>
    <section class="text-gray-600 body-font overflow-hidden lg:mx-20 mx-5">
        <div class="container py-10">
            <div class="mt-5" v-if="datas">
                <div class="mb-11 flex flex-wrap -m-1 px-5">
                    <div class="w-full p-1">
                        <a>
                            <div
                                class="py-5 px-8 bg-white items-center border-2 border-indigo-600 rounded-2xl shadow-10xl"
                            >
                                <div class="flex flex-wrap justify-between -m-2">
                                    <div class="flex-1 p-2">
                                        <h3
                                            class="md:text-lg text-sm font-semibold leading-normal"
                                            data-config-id="auto-txt-3-1"
                                        >{{datas.name}}</h3>
                                        <div
                                            class="text-gray-600 font-medium"
                                            data-config-id="auto-txt-4-1"
                                        >
                                            <ul
                                                class="max-w-md space-y-1 text-white list-none list-inside dark:text-gray-400"
                                            >
                                                <li
                                                    class="flex items-center md:text-base text-sm"
                                                    v-for="(
                                            dataLayana, key
                                        ) in datas.layanans"
                                                    :key="`datalayananDetailMobile${key}`"
                                                >
                                                    <svg
                                                        class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        />
                                                    </svg>
                                                    <template v-if="dataLayana.in_link">
                                                        <router-link
                                                            class="tetx-4xl text-slate-800"
                                                            :to="dataLayana.in_link"
                                                        >{{ dataLayana.name }}</router-link>
                                                    </template>
                                                    <template v-if="!dataLayana.in_link">
                                                        <a
                                                            target="_blank"
                                                            :href="dataLayana.link"
                                                            class="tetx-4xl text-slate-800"
                                                        >{{ dataLayana.name }}</a>
                                                    </template>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>