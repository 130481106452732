<script>
import Helper from "@/store/helper";
import { Drawer } from "flowbite";
import GPRWidged from "./GPRWidged.vue";
import {
    WheelchairIcon,
    VolumeIcon,
    ZoomInIcon,
    ZoomOutIcon,
    ContrastIcon,
    EyeIcon,
    Lamp2Icon,
    LinkIcon,
    ABIcon,
    RefreshIcon,
    FileBarcodeIcon,
    Moon2Icon,
    SunIcon,
} from "vue-tabler-icons";

export default {
    components: {
        WheelchairIcon,
        VolumeIcon,
        ZoomInIcon,
        ZoomOutIcon,
        ContrastIcon,
        EyeIcon,
        Lamp2Icon,
        LinkIcon,
        ABIcon,
        RefreshIcon,
        FileBarcodeIcon,
        Moon2Icon,
        SunIcon,
        GPRWidged,
    },
    data() {
        return {
            initial: null,
            drawer: null,
            fontSize: 0,
            abuAbu: false,
            kontraks: false,
            klise: false,
            penerangan: false,
            garisBawah: false,
            textTegas: false,
            voice: false,
            tipeSidebar: 2,
        };
    },

    mounted() {
        // set the drawer menu element

        const $targetEl = document.getElementById("navarPembantu");
        // options with default values
        const options = {
            placement: "left",
            backdrop: true,
            bodyScrolling: true,
            edge: true,
            edgeOffset: "",
            backdropClasses:
                "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30",
            onHide: () => {},
            onShow: () => {},
            onToggle: () => {},
        };
        const tes = new Drawer($targetEl, options);
        this.drawer = tes;
    },
    methods: {
        open(apa = 2) {
            switch (apa) {
                case 2:
                    this.tipeSidebar = 2;
                    break;

                default:
                    this.tipeSidebar = 1;
                    break;
            }
            this.drawer.show();
        },
        close() {
            this.drawer.hide();
        },

        hendelModeMalam() {
            this.$store.dispatch("modeMalam/setModeMalam");
        },
        hendelVoiece() {
            // alert("sura");
            this.voice = !this.voice;
            this.$store.dispatch("voice/setVoice", this.voice);
            Helper.startVoice(
                this.voice
                    ? "Moda Suara diAktifkan"
                    : "Moda Suara dinonAktifkan"
            );
        },
        hendelPerbesar() {
            if (this.fontSize < 8) {
                this.fontSize = this.fontSize + 1;
                this.$store.dispatch("styleClass/setFontSize", this.fontSize);
            }
        },
        hendelPerkecil() {
            if (this.fontSize > 0) {
                this.fontSize = this.fontSize - 1;
                this.$store.dispatch("styleClass/setFontSize", this.fontSize);
            }
        },
        hendelAbuAbu() {
            this.reset(1);
            this.abuAbu = !this.abuAbu;
            this.$store.dispatch("styleClass/setAbuAbu", this.abuAbu);
        },
        hendelKontraks() {
            this.reset(2);
            this.kontraks = !this.kontraks;
            this.$store.dispatch("styleClass/setKontraks", this.kontraks);
        },
        hendelKlise() {
            this.reset(3);
            this.klise = !this.klise;
            this.$store.dispatch("styleClass/setKlise", this.klise);
        },
        hendelPenerangan() {
            this.reset(4);
            this.penerangan = !this.penerangan;
            this.$store.dispatch("styleClass/setPenerangan", this.penerangan);
        },
        hendelGarisBawah() {
            this.garisBawah = !this.garisBawah;
            this.$store.dispatch("styleClass/setGarisBawah", this.garisBawah);
        },
        hendelTextTegas() {
            this.textTegas = !this.textTegas;
            this.$store.dispatch("styleClass/setTextTegas", this.textTegas);
        },
        hendelUlang() {
            this.reset(5);
        },
        reset(number) {
            if (number != 1) {
                this.abuAbu = false;
            }
            if (number != 2) {
                this.kontraks = false;
            }
            if (number != 3) {
                this.klise = false;
            }
            if (number != 4) {
                this.penerangan = false;
            }
            if (number == 5) {
                this.fontSize = 0;
                this.$store.dispatch("styleClass/setFontSize", this.fontSize);
                this.garisBawah = false;
                this.$store.dispatch(
                    "styleClass/setGarisBawah",
                    this.garisBawah
                );
                this.textTegas = false;
                this.$store.dispatch("styleClass/setTextTegas", this.textTegas);
                this.voice = false;
                this.$store.dispatch("voice/setVoice", this.voice);
            }
            this.$store.dispatch("styleClass/setAbuAbu", this.abuAbu);
            this.$store.dispatch("styleClass/setKontraks", this.kontraks);
            this.$store.dispatch("styleClass/setKlise", this.klise);
            this.$store.dispatch("styleClass/setPenerangan", this.penerangan);
        },
        hendelBaca(e) {
            if (!this.$store.state.voice.isVoice) {
                return true;
            }
            let text = e.target.textContent;
            if (!text.trim()) {
                text = e.target.getAttribute("alt");
                if (!text) {
                    text = e.target.getAttribute("title");
                }
            }
            Helper.startVoice(text);
        },
    },
};
</script>

<template>
    <teleport to="#teleportasi">
        <div class="fixed left-0 bottom-1/2 pt-64 z-30">
            <div class="text-center -ml-2">
                <button
                    @mouseenter="hendelBaca"
                    class="text-white bg-blue-900 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg lg:px-5 lg:py-5 px-2 py-2 text-2xl dark:opacity-50 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-900"
                    type="button"
                    @click="open(1)"
                    title="Sarana"
                >
                    <WheelchairIcon />
                </button>
            </div>
            <div class="text-center -ml-10 mt-10">
                <!-- <button
                    @mouseenter="hendelBaca"
                    class="text-white bg-blue-600 hover:opacity-50 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg lg:px-5 lg:py-5 px-2 py-2 lg:text-lg text-base dark:bg-blue-500 dark:hover:opacity-50 focus:outline-none dark:focus:ring-blue-700 -rotate-90"
                    type="button"
                    @click="open(2)"
                >
                    <span class="not-efek"> GPR Widget </span>
                </button> -->
            </div>
        </div>

        <!-- drawer component -->
        <div
            id="navarPembantu"
            class="fixed z-50 h-screen p-4 overflow-y-auto bg-white w-80 dark:bg-gray-800 -translate-x-full"
            aria-hidden="true"
        >
            <h5
                class="text-base font-semibold text-gray-500 uppercase dark:text-gray-400 not-efek"
                @mouseenter="hendelBaca"
            >
                Sidebar
            </h5>
            <button
                @mouseenter="hendelBaca"
                @click="close"
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
                <svg
                    aria-hidden="true"
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
                <span class="sr-only">Keluar Menu</span>
            </button>
            <template v-if="tipeSidebar == 1">
                <div class="py-4 overflow-y-auto" @mouseover="hendelBaca">
                    <ul class="space-y-2">
                        <li>
                            <button
                                @click="hendelVoiece"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <VolumeIcon />
                                <span class="not-efek ml-3">Moda Suara</span>
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelPerbesar"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <ZoomInIcon />
                                <span class="not-efek ml-3">Perbesar Teks</span>
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelPerkecil"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <ZoomOutIcon />
                                <span class="not-efek ml-3">Perkecil Teks</span>
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelAbuAbu"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 filter grayscale-0"
                            >
                                <FileBarcodeIcon />
                                <span class="not-efek ml-3">Skala Abu-abu</span>
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelKontraks"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <ContrastIcon />
                                <span class="not-efek ml-3">Warna</span>
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelKlise"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <EyeIcon />
                                <span class="not-efek ml-3">Klise</span>
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelPenerangan"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <Lamp2Icon />
                                <span class="not-efek ml-3">Penerangan</span>
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelGarisBawah"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <LinkIcon />
                                <span class="not-efek ml-3"
                                    >Garis Bawah Tautan</span
                                >
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelTextTegas"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <ABIcon />
                                <span class="not-efek ml-3"
                                    >Penegasan Teks</span
                                >
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelModeMalam()"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <Moon2Icon
                                    v-if="!$store.state.modeMalam.isModeMalam"
                                />
                                <SunIcon
                                    v-if="$store.state.modeMalam.isModeMalam"
                                />
                                <span class="not-efek ml-3">
                                    {{
                                        !$store.state.modeMalam.isModeMalam
                                            ? "Malam"
                                            : "Siang"
                                    }}</span
                                >
                            </button>
                        </li>
                        <li>
                            <button
                                @click="hendelUlang"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <RefreshIcon />
                                <span class="not-efek ml-3"
                                    >Mengatur Ulang</span
                                >
                            </button>
                        </li>
                    </ul>
                </div>
            </template>
            <div :class="`${tipeSidebar == 2 ? '' : 'display-off'}`">
                <!-- <GPRWidged /> -->
            </div>
        </div>
    </teleport>
</template>
<style scoped>
.display-off {
    visibility: hidden;
}
</style>
