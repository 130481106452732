import axios from "axios";
// const point = "http://127.0.0.1:8000/api/v1/portal/16";
const point = "https://covid-19.gorontaloprov.go.id/api/v1/portal/16";
const TableUnor16 = {
    async cekKoneksi() {
        try {
            const data = await axios.get(point + "/asn", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (error.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
    async getAsn() {
        try {
            const data = await axios.get(point + "/asn", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (error.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
    async getOpd() {
        try {
            const data = await axios.get(point + "/opd", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (error.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
    async getWhere(where) {
        try {
            const data = await axios.get(point + "/" + where, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (error.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
};

export default TableUnor16;