<script>
import { reactive, onMounted, toRefs } from "vue";
// import Slaider from "@/components/Slaider/Index.vue";
import SlaiderDestinasi from "@/components/Slaider/v2/Destinasi.vue";
import PortalLayanan from "@/store/api/portalLayanan";

export default {
    components: {
        SlaiderDestinasi,
    },
    setup() {
        const state = reactive({
            datasPortal: [],
        });
        onMounted(async () => {
            await getData();
        });
        const getData = async () => {
            state.datasPortal = [];
            try {
                const { data } = await PortalLayanan.getData();
                state.datasPortal = data;
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        };
        return {
            ...toRefs(state),
        };
    },
};
</script>
<template>
    <section
        v-if="datasPortal.length > 0"
        class="relative overflow-hidden pb-20 px-2 md:px-10"
    >
        <h2
            class="mb-4 mt-10 md:text-5xl text-3xl text-center font-bold font-heading tracking-px-n leading-tight dark:text-slate-200 text-slate-100"
        >
            Portal Layanan
        </h2>

        <div class="lg:mx-32 mx-2 md:mt-20">
            <!-- <div class="flex flex-row items-center justify-center"> -->
            <!-- <Slaider :datas="datasPortal" /> -->
            <SlaiderDestinasi :datas="datasPortal" />
            <div class="mt-5 relative">
                <router-link
                    to="/portal-layanan"
                    class="bg-blue-500 dark:bg-blue-800 py-2 px-5 rounded-lg text-white dark:text-slate-200 absolute right-0"
                >
                    Selengkapnya
                </router-link>
            </div>
            <!-- </div> -->
        </div>
    </section>
</template>
