<script>
import RadioPng from "@/assets/img/gorontalo/radio.png";
import ComponenAudioDefault from "@/components/Audio/Default.vue";
import IframeYoutube from "../../Video/IframeYoutube.vue";
import ApiYoutube from "../../../store/api/youtube.js";
import { onMounted, reactive, toRefs } from "vue";
import RadioRhJpg from "@/assets/jpg/Radio-RH.jpg";

export default {
    components: {
        ComponenAudioDefault,
        IframeYoutube
    },
    setup() {
        const state = reactive({
            dataVideo: null
        });

        onMounted(async () => {
            await getDataYoutube();
        });

        const getDataYoutube = async () => {
            try {
                const { data } = await ApiYoutube.getOne();
                if (data) {
                    state.dataVideo = data;
                }
            } catch (error) {}
        };

        return { RadioPng, ...toRefs(state), RadioRhJpg };
    }
};
</script>
<template>
    <section
        class="relative overflow-hidden pb-20 px-2 shadow-lg"
        style=" background-position: center;"
        :style="`background-image: url('${RadioRhJpg}');`"
    >
        <!-- <h2
            class="mt-10 md:text-5xl text-2xl text-center font-bold font-heading tracking-px-n leading-tight dark:text-slate-200"
        >Radio Suara Rakyat Hulondalo</h2> -->
        <div class="lg:mx-32 mx-2 lg:mt-60 mt-20">
            <div class="flex items-start justify-center gap-5 flex-col lg:flex-row">
                <div class="w-full flex flex-col items-center justify-center pt-10 px-2 gap-2">
                    <!--  -->
                    <!-- <div>
                        <h3 class="text-center text-xl dark:text-slate-300">Berita Daring</h3>
                        <hr class="h-px mt-8 bg-gray-200 border-0 dark:bg-gray-700" />
                    </div> -->

                    <div class="w-full rounded-md py-2" v-if="dataVideo">
                        <IframeYoutube
                            :codeYoutube="dataVideo.data.id.videoId"
                            :title="dataVideo.data.snippet.title"
                        />
                        <!-- <h4
                            class="text-center text-md dark:text-slate-300"
                        >{{ dataVideo.data.snippet.title }}</h4> -->
                    </div>
                </div>
                <div
                    class="w-full flex flex-col items-center justify-center rounded-md pt-10 px-2 gap-2"
                >
                    <!-- shadow-lg -->
                    <div>
                        <!-- <h3
                            class="text-center text-xl dark:text-slate-300"
                        >Live streaming Radio 99.9 FM</h3>
                        <hr class="h-px mt-8 bg-gray-200 border-0 dark:bg-gray-700" /> -->
                    </div>

                    <div class="w-full rounded-md py-2 flex flex-col itemx-center justify-center">
                        <!-- <div class="w-full flex justify-center">
                            <img
                                :src="RadioPng" 
                                class="w-40 pb-5"
                                alt="logo radio"
                            />
                        </div>-->
                        <div class="w-full flex flex-col justify-center items-center bg-white p-10 rounded-lg shadow-2xl">
                            <!-- autoplay -->
                            <ComponenAudioDefault
                                :src="
                                    $store.getters['setting/data_v2'].audioLink
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
