<template>
    <!-- Header -->
    <div class="px-4 md:px-10 mx-auto w-full">
        <div>
            <!-- Card stats -->
            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                    <div
                        class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg"
                    >
                        <div class="flex-auto p-4">
                            <div class="flex flex-wrap">
                                <div
                                    class="relative w-full pr-4 max-w-full flex-grow flex-1"
                                >
                                    <h5
                                        class="text-blueGray-400 uppercase font-bold text-xs"
                                    >
                                        ping
                                    </h5>
                                </div>
                                <div class="relative w-auto pl-4 flex-initial">
                                    <a href="#" @click="openPing">
                                        <div
                                            class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500"
                                        >
                                            <i class="fas fa-hands-helping"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                    <BelakanCardStats
                        statSubtitle="OPD Terintegrasi"
                        :statTitle="opdActive"
                        statPercentColor="text-emerald-500"
                        statIconName="fas fa-building"
                        statIconColor="bg-emerald-500"
                    />
                </div>
                <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                    <BelakanCardStats
                        statSubtitle="Jumlah API Terintegrasi"
                        :statTitle="jumlahApi"
                        statPercentColor="text-emerald-500"
                        statIconName="fas fa-project-diagram"
                        statIconColor="bg-emerald-300"
                    />
                </div>
                <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                    <BelakanCardStats
                        statSubtitle="OPD Belum Terintegrasi"
                        :statTitle="opdNonActive"
                        statPercentColor="text-emerald-500"
                        statIconName="fas fa-building"
                        statIconColor="bg-red-500"
                    />
                </div>
            </div>
        </div>
        <teleport to="#teleportasi">
            <ModalPing ref="trigerModal2" :datas="OpdActives" />
        </teleport>
    </div>
</template>

<script>
import BelakanCardStats from "@/components/Cards/BelakanCardStats.vue";
import TableUnor from "@/store/sqlLite/unor.js";
import ModalPing from "@/components/Modal/ModalPing.vue";

export default {
    components: {
        BelakanCardStats,
        ModalPing,
    },
    data() {
        return {
            jumlahApi: 0,
            opdActive: 0,
            opdNonActive: 0,
            OpdActives: [],
        };
    },
    mounted() {
        this.getOpdStatus();
        this.getJumlahApi();
    },
    methods: {
        getJumlahApi() {
            let data = [6, 9, 1];
            this.jumlahApi = data.reduce((val, el) => {
                return val + el;
            }, 0);
        },
        openPing() {
            this.$refs.trigerModal2.toggleModal(this.OpdActives);
        },
        async getOpdStatus(status) {
            this.opdActive = 100;
            try {
                const dataActive = await TableUnor.getOpdStatus("active");
                this.OpdActives = dataActive;
                this.opdActive = dataActive.length;
                const dataNonActive = await TableUnor.getOpdStatus(
                    "non-active"
                );
                this.opdNonActive = dataNonActive.length;
            } catch (error) {}
        },
    },
};
</script>
