<script>
export default {
    props: {
        datas: {
            type: Array,
            default: [],
        },
    },
};
</script>

<template>
    <div
        class="w-full flex flex-col items-center justify-center shadow-lg rounded-md pt-10 px-2 gap-2 bg-white dark:bg-gray-700"
    >
        <div>
            <h3 class="text-left text-xl dark:text-slate-300">
                Berita Gorontalo
            </h3>
            <hr class="h-px mt-8 bg-gray-200 border-0 dark:bg-gray-700" />
        </div>
        <div
            class="flex flex-col items-start rounded-md py-5 px-2 gap-2"
            v-if="datas.length > 0"
        >
            <a v-for="berita in datas" :key="berita" class="group" href="#">
                <a target="_blank" :href="berita.permalink">
                    <div class="flex md:flex-row flex-col items-center -m-4">
                        <div class="p-4 md:flex-1">
                            <div class="overflow-hidden rounded-xl w-full">
                                <img
                                    v-if="berita.thumbnail"
                                    class="transform hover:scale-110 transition ease-out duration-500 w-full"
                                    :src="berita.thumbnail"
                                    :alt="berita.thumbnail"
                                />
                            </div>
                        </div>
                        <div class=" p-4 md:flex-1">
                            <p
                                class="mb-4 font-heading font-medium md:text-lg text-sm text-gray-900 group-hover:underline dark:text-slate-300"
                            >
                                {{ berita.title }}
                            </p>

                            <h4
                                class="font-heading font-medium text-xs uppercase text-gray-500 tracking-px"
                                data-config-id="auto-txt-6-3"
                            >
                                <span
                                    class="inline-block mb-3 text-xs px-2 py-1 bg-blue-50 rounded uppercase text-blue-400 font-semibold"
                                    v-for="categorie in berita.categories"
                                    :key="categorie"
                                >
                                    {{ categorie }}
                                </span>
                                {{ berita.date }}
                            </h4>
                        </div>
                    </div>
                </a>
            </a>
        </div>
    </div>
</template>
