<script>
import { reactive } from "vue";
import { PlayerPlayIcon, PlayerPauseIcon } from "vue-tabler-icons";
export default {
    props: {
        src: {
            type: String,
            default: "",
        },
    },
    components: {
        PlayerPlayIcon,
        PlayerPauseIcon,
    },
    setup() {
        const state = reactive({
            DURATION: 4000,
            counter: null,
            parentbar: null,
            childbar: null,
            flag: true,
        });
        const hendelKlikPlay = () => {
            // const playButton = document.querySelector(".play-button");

            // const progressColor = document.querySelector(".progress-color");
            const pauseButton = document.querySelector(".pause-button");
            const playButton = document.querySelector(".play-button");
            // const progressColorWidth =
            // progressColor.getBoundingClientRect().width;
            // if (progressColorWidth === 0) {
            //     state.flag = true;
            // }
            manageProgress(state.flag);
            state.flag = !state.flag;
            pauseButton.classList.remove("d-none");
            playButton.classList.add("d-none");
        };

        const hendelKlikPaus = () => {
            const pauseButton = document.querySelector(".pause-button");
            const playButton = document.querySelector(".play-button");
            manageProgress(state.flag);
            state.flag = !state.flag;
            playButton.classList.remove("d-none");
            pauseButton.classList.add("d-none");
        };

        const manageProgress = (flag) => {
            const audio = document.querySelector("audio");
            const progressBar = document.querySelector(".progress-bar");
            // const progressColor = document.querySelector(".progress-color");
            // const progressBarWidth = progressBar.getBoundingClientRect().width;
            // const progressColorWidth =
            //     progressColor.getBoundingClientRect().width;
            const soundWaveBarScale1 = document.querySelectorAll(
                ".sound-wave .bar-scale1"
            );
            const soundWaveBarScale2 = document.querySelectorAll(
                ".sound-wave .bar-scale2"
            );
            const soundWaveBarScale3 = document.querySelectorAll(
                ".sound-wave .bar-scale3"
            );

            if (!flag) {
                audio.pause();
                soundWaveBarScale1.forEach((element) =>
                    element.classList.add("stop-animation")
                );
                soundWaveBarScale2.forEach((element) =>
                    element.classList.add("stop-animation")
                );
                soundWaveBarScale3.forEach((element) =>
                    element.classList.add("stop-animation")
                );
            } else {
                audio.play();
                // const parentbar = progressBarWidth;
                // const childbar = progressColorWidth;
                // const DURATION = state.DURATION;
                // let duration =
                //     ((parentbar - childbar) * (DURATION / 1000)) / parentbar;

                soundWaveBarScale1.forEach((element) =>
                    element.classList.remove("stop-animation")
                );
                soundWaveBarScale2.forEach((element) =>
                    element.classList.remove("stop-animation")
                );
                soundWaveBarScale3.forEach((element) =>
                    element.classList.remove("stop-animation")
                );
            }
        };
        return { hendelKlikPlay, hendelKlikPaus };
    },
};
</script>
<template v-if="src">
    <audio controls class="w-full" v-if="src">
        <source :src="src" type="audio/mpeg" />
        Browser Yang Anda Miliki tidak suport memutar audio
    </audio>
    <div class="w-full">
        <div class="sound-wave">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs"
                preserveAspectRatio="none"
                viewBox="0 0 1440 560"
            >
                <g mask='url("#SvgjsMask1099")' fill="none">
                    <rect fill="#0e2a47"></rect>
                    <g
                        transform="translate(0, 0)"
                        stroke-linecap="round"
                        stroke="url(#SvgjsLinearGradient1100)"
                    >
                        <path
                            d="M375 202.15 L375 357.85"
                            stroke-width="17.25"
                            class="bar-scale2 stop-animation"
                        ></path>
                        <path
                            d="M398 155.33 L398 404.67"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M421 196.44 L421 363.56"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M444 259.91 L444 300.09"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                        <path
                            d="M467 208.25 L467 351.75"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M490 184.8 L490 375.2"
                            stroke-width="17.25"
                            class="bar-scale2 stop-animation"
                        ></path>
                        <path
                            d="M513 249.28 L513 310.72"
                            stroke-width="17.25"
                            class="bar-scale2 stop-animation"
                        ></path>
                        <path
                            d="M536 220.75 L536 339.25"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M559 254.8 L559 305.2"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                        <path
                            d="M582 186.77 L582 373.23"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M605 210.13 L605 349.87"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                        <path
                            d="M628 234.45 L628 325.55"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M651 241.1 L651 318.89"
                            stroke-width="17.25"
                            class="bar-scale2 stop-animation"
                        ></path>
                        <path
                            d="M674 202.95 L674 357.05"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M697 165.81 L697 394.19"
                            stroke-width="17.25"
                            class="bar-scale2 stop-animation"
                        ></path>
                        <path
                            d="M720 224.51 L720 335.49"
                            stroke-width="17.25"
                            class="bar-scale2 stop-animation"
                        ></path>
                        <path
                            d="M743 157.59 L743 402.4"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                        <path
                            d="M766 164.98 L766 395.02"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                        <path
                            d="M789 158.93 L789 401.07"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M812 224.24 L812 335.76"
                            stroke-width="17.25"
                            class="bar-scale2 stop-animation"
                        ></path>
                        <path
                            d="M835 171.73 L835 388.27"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                        <path
                            d="M858 264.89 L858 295.11"
                            stroke-width="17.25"
                            class="bar-scale2 stop-animation"
                        ></path>
                        <path
                            d="M881 175.14 L881 384.86"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                        <path
                            d="M904 248.17 L904 311.83"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M927 185.4 L927 374.6"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                        <path
                            d="M950 234.82 L950 325.18"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M973 229.9 L973 330.1"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M996 194.25 L996 365.75"
                            stroke-width="17.25"
                            class="bar-scale2 stop-animation"
                        ></path>
                        <path
                            d="M1019 162.47 L1019 397.53"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                        <path
                            d="M1042 205.06 L1042 354.94"
                            stroke-width="17.25"
                            class="bar-scale3 stop-animation"
                        ></path>
                        <path
                            d="M1065 240.52 L1065 319.48"
                            stroke-width="17.25"
                            class="bar-scale1 stop-animation"
                        ></path>
                    </g>
                </g>
                <defs>
                    <mask id="SvgjsMask1099">
                        <rect width="1440" height="560" fill="#ffffff"></rect>
                    </mask>
                    <linearGradient
                        x1="360"
                        y1="280"
                        x2="1080"
                        y2="280"
                        gradientUnits="userSpaceOnUse"
                        id="SvgjsLinearGradient1100"
                    >
                        <stop stop-color="#3a7cc3" offset="0"></stop>
                        <stop stop-color="#dd1133" offset="1"></stop>
                    </linearGradient>
                </defs>
            </svg>
            <div class="mb-10">
                <!-- <div class="progress-color"></div> -->
            </div>
            <div class="audio-buttons">
                <div
                    class="play-button bg-blue-500 rounded-full text-center"
                    @click="hendelKlikPlay"
                >
                    <PlayerPlayIcon
                        :size="60"
                        style="display: inline"
                        color="white"
                    />
                </div>
                <div
                    class="pause-button d-none bg-blue-500 rounded-full"
                    @click="hendelKlikPaus"
                >
                    <PlayerPauseIcon
                        :size="60"
                        style="display: inline"
                        color="white"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
audio {
    display: none;
}
.play-layer-wrapper {
    position: relative;
}

.play-layer {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100vh;
}

.sound-wave {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    svg {
        display: block;
        max-width: 63rem;
        height: auto;
    }
}
.sound-wave:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 40rem;
    z-index: -1;
    top: 0;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: radial-gradient(#1389e933, transparent 60%, transparent 100%);
}

.progress-bar {
    height: 10px;
    width: 35%;
    border-radius: 30px;
    border: 2px solid #fff;
    background-color: #fff;
    margin: -2rem auto 6rem;
}

.progress-color {
    background: #1389e9;
    height: 100%;
    width: 0;
    border-radius: 30px;
    transition: width 4s linear;
}

.d-none {
    display: none;
}

@keyframes scale1 {
    0% {
        transform: scaleY(0.6);
    }
    40% {
        transform: scaleY(1.2);
    }
    100% {
        transform: scaleY(0.6);
    }
}

.bar-scale1 {
    animation: scale1 0.6s infinite;
    transform-origin: center;
}

@keyframes scale2 {
    0% {
        transform: scaleY(0.5);
    }
    30% {
        transform: scaleY(1.6);
    }
    100% {
        transform: scaleY(0.5);
    }
}

.bar-scale2 {
    animation: scale2 0.6s infinite;
    transform-origin: center;
}

@keyframes scale3 {
    0% {
        transform: scaleY(1.5);
    }
    70% {
        transform: scaleY(0.5);
    }
    100% {
        transform: scaleY(1.5);
    }
}

.bar-scale3 {
    animation: scale3 0.6s infinite;
    transform-origin: center;
}

.stop-animation {
    animation-duration: 0s;
}

.audio-buttons {
    position: relative;
}

.play-button {
    svg {
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.65);
        border-radius: 50px;
    }
}
.play-button,
.pause-button {
    // width: 46px;
    // height: 46px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
</style>
