<template>
    <div>
        <a
            class="text-blueGray-500 py-1 px-3"
            href="#pablo"
            ref="btnDropdownRef"
            v-on:click="toggleDropdown($event)"
        >
            <i class="fas fa-ellipsis-v"></i>
        </a>
        <div
            ref="popoverDropdownRef"
            class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
            v-bind:class="{
                hidden: !dropdownPopoverShow,
                block: dropdownPopoverShow,
            }"
        >
            <a
                @click="aksiEdit(target)"
                href="javascript:void(0);"
                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap hover:text-blueGray-800 text-blueGray-400 bg-transparent"
            >
                Edit
            </a>
            <a
                @click="aksiHapus(target)"
                href="javascript:void(0);"
                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap hover:text-blueGray-800 text-blueGray-400 bg-transparent"
            >
                Hapus
            </a>
        </div>
    </div>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
    props: {
        target: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            dropdownPopoverShow: false,
        };
    },
    methods: {
        aksiHapus(target) {
            this.$emit("hapus", target);
        },
        aksiEdit(target) {
            this.$emit("edit", target);
        },
        toggleDropdown: function (event) {
            event.preventDefault();
            if (this.dropdownPopoverShow) {
                this.dropdownPopoverShow = false;
            } else {
                this.dropdownPopoverShow = true;
                createPopper(
                    this.$refs.btnDropdownRef,
                    this.$refs.popoverDropdownRef,
                    {
                        placement: "bottom-start",
                    }
                );
            }
        },
    },
};
</script>
