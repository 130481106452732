<template>
    <div>
        <div class="flex flex-wrap">
            <BelakanHeaderStats class="mb-10" />
            <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
                <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-slate-500"
                >
                    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
                        <div class="flex flex-wrap items-center">
                            <div
                                class="relative w-full max-w-full flex-grow flex-1"
                            >
                                <h2 class="text-white text-xl font-semibold">
                                    Selemat Datang
                                </h2>
                                <h6
                                    class="text-blueGray-100 mb-1 text-xs font-semibold"
                                >
                                    {{
                                        $store.getters.user
                                            ? $store.getters.user.email
                                            : ""
                                    }}
                                </h6>
                                <div class="relative h-10-px">
                                    {{ cookie }}
                                </div>

                                <!-- <pre>
                                    {{ userTes }}
                                    {{ $store.getters.user }}
                                </pre> -->
                                <!-- <pre>

                                    {{
                                        $store.getters.user.stsTokenManager
                                            .expirationTime
                                    }}
                                    {{
                                        waktuSerkarang < waktuExpaired
                                            ? "false"
                                            : "true"
                                    }}
                                    now :{{ waktuSerkarang }}
                                    exp : {{ waktuExpaired }}
                                </pre> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative h-350-px"></div>
    </div>
</template>
<script>
import BelakanHeaderStats from "@/components/Headers/BelakanHeaderStats.vue";
import { useCookies } from "vue3-cookies";
export default {
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    components: {
        BelakanHeaderStats,
    },
    name: "dashboard-page",
    data() {
        return {
            cookie: "",
            // cookie: this.cookies.isKey("toaken"),
            // userTes: atob(atob(this.$store.state.user)),
            // waktuExpaired: new Date(
            //     this.$store.getters.user.stsTokenManager.expirationTime
            // ),
        };
    },
    mounted() {
        // this.$store.dispatch("isLogin");
    },
};
</script>
