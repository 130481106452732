import {
    createWebHistory,
    createWebHashHistory,
    createRouter,
} from "vue-router";
import { authFire } from "@/firebase";
import store from "@/store";

// layouts

import Belakan from "@/layouts/Belakan.vue";
import Auth from "@/layouts/Auth.vue";
// import Depan from "@/layouts/Depan.vue";
import Depan from "@/layouts/v2/Depan.vue";
// import PageError from "@/layouts/Error.vue";

// views for Admin layout

import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import Maps from "@/views/admin/Maps.vue";
// import Dashboard from "@/views/admin/Dashboard.vue";
import Dashboard from "@/views/belakan/Dashboard.vue";
import Grup from "@/views/belakan/Grup.vue";
import Aplikasi from "@/views/belakan/Aplikasi.vue";
import Setting from "@/views/belakan/Settings.vue";

// views for Auth layout

import Login from "@/views/auth/LoginBelakan.vue";
// import Register from "@/views/auth/Register.vue";

// views without layouts

// import Landing from "@/views/Landing.vue";
import Landing from "@/views/v2/Landing.vue";
import Profile from "@/views/Profile.vue";
import DetailArtikel from "@/views/v2/DetailArtikel.vue";
import DataArtikel from "@/views/v2/DataArtikel.vue";
import DataLaporan from "@/views/v2/DataLaporan.vue";
import DataPortalLayanan from "@/views/v2/DataPortalLayanan.vue";
import DataMajalah from "@/views/v2/DataMajalah.vue";
import DataKlipingBerita from "@/views/v2/DataKlipingBerita.vue";
import DetailMajalah from "@/views/v2/DetailMajalah.vue";
import SubCategoryLayanan from "@/views/v2/SubCategoryLayanan.vue";
import ApiArtike from "@/store/api/artikel";
import NotFound from "@/views/NotFound.vue";

// import Index from "@/views/Index.vue";

// routes
async function getApiArtikel(slug) {
    let dataSementara = null;
    try {
        const data = await ApiArtike.getArtikeBySlug(slug);
        // return data;
        // console.log("dataSementara", data);
        dataSementara.push(data);
        return data;
    } catch (error) {
    } finally {
        // return null;
    }
    return dataSementara;
}

const routes = [
    {
        path: "/admin",
        redirect: "/admin/dashboard",
        component: Belakan,
        meta: {
            harusLogin: true,
        },
        children: [
            {
                path: "/admin/dashboard",
                component: Dashboard,
            },
            {
                path: "/admin/grup",
                component: Grup,
            },
            {
                path: "/admin/aplikasi",
                component: Aplikasi,
            },
            {
                path: "/admin/setting",
                component: Setting,
            },
            {
                path: "/admin/settings",
                component: Settings,
            },
            {
                path: "/admin/tables",
                component: Tables,
            },
            {
                path: "/admin/maps",
                component: Maps,
            },
        ],
    },
    {
        path: "/auth",
        redirect: "/auth/login",
        component: Auth,
        children: [
            {
                path: "/auth/login",
                component: Login,
            },
            // {
            //     path: "/auth/register",
            //     component: Register,
            // },
        ],
    },
    {
        path: "/",
        component: Depan,
        children: [
            {
                path: "/",
                name: "landing",
                component: Landing,
            },
            {
                path: "/artikel/:slug",
                name: "derail-artikel",
                component: DetailArtikel,
                // props: (route) => ({
                //     artikel: getApiArtikel(route.params.slug),
                // }),
            },
            {
                path: "/cari",
                name: "cari-artikel",
                component: DataArtikel,
            },
            {
                name: "cari",
                path: "/cari/:judul",
                component: DataArtikel,
            },
            {
                name: "Laporan",
                path: "/laporan",
                component: DataLaporan,
            },
            // {
            //     name: "Laporan By Category",
            //     path: "/laporan/:categoryId",
            //     component: DataLaporan,
            // },
            {
                name: "Portal Layanan",
                path: "/portal-layanan",
                component: DataPortalLayanan,
            },
            {
                name: "Majalah",
                path: "/majalah",
                component: DataMajalah,
            },
            {
                name: "Kliping Berita",
                path: "/kliping-berita",
                component: DataKlipingBerita,
            },
            {
                name: "Baca Majalah",
                path: "/majalah/:id/baca",
                component: DetailMajalah,
            },
            {
                name: "informasi-layanan",
                path: "/informasi-layanan/:id/:slug",
                component: SubCategoryLayanan,
            },
        ],
    },
    {
        path: "/profile",
        component: Profile,
    },
    // { path: "/:pathMatch(.*)*", redirect: "/" },
    
    {
        path: "/:pathMatch(.*)*",
        // path: "*",
        name: "NotFound",
        component: NotFound,
        meta: { status: 404 },
    },
    // { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },

    // { path: "/:pathMatch(.*)*", component: PageError },
];

const router = createRouter({
    // import.meta.env.BASE_URL
    // mode: "history",
    history: createWebHistory(),
    // history: createWebHashHistory(),
    // history: createWebHashHistory("/folder/"),
    routes,
});

router.beforeEach((to, from, next) => {
    if (
        (to.path === "/auth/login" || to.path === "/auth/register") &&
        store.getters.user
        // authFire.currentUser
    ) {
        next("/admin");
        return;
    }
    if (
        to.matched.some((recode) => recode.meta.harusLogin) &&
        !store.getters.user
        // !authFire.currentUser
    ) {
        next("/auth");
        return;
    }
    window.scrollTo(0, 0);
    next();
});

export default router;
