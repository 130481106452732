<script>
import {
    BrandFacebookIcon,
    BrandInstagramIcon,
    BrandTwitterIcon,
    BrandYoutubeIcon,
    BrandTiktokIcon,
} from "vue-tabler-icons";
import Helper from "@/store/helper";
export default {
    components: {
        BrandFacebookIcon,
        BrandInstagramIcon,
        BrandTwitterIcon,
        BrandYoutubeIcon,
        BrandTiktokIcon,
    },
    methods: {
        hendelBaca(e) {
            if (!this.$store.state.voice.isVoice) {
                return true;
            }
            let text = e.target.textContent;
            if (!text.trim()) {
                text = e.target.getAttribute("alt");
                if (!text) {
                    text = e.target.getAttribute("title");
                }
            }
            Helper.startVoice(text);
        },
    },
};
</script>

<template>
    <section
        data-section-id="1"
        data-share=""
        data-category="footers"
        data-component-id="7fd1b1dd_02_awz"
        class="relative pt-24 bg-violet-900 dark:bg-gray-900 overflow-hidden"
    >
        <img
            class="absolute left-1/2 bottom-0 h-full transform -translate-x-1/2"
            src="https://shuffle.dev/flaro-assets/images/footers/gradient.svg"
            alt=""
            data-config-id="auto-img-1-2"
        />
        <div class="relative z-10 container px-4 mx-auto md:mx-60">
            <div class="pb-24 border-b border-gray-900">
                <div class="flex flex-wrap -m-8">
                    <div class="w-full sm:w-1/2 lg:w-6/12 p-8">
                        <div class="lg:max-w-sm">
                            <a class="flex items-center mb-10">
                                <!-- src="https://flowbite.com/docs/images/logo.svg" -->
                                <img
                                    :src="
                                        $store.getters['setting/data_v2'].logo
                                    "
                                    class="h-6 mr-3 sm:h-9"
                                    alt="Icon Logo"
                                />
                                <span
                                    class="not-efek self-center text-xl font-semibold whitespace-nowrap dark:text-slate-400 text-white"
                                    >{{
                                        $store.getters["setting/data_v2"].title
                                    }}</span
                                >
                            </a>
                            <div
                                class="mb-20 dark:text-gray-400 text-white font-medium leading-relaxed md:max-w-xs"
                                data-config-id="auto-txt-1-2"
                            >
                                <p>
                                    {{
                                        $store.getters["setting/data_v2"].domain
                                    }}
                                </p>
                                <p>
                                    {{
                                        $store.getters["setting/data_v2"].alamat
                                    }}
                                </p>
                                <p>
                                    {{
                                        $store.getters["setting/data_v2"]
                                            .telepon
                                    }}
                                </p>
                                <p>
                                    {{ $store.getters["setting/data_v2"].faks }}
                                </p>
                                <p>
                                    {{
                                        $store.getters["setting/data_v2"].email
                                    }}
                                </p>
                            </div>
                            <div class="flex flex-wrap -m-1.5">
                                <div class="w-auto p-1.5">
                                    <a
                                        @mouseenter="hendelBaca"
                                        title="facebook"
                                        :href="
                                            $store.getters['setting/data_v2']
                                                .facebook
                                        "
                                    >
                                        <div
                                            class="flex items-center justify-center w-9 h-9 border border-gray-800 hover:border-gray-900 rounded-full text-white"
                                        >
                                            <BrandFacebookIcon />
                                        </div>
                                    </a>
                                </div>
                                <div class="w-auto p-1.5">
                                    <a
                                        @mouseenter="hendelBaca"
                                        title="instagram"
                                        :href="
                                            $store.getters['setting/data_v2']
                                                .instagram
                                        "
                                    >
                                        <div
                                            class="flex items-center justify-center w-9 h-9 border border-gray-800 hover:border-gray-900 rounded-full text-white"
                                        >
                                            <BrandInstagramIcon />
                                        </div>
                                    </a>
                                </div>
                                <div class="w-auto p-1.5">
                                    <a
                                        @mouseenter="hendelBaca"
                                        title="twitter"
                                        :href="
                                            $store.getters['setting/data_v2']
                                                .twitter
                                        "
                                    >
                                        <div
                                            class="flex items-center justify-center w-9 h-9 border border-gray-800 hover:border-gray-900 rounded-full text-white"
                                        >
                                            <BrandTwitterIcon />
                                        </div>
                                    </a>
                                </div>
                                <div class="w-auto p-1.5">
                                    <a
                                        @mouseenter="hendelBaca"
                                        title="youtube"
                                        :href="
                                            $store.getters['setting/data_v2']
                                                .youtube
                                        "
                                    >
                                        <div
                                            class="flex items-center justify-center w-9 h-9 border border-gray-800 hover:border-gray-900 rounded-full text-white"
                                        >
                                            <BrandYoutubeIcon />
                                        </div>
                                    </a>
                                </div>
                                <div class="w-auto p-1.5">
                                    <a
                                        @mouseenter="hendelBaca"
                                        title="tiktok"
                                        :href="
                                            $store.getters['setting/data_v2']
                                                .tiktok
                                        "
                                    >
                                        <div
                                            class="flex items-center justify-center w-9 h-9 border border-gray-800 hover:border-gray-900 rounded-full text-white"
                                        >
                                            <BrandTiktokIcon />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="w-full sm:w-1/2 lg:w-2/12 p-8">
                        <h3
                            class="mb-8 text-sm text-gray-600 uppercase font-semibold leading-normal tracking-px"
                            data-config-id="auto-txt-2-2"
                        >
                            Products
                        </h3>
                        <ul>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-3-2"
                                    >Services</a
                                >
                            </li>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-4-2"
                                    >About Us</a
                                >
                            </li>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-5-2"
                                    >News &amp; Stories</a
                                >
                            </li>
                            <li>
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-6-2"
                                    >Roadmap</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="w-full sm:w-1/2 lg:w-2/12 p-8">
                        <h3
                            class="mb-8 text-sm text-gray-600 uppercase font-semibold leading-normal tracking-px"
                            data-config-id="auto-txt-7-2"
                        >
                            Important links
                        </h3>
                        <ul>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-8-2"
                                    >Our Journeys</a
                                >
                            </li>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-9-2"
                                    >Pricing Plans</a
                                >
                            </li>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-10-2"
                                    >Roadmap</a
                                >
                            </li>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-11-2"
                                    >Terms &amp; Conditions</a
                                >
                            </li>
                            <li>
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-12-2"
                                    >Privacy Policy</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="w-full sm:w-1/2 lg:w-2/12 p-8">
                        <h3
                            class="mb-8 text-sm text-gray-600 uppercase font-semibold leading-normal tracking-px"
                            data-config-id="auto-txt-13-2"
                        >
                            Company
                        </h3>
                        <ul>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-14-2"
                                    >About Us</a
                                >
                            </li>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-15-2"
                                    >Jobs</a
                                >
                            </li>
                            <li class="mb-5">
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-16-2"
                                    >Press</a
                                >
                            </li>
                            <li>
                                <a
                                    class="text-white hover:text-gray-200 font-medium leading-relaxed"
                                    href="#"
                                    data-config-id="auto-txt-17-2"
                                    >Contact Us</a
                                >
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
            <p
                class="py-5 text-sm text-gray-300 font-medium text-center leading-normal"
                data-config-id="auto-txt-18-2"
            >
                Copyright © 2023 {{ $store.getters["setting/data_v2"].title }}
            </p>
        </div>
    </section>
</template>
