import axios from "axios";
import { apiPortal } from "../../config";
const point = apiPortal + "/kliping-berita";
const klipingBerita = {
    async getData(page = 1) {
        try {
            const data = await axios.get(point, {
                params: {
                    page: page,
                },
            });
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
    async getBySlug(slug) {
        try {
            const data = await axios.get(`${point}/${slug}/detail-slug`);
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
};
export default klipingBerita;