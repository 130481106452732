import axios from "axios";
import { apiPortal } from "../../config";
const point = apiPortal + "/berita";
const Berita = {
    async feed() {
        try {
            const data = await axios.get(`${point}/gorontalo`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
};
export default Berita;
