<script>
import InfoTerkini from "@/store/api/infoTerkini";
import ModalInfo from "../../Modal/ModalInfo.vue";
export default {
    components: {
        ModalInfo,
    },
    data() {
        return {
            datas: [],
            image: "",
            link: "",
            in_link: "",
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.datas = [];
            try {
                const data = await InfoTerkini.getDataLimit();
                this.datas = data.data;
                this.image = data.data[0].image;
                this.link = data.data[0].link;
                this.in_link = data.data[0].in_link;
                // console.log("data info terkini", this.datas);
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
    },
};
</script>
<template>
    <ModalInfo :image="image" :link="link" :in_link="in_link" />
    <div class="md:mx-60 md:my-10 mx-5 my-2" v-if="datas.length > 0">
        <div id="indicators-carousel" class="relative" data-carousel="static">
            <!-- Carousel wrapper -->
            <div class="relative h-80 overflow-hidden rounded-lg md:h-96">
                <!-- Item 1 -->
                <div
                    v-for="(slider, key) in datas"
                    :key="`dataInforTerkini${key}`"
                    class="hidden duration-700 ease-in-out"
                    :data-carousel-item="{ active: key == 0 }"
                >
                    <div
                        class="flex items-start content-start gap-10 md:flex-row flex-col"
                    >
                        <div class="w-full h-80 md:h-96 bg-black">
                            <div
                                class="h-80 md:h-96 bg-center bg-cover rounded-lg"
                                :style="`
                                background-image: url('${slider.image}');
                            `"
                            ></div>
                        </div>
                        <div class="w-full">
                            <div class="overflow-y-auto h-96">
                                <h3
                                    class="mb-4 text-4xl font-bold font-heading leading-snug dark:text-slate-300"
                                    data-config-id="auto-txt-3-6 "
                                >
                                    <template v-if="slider.in_link">
                                        <router-link :to="slider.in_link">
                                            {{ slider.name }}
                                        </router-link>
                                    </template>
                                    <template v-if="!slider.in_link">
                                        <a :href="slider.link">
                                            {{ slider.name }}
                                        </a>
                                    </template>
                                </h3>
                                <p
                                    class="mb-4 font-medium leading-relaxed text-justify text-base dark:text-slate-200"
                                    data-config-id="auto-txt-4-6"
                                    v-html="slider.body"
                                ></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Slider indicators -->
            <div
                class="absolute z-20 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2 bg-blue-700 dark:bg-slate-400 opacity-80 p-2 rounded-lg"
            >
                <button
                    v-for="(slider, key) in datas"
                    :key="`${key}slider`"
                    type="button"
                    class="w-3 h-3 rounded-full"
                    aria-current="true"
                    aria-label="Slide 1"
                    :data-carousel-slide-to="key"
                ></button>
            </div>
            <!-- Slider controls -->
            <button
                type="button"
                class="absolute top-0 left-0 z-20 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                data-carousel-prev
            >
                <span
                    class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-blue-700 dark:bg-white/50 group-hover:bg-blue-700/30 dark:group-hover:bg-white/80 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
                >
                    <svg
                        aria-hidden="true"
                        class="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 19l-7-7 7-7"
                        ></path>
                    </svg>
                    <span class="sr-only">Previous</span>
                </span>
            </button>
            <button
                type="button"
                class="absolute top-0 right-0 z-20 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                data-carousel-next
            >
                <span
                    class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-blue-700 dark:bg-white/50 group-hover:bg-blue-700/30 dark:group-hover:bg-white/80 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
                >
                    <svg
                        aria-hidden="true"
                        class="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5l7 7-7 7"
                        ></path>
                    </svg>
                    <span class="sr-only">Next</span>
                </span>
            </button>
        </div>
    </div>
</template>
