import axios from "axios";

// const point = "http://127.0.0.1:8000/api/v1/portal/28";
const point = "https://covid-19.gorontaloprov.go.id/api/v1/portal/28";
const TableUnor28 = {
    async cekKoneksi() {
        try {
            const data = await axios.get(point + "/sekolah", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
    async getSekolah() {
        try {
            const data = await axios.get(point + "/sekolah", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },

    async getPendidik() {
        try {
            const data = await axios.get(point + "/pendidik", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },

    async getSekolahStatus(status) {
        try {
            const data = await axios.get(
                point + "/sekolah-status?id=" + status, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "application/json",
                    },
                }
            );
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
    async getSekolahKabupaten(status) {
        try {
            const data = await axios.get(
                point + "/sekolah-kabupaten?id=" + status, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "application/json",
                    },
                }
            );
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
};

export default TableUnor28;