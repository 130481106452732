import { useLoadGrups } from "@/store/fire/grup.js";

const grupArray = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
        data: (state) => {
            const dataOlah = JSON.parse(JSON.stringify(state.data));
            return dataOlah;
        },
    },
    mutations: {
        CREATE_GRUP(state, data) {
            const dataGrups = JSON.parse(JSON.stringify(state.data));
            state.data = dataGrups.concat(data);
        },

        UPDATE_GRUP(state, data) {
            const dataGrupsa = JSON.parse(JSON.stringify(state.data));
            state.data = dataGrupsa.map((el) => {
                if (el.id == data.id) {
                    return data;
                } else {
                    return el;
                }
            });
        },
        SET_GRUP(state, data) {
            state.data = JSON.parse(JSON.stringify(data));
        },
        DELETE_GRUP(state, data) {
            const dataGrups = JSON.parse(JSON.stringify(state.data));
            const find = dataGrups.find((el) => {
                return el.id == data;
            });
            const filter = dataGrups.filter((el) => {
                return el != find;
            });
            state.data = filter;
        },
    },
    actions: {
        create({ commit }, data) {
            commit("CREATE_GRUP", data);
        },
        delete({ commit }, id) {
            commit("DELETE_GRUP", id);
        },
        update({ commit }, data) {
            commit("UPDATE_GRUP", data);
        },
        setAll({ commit }, data) {
            commit("SET_GRUP", data);
        },
        async getAll({ commit }) {
            try {
                const data = await useLoadGrups();
                commit("SET_GRUP", data);
            } catch (error) {
                throw error;
            }
        },
    },
};

export default grupArray;