<script>
import { useRouter } from "vue-router";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    name: "Laporan Slaider",
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    setup() {
        const router = useRouter();
        const hendelLink = (data) => {
            console.log("data", data);
            if (data.in_link) {
                router.push(data.in_link);
            } else {
                return (window.location = data.link);
            }
        };
        return { hendelLink };
    },
};
</script>

<template>
    <div class="w-full" style="text-align: -webkit-center" v-if="data">
        <router-link
            class="group"
            :to="{ path: '/laporan', query: { category: data.id } }"
        >
            <h3
                class="mb-10 text-xl text-center font-bold font-heading tracking-px-n leading-tight dark:text-slate-200"
            >
                {{ data.name }}
            </h3>
        </router-link>
        <div class="grid grid-cols-1">
            <!-- style="width: 200px" -->
            <Carousel
                :settings="{
                    itemsToShow: 1,
                    snapAlign: 'center',
                }"
                :autoplay="5000"
                :wrap-around="true"
            >
                <template #slides>
                    <Slide v-for="laporan in data.laporans" :key="laporan">
                        <div
                            @click="hendelLink(laporan)"
                            class="carousel__item bg-white bg-no-repeat bg-center bg-cover"
                            :style="`background-image: url('${laporan.image}');`"
                        ></div>
                    </Slide>
                </template>

                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
        </div>
    </div>
</template>
<style scoped>
.carousel__item {
    min-height: 380px;
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
