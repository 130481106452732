<script>
import Helper from "@/store/helper";
import { Dropdown } from "flowbite";

export default {
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    setup() {
        const hendelOpenNavbarLoop = (e) => {
            // alert("berjas");
            // return true;
            // set the element that trigger the dropdown menu on click
            const $triggerEl = e.target;
            const idTarget = $triggerEl.getAttribute("data-dropdown-toggle");
            // set the dropdown menu element
            const $targetEl = document.getElementById(idTarget);
            const options = {
                placement: "right",
                triggerType: "click",
                offsetSkidding: 0,
                offsetDistance: 10,
                delay: 300,
                // onHide: () => {
                //     console.log("dropdown has been hidden");
                // },
                // onShow: () => {
                //     console.log("dropdown has been shown");
                // },
                // onToggle: () => {
                //     console.log("dropdown has been toggled");
                // },
            };
            const dropdown = new Dropdown($targetEl, $triggerEl, options);
            // console.log("dropdowndropdown", dropdown);
            // console.log("dropdown targetEl", $targetEl);
            // console.log(
            //     "show aaa",
            //     e.target.getAttribute("data-dropdown-toggle")
            // );
            dropdown.show();
        };
        return { hendelOpenNavbarLoop };
    },
    methods: {
        hendelBaca(e) {
            if (!this.$store.state.voice.isVoice) {
                return true;
            }
            let text = e.target.textContent;
            if (!text.trim()) {
                text = e.target.getAttribute("alt");
                if (!text) {
                    text = e.target.getAttribute("title");
                }
            }
            Helper.startVoice(text);
        },
    },
};
</script>
<template>
    <button
        @click="hendelOpenNavbarLoop"
        :id="`dropdownNavbar1LoopLink-${data.id}`"
        :data-dropdown-toggle="`dropdownNavbar1Loop-${data.id}`"
        data-dropdown-placement="right-start"
        type="button"
        class="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
    >
        {{ data.title
        }}<svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
            ></path>
        </svg>
    </button>
    <div
        :id="`dropdownNavbar1Loop-${data.id}`"
        class="z-20 hidden font-normal bg-white divide-y divide-gray-100 rounded shadow w-80 dark:bg-gray-700 dark:divide-gray-600"
    >
        <ul
            class="py-1 text-sm text-gray-700 dark:text-gray-400"
            aria-labelledby="dropdownLargeButton"
            @mouseover="hendelBaca"
        >
            <li
                v-for="(data1, key2) in data.children"
                :key="`menuSubKey${data.id}${key2}`"
                class="not-efek"
            >
                <template v-if="data1.children.length > 0">
                    <LoopingNabar :data="data1" />
                </template>
                <template v-if="data1.children.length < 1">
                    <template v-if="data1.url2">
                        <router-link
                            :to="data1.url2"
                            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >{{ data1.title }}</router-link
                        >
                    </template>
                    <template v-if="!data1.url2">
                        <a
                            :target="data1.target"
                            :href="data1.url"
                            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >{{ data1.title }}</a
                        >
                    </template>
                </template>
            </li>
        </ul>
    </div>
</template>
