<script>
export default {
    props: {
        codeYoutube: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        }
    }
};
</script>

<template>
    <iframe
        class="rounded-lg shadow-2xl"
        width="100%"
        height="350"
        :src="`https://www.youtube.com/embed/${codeYoutube}`"
        :title="title"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
    ></iframe>
</template>
