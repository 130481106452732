<script>
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import ApiBener from "../../../store/api/bener";
import { onMounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

export default {
    name: "Bener Slider",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    setup() {
        const router = useRouter();
        const state = reactive({
            datas: [],
        });
        onMounted(async () => {
            await getData();
        });
        const getData = async () => {
            state.datas = [];
            try {
                const { data } = await ApiBener.getData();
                state.datas = data;
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        };
        const hendelLink = (data) => {
            console.log("data", data);
            if (data.in_link) {
                router.push(data.in_link);
            } else {
                return (window.location = data.link);
            }
        };
        return {
            ...toRefs(state),
            hendelLink,
        };
    },
};
</script>

<template>
    <Carousel :autoplay="5000" :wrap-around="true" v-if="datas.length > 0">
        <Slide v-for="bener in datas" :key="bener">
            <img
                @click="hendelLink(bener)"
                class="carousel__item bg-white bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden"
                :src="bener.image"
            />
        </Slide>

        <template #addons>
            <Navigation />
            <!-- <Pagination /> -->
        </template>
    </Carousel>
</template>
<style scoped>
.carousel__item {
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    /* border-radius: 8px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .carousel__slide {
    padding: 10px;
} */

.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
}
</style>
