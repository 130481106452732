<!-- src/components/NotFound.vue -->
<template>
	<!--dust particel-->
	<div>
		<div class="starsec"></div>
		<div class="starthird"></div>
		<div class="starfourth"></div>
		<div class="starfifth"></div>
	</div>
	
	<!-- END Lamp -->
	<section class="error">
		<!-- Content -->
		<div class="error__content">
			<div class="error__message message">
				<h1 class="message__title">Page Not Found</h1>
			</div>
			<div class="error__nav e-nav">
				<a href="/" target="_blanck" class="e-nav__link"></a>
			</div>
		</div>
		<!-- END Content -->
	</section>
</template>

<script>
export default {
	name: "NotFound",
	mounted() {
		document.title = "404 - Page Not Found";
	},
};
</script>

<style scoped>
* {
	margin: 0;
	padding: 0;
	-webkit-text-size-adjust: none;
}

html,
body {
	height: 100%;
	overflow: hidden;
}

body {
	padding: 0;
	margin: 0;
	background: #181828;
	font-size: 14px;
	line-height: 1;
}

label {
	cursor: pointer;
}

a {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	background: transparent;
	text-decoration: none;
	color: #000;
}

input,
select,
button,
textarea {
	margin: 0;
	font-size: 100%;
}

html,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
input {
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

.top-header:before {
	background-image: url(https://1.bp.blogspot.com/-gxsOcYWghHA/Xp_izTh4sFI/AAAAAAAAU8s/y637Fwg99qAuzW9na_NT_uApny8Vce95gCEwYBhgL/s1600/header-footer-gradient-bg.png);
}
.top-header:before {
	content: "";
	display: block;
	width: 100%;
	height: 4px;
	background-repeat: repeat-x;
	background-size: contain;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.5;
}

.starsec {
	content: " ";
	position: absolute;
	width: 3px;
	height: 3px;
	background: transparent;
	box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
		234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4, 633px 601px #ff5722,
		350px 630px #ffeb3b, 1164px 782px #00bcd4, 76px 690px #3f51b5, 1825px 701px #cddc39,
		1646px 578px #ffeb3b, 544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
		168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0, 104px 1690px #8bc34a,
		1167px 1338px #e91e63, 345px 1652px #009688, 1682px 1196px #f44336,
		1995px 494px #8bc34a, 428px 798px #ff5722, 340px 1623px #f44336, 605px 349px #9c27b0,
		1339px 1344px #673ab7, 1102px 1745px #3f51b5, 1592px 1676px #2196f3,
		419px 1024px #ff9800, 630px 1033px #4caf50, 1995px 1644px #00bcd4,
		1092px 712px #9c27b0, 1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
		19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3, 67px 712px #ff5722,
		89px 1406px #f44336, 275px 321px #009688, 592px 630px #e91e63, 1012px 1690px #9c27b0,
		1749px 23px #673ab7, 94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
		1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4, 1202px 46px #ff5722,
		890px 569px #ff5722, 1613px 813px #2196f3, 223px 252px #ff9800, 983px 1093px #f44336,
		726px 1029px #ffc107, 1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
		212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff, 1768px 516px #f44336,
		849px 391px #ff9800, 228px 1824px #fff, 1119px 1680px #ffc107, 812px 1480px #3f51b5,
		1438px 1585px #cddc39, 137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
		857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50, 1655px 911px #00bcd4,
		1102px 1216px #ff5722, 1807px 1044px #fff, 660px 435px #03a9f4, 299px 678px #4caf50,
		1193px 115px #ff9800, 918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
		108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722, 1004px 391px #673ab7,
		1529px 516px #f44336, 1206px 845px #cddc39, 347px 583px #009688, 1102px 1332px #f44336,
		709px 1756px #00bcd4, 1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
		320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722, 1546px 692px #f44336;
	animation: animStar 150s linear infinite;
}

.starthird {
	content: " ";
	position: absolute;
	width: 3px;
	height: 3px;
	background: transparent;
	box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
		234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4, 633px 601px #ff5722,
		350px 630px #ffeb3b, 1164px 782px #00bcd4, 76px 690px #3f51b5, 1825px 701px #cddc39,
		1646px 578px #ffeb3b, 544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
		168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0, 104px 1690px #8bc34a,
		1167px 1338px #e91e63, 345px 1652px #009688, 1682px 1196px #f44336,
		1995px 494px #8bc34a, 428px 798px #ff5722, 340px 1623px #f44336, 605px 349px #9c27b0,
		1339px 1344px #673ab7, 1102px 1745px #3f51b5, 1592px 1676px #2196f3,
		419px 1024px #ff9800, 630px 1033px #4caf50, 1995px 1644px #00bcd4,
		1092px 712px #9c27b0, 1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
		19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3, 67px 712px #ff5722,
		89px 1406px #f44336, 275px 321px #009688, 592px 630px #e91e63, 1012px 1690px #9c27b0,
		1749px 23px #673ab7, 94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
		1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4, 1202px 46px #ff5722,
		890px 569px #ff5722, 1613px 813px #2196f3, 223px 252px #ff9800, 983px 1093px #f44336,
		726px 1029px #ffc107, 1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
		212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff, 1768px 516px #f44336,
		849px 391px #ff9800, 228px 1824px #fff, 1119px 1680px #ffc107, 812px 1480px #3f51b5,
		1438px 1585px #cddc39, 137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
		857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50, 1655px 911px #00bcd4,
		1102px 1216px #ff5722, 1807px 1044px #fff, 660px 435px #03a9f4, 299px 678px #4caf50,
		1193px 115px #ff9800, 918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
		108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722, 1004px 391px #673ab7,
		1529px 516px #f44336, 1206px 845px #cddc39, 347px 583px #009688, 1102px 1332px #f44336,
		709px 1756px #00bcd4, 1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
		320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722, 1546px 692px #f44336;
	animation: animStar 10s linear infinite;
}

.starfourth {
	content: " ";
	position: absolute;
	width: 2px;
	height: 2px;
	background: transparent;
	box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
		234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4, 633px 601px #ff5722,
		350px 630px #ffeb3b, 1164px 782px #00bcd4, 76px 690px #3f51b5, 1825px 701px #cddc39,
		1646px 578px #ffeb3b, 544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
		168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0, 104px 1690px #8bc34a,
		1167px 1338px #e91e63, 345px 1652px #009688, 1682px 1196px #f44336,
		1995px 494px #8bc34a, 428px 798px #ff5722, 340px 1623px #f44336, 605px 349px #9c27b0,
		1339px 1344px #673ab7, 1102px 1745px #3f51b5, 1592px 1676px #2196f3,
		419px 1024px #ff9800, 630px 1033px #4caf50, 1995px 1644px #00bcd4,
		1092px 712px #9c27b0, 1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
		19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3, 67px 712px #ff5722,
		89px 1406px #f44336, 275px 321px #009688, 592px 630px #e91e63, 1012px 1690px #9c27b0,
		1749px 23px #673ab7, 94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
		1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4, 1202px 46px #ff5722,
		890px 569px #ff5722, 1613px 813px #2196f3, 223px 252px #ff9800, 983px 1093px #f44336,
		726px 1029px #ffc107, 1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
		212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff, 1768px 516px #f44336,
		849px 391px #ff9800, 228px 1824px #fff, 1119px 1680px #ffc107, 812px 1480px #3f51b5,
		1438px 1585px #cddc39, 137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
		857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50, 1655px 911px #00bcd4,
		1102px 1216px #ff5722, 1807px 1044px #fff, 660px 435px #03a9f4, 299px 678px #4caf50,
		1193px 115px #ff9800, 918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
		108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722, 1004px 391px #673ab7,
		1529px 516px #f44336, 1206px 845px #cddc39, 347px 583px #009688, 1102px 1332px #f44336,
		709px 1756px #00bcd4, 1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
		320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722, 1546px 692px #f44336;
	animation: animStar 50s linear infinite;
}

.starfifth {
	content: " ";
	position: absolute;
	width: 1px;
	height: 1px;
	background: transparent;
	box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
		234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4, 633px 601px #ff5722,
		350px 630px #ffeb3b, 1164px 782px #00bcd4, 76px 690px #3f51b5, 1825px 701px #cddc39,
		1646px 578px #ffeb3b, 544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
		168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0, 104px 1690px #8bc34a,
		1167px 1338px #e91e63, 345px 1652px #009688, 1682px 1196px #f44336,
		1995px 494px #8bc34a, 428px 798px #ff5722, 340px 1623px #f44336, 605px 349px #9c27b0,
		1339px 1344px #673ab7, 1102px 1745px #3f51b5, 1592px 1676px #2196f3,
		419px 1024px #ff9800, 630px 1033px #4caf50, 1995px 1644px #00bcd4,
		1092px 712px #9c27b0, 1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
		19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3, 67px 712px #ff5722,
		89px 1406px #f44336, 275px 321px #009688, 592px 630px #e91e63, 1012px 1690px #9c27b0,
		1749px 23px #673ab7, 94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
		1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4, 1202px 46px #ff5722,
		890px 569px #ff5722, 1613px 813px #2196f3, 223px 252px #ff9800, 983px 1093px #f44336,
		726px 1029px #ffc107, 1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
		212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff, 1768px 516px #f44336,
		849px 391px #ff9800, 228px 1824px #fff, 1119px 1680px #ffc107, 812px 1480px #3f51b5,
		1438px 1585px #cddc39, 137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
		857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50, 1655px 911px #00bcd4,
		1102px 1216px #ff5722, 1807px 1044px #fff, 660px 435px #03a9f4, 299px 678px #4caf50,
		1193px 115px #ff9800, 918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
		108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722, 1004px 391px #673ab7,
		1529px 516px #f44336, 1206px 845px #cddc39, 347px 583px #009688, 1102px 1332px #f44336,
		709px 1756px #00bcd4, 1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
		320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722, 1546px 692px #f44336;
	animation: animStar 80s linear infinite;
}

@keyframes animStar {
	0% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(-2000px);
	}
}

button {
	border: none;
	padding: 0;
	font-size: 0;
	line-height: 0;
	background: none;
	cursor: pointer;
}

:focus {
	outline: 0;
}

.clearfix:before,
.clearfix:after {
	content: "\0020";
	display: block;
	height: 0;
	visibility: hidden;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}

/* 1. END BODY */
/***********************************/

/***********************************
			/* 2. CONTENT */
/***********************************/
/* 2.1. Section error */
.error {
	min-height: 100vh;
	position: relative;
	padding: 240px 0;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	text-align: center;
	margin-top: 70px;
}

.error__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.error__content {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.error__message {
	text-align: center;
	color: #181828;
}

.message__title {
	font-family: "Montserrat", sans-serif;
	font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 5px;
	font-size: 5.6rem;
	padding-bottom: 40px;
	max-width: 960px;
	margin: 0 auto;
}

.message__text {
	font-family: "Montserrat", sans-serif;
	line-height: 42px;
	font-size: 18px;
	padding: 0 60px;
	max-width: 680px;
	margin: auto;
}

.error__nav {
	max-width: 600px;
	margin: 40px auto 0;
	text-align: center;
}

.e-nav__form {
	position: relative;
	height: 45px;
	overflow: hidden;
	width: 170px;
	display: inline-block;
	vertical-align: top;
	border: 1px solid #212121;
	padding-left: 10px;
	padding-right: 46px;
}

.e-nav__icon {
	position: absolute;
	right: 15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #212121;
	-webkit-transition: color 0.25s ease;
	transition: color 0.25s ease;
}

.e-nav__link {
	height: 45px;
	line-height: 45px;
	width: 170px;
	display: inline-block;
	vertical-align: top;
	margin: 0 15px;
	border: 1px solid #181828;
	color: #181828;
	text-decoration: none;
	font-family: "Montserrat", sans-serif;
	text-transform: uppercase;
	font-size: 11px;
	letter-spacing: 0.1rem;
	position: relative;
	overflow: hidden;
}

.e-nav__link:before {
	content: "";
	height: 200px;
	background: #212121;
	position: absolute;
	top: 70px;
	right: 70px;
	width: 260px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-transform: rotate(50deg);
	transform: rotate(50deg);
}

.e-nav__link:after {
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	z-index: 999;
	position: relative;
}

.e-nav__link:after {
	content: "Home Page";
}

.e-nav__link:hover:before {
	top: -60px;
	right: -50px;
}

.e-nav__link:hover {
	color: #fff;
}

.e-nav__link:nth-child(2):hover:after {
	color: #fff;
}
/* 2.1. END Section Error */

/* 2.2. Social style */
.error__social {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 20px;
	z-index: 10;
}

.e-social__list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.e-social__icon {
	padding-bottom: 30px;
}

.e-social__icon:last-child {
	padding-bottom: 0;
}

.e-social__link {
	color: #fff;
	-webkit-transition: all 0.25s ease;
	transition: all 0.25s ease;
	display: block;
}

.e-social__link:hover {
	opacity: 0.7;
}
/* 2.2. END Social style */

/* 2.3. Lamp */
.lamp {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	margin: 0px auto;
	width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	transform-origin: center top;
	animation-timing-function: cubic-bezier(0.6, 0, 0.38, 1);
	animation: move 5.1s infinite;
}

@keyframes move {
	0% {
		transform: rotate(40deg);
	}
	50% {
		transform: rotate(-40deg);
	}
	100% {
		transform: rotate(40deg);
	}
}

.cable {
	width: 8px;
	height: 248px;
	background-image: linear-gradient(rgb(32 148 218 / 70%), rgb(193 65 25)),
		linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
}

.cover {
	width: 200px;
	height: 80px;
	background: #0bd5e8;
	border-top-left-radius: 50%;
	border-top-right-radius: 50%;
	position: relative;
	z-index: 200;
}

.in-cover {
	width: 100%;
	max-width: 200px;
	height: 20px;
	border-radius: 100%;
	background: #08ffff;
	position: absolute;
	left: 0px;
	right: 0px;
	margin: 0px auto;
	bottom: -9px;
	z-index: 100;
}
.in-cover .bulb {
	width: 50px;
	height: 50px;
	background-color: #08fffa;
	border-radius: 50%;
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -20px;
	margin: 0px auto;
	-webkit-box-shadow: 0 0 15px 7px rgba(0, 255, 255, 0.8),
		0 0 40px 25px rgba(0, 255, 255, 0.5), -75px 0 30px 15px rgba(0, 255, 255, 0.2);
	box-shadow: 0 0 25px 7px rgb(127 255 255 / 80%), 0 0 64px 47px rgba(0, 255, 255, 0.5),
		0px 0 30px 15px rgba(0, 255, 255, 0.2);
}

.light {
	width: 200px;
	height: 0px;
	border-bottom: 900px solid rgb(44 255 255 / 24%);
	border-left: 50px solid transparent;
	border-right: 50px solid transparent;
	position: absolute;
	left: 0px;
	right: 0px;
	top: 270px;
	margin: 0px auto;
	z-index: 1;
	border-radius: 90px 90px 0px 0px;
}
/* 2.3. END Lamp */
/***********************************
			/* 2. END CONTENT */
/***********************************/

/***********************************
			/* 3. RESPONSIVE */
/***********************************/
.error {
	overflow: hidden;
	max-height: 100vh;
}
@media (max-width: 1400px) {
	.lamp {
		zoom: 0.5;
	}
	.error__content {
		top: 55%;
	}
	.message__title {
		font-size: 3.5rem;
	}
}
@media (max-width: 900px) {
	.message__title {
		font-size: 34px;
	}
	.error__content {
		top: 55%;
	}
}
@media (max-width: 950px) {
	.lamp__wrap {
		max-height: 100vh;
		overflow: hidden;
		max-width: 100vw;
	}
	.error__social {
		bottom: 30px;
		top: auto;
		transform: none;
		width: 100%;
		position: fixed;
		left: 0;
	}
	.e-social__icon {
		display: inline-block;
		padding-right: 30px;
	}
	.e-social__icon:last-child {
		padding-right: 0;
	}
	.e-social__icon {
		padding-bottom: 0;
	}
}
@media (max-width: 750px) {
	body,
	html {
		max-height: 100vh;
	}
	.error__content {
		position: static;
		margin: 0 auto;
		transform: none;
		padding-top: 300px;
	}
	.error {
		padding-top: 0;
		padding-bottom: 100px;
		height: 100vh;
	}
}
@media (max-width: 650px) {
	.message__title {
		font-size: 36px;
		padding-bottom: 20px;
	}
	.message__text {
		font-size: 16px;
		line-height: 2;
		padding-right: 20px;
		padding-left: 20px;
	}
	.lamp {
		zoom: 0.6;
	}
	.error__content {
		padding-top: 180px;
	}
}
@media (max-width: 480px) {
	.message__title {
		font-size: 30px;
	}
	.message__text {
		padding-left: 10px;
		padding-right: 10px;
		font-size: 15px;
	}
	.error__nav {
		margin-top: 20px;
	}
}
</style>
