<script>
export default {
    props : {
        type : {
            type : Boolean,
            default : false
        }
    }
}
</script>

<template>
    <div v-if="type">
        <svg
            class="relative top-1"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-config-id="auto-svg-1-1"
        >
            <path
                d="M4.16732 12.5L10.0007 6.66667L15.834 12.5"
                stroke="#4F46E5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></path>
        </svg>
    </div>
    <div v-if="!type">
        <svg
            class="relative top-1"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-config-id="auto-svg-2-1"
        >
            <path
                d="M14.25 6.75L9 12L3.75 6.75"
                stroke="#18181B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></path>
        </svg>
    </div>
</template>
