<script>
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import ApiDestinasiDanAtraksi from "@/store/api/destinasiAtraksi";
import { onMounted, reactive, toRefs,getCurrentInstance } from "vue";
import { useRouter } from "vue-router";

export default {
    name: "Destinasi Dan Atraksi",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    setup() {
        const app = getCurrentInstance();
        const globalProperties = app.appContext.config.globalProperties;
        const router = useRouter();
        const state = reactive({
            datas: [],
        });
        onMounted(async () => {
            await getData();
        });
        const getData = async () => {
            state.datas = [];
            try {
                const { data } = await ApiDestinasiDanAtraksi.getData();
                state.datas = data;
            } catch (error) {
                globalProperties.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        };
        const hendelLink = (data) => {
            console.log("data", data);
            if (data.in_link) {
                router.push(data.in_link);
            } else {
                return (window.location = data.link);
            }
        };
        return {
            ...toRefs(state),
            hendelLink,
        };
    },
};
</script>
<template>
    <div class="mx-2 sm:mx-20 md:mx-32 mb-10 " v-if="datas.length > 0">
        <h2
            class="mb-2 mt-10 md:text-5xl text-2xl text-center font-bold font-heading tracking-px-n leading-tight dark:text-slate-200 text-slate-200"
        >
            Destinasi dan Atraksi
        </h2>
        <div class="text-center mb-4 text-slate-200 text-xs">
            <span
                >Kami menyediakan keindahan, keragaman dan kenyamanan di
                Gorontalo</span
            >
        </div>
        <Carousel :itemsToShow="3.95" :wrapAround="true" :transition="500">
            <Slide v-for="destinasi in datas" :key="destinasi">
                <div
                    @click="hendelLink(destinasi)"
                    class="carousel__item bg-white bg-no-repeat bg-center bg-cover "
                    :style="`background-image: url('${destinasi.image}');border-radius: 10px;`"
                >
                    <span
                        class="xl:hidden block mb-2 font-bold font-heading md:max-w-xl leading-none dark:text-slate-200 text-white opacity-100 text-shadow-custom"
                        >{{ destinasi.name }}</span
                    >
                </div>
            </Slide>
            <template #addons>
                <Navigation />
                <!-- <Pagination /> -->
            </template>
        </Carousel>
    </div>
</template>
<style scoped>
.carousel__item {
    min-height: 150px;
    width: 223px;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    /* border-radius: 8px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel__slide {
    padding: 5px;
}

.carousel__viewport {
    perspective: 2000px;
}

.carousel__track {
    transform-style: preserve-3d;
}

.carousel__slide--sliding {
    transition: 0.5s;
}

.carousel__slide {
    opacity: 0.9;
    transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
    transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
    opacity: 1;
    transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
    opacity: 1;
    transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1.1);
}
</style>
