<template>
    <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
            <CardSettings />
        </div>
    </div>
</template>
<script>
import CardSettings from "@/components/Cards/BelakanCardSettings.vue";

export default {
    components: {
        CardSettings,
    },
};
</script>
