import axios from "axios";
const point = "https://covid-19.gorontaloprov.go.id/api";
const TableUnor36 = {
    async cekKoneksi() {
        try {
            const data = await axios.get(point + "/virus-gorontalo", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (error.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
    async getCovid() {
        try {
            const data = await axios.get(point + "/virus-gorontalo", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (error.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
};

export default TableUnor36;