import TabelSeting from "@/store/fire/setting";
import Seting from "@/store/api/seting";
import Menu from "@/store/api/menu";
import Chache from "@/store/cache1.js";
const setting = {
    namespaced: true,
    state: {
        data: {
            judul: "",
            sub_judul: "",
            email: "",
            alamat: "",
            link_background: "",
            sosial_media: [],
        },
        data_v2: {
            title: "",
            subTitle: "",
            description: "",
            bg_image: "",
            domain: "",
            alamat: "",
            telepon: "",
            faks: "",
            email: "",
            logo: "",
            audioLink: "",
            facebook: "",
            instagram: "",
            twitter: "",
            youtube: "",
            tiktok: "",

            // socialMedia: null,
        },
        data_menu: [],
    },
    getters: {
        data: (state) => {
            const dataChache = Chache.get("dataSetting");
            if (dataChache) {
                return JSON.parse(JSON.stringify(dataChache));
            }
            return state.data;
        },
        data_v2: (state) => {
            const dataChache = Chache.get("dataSettingV2");
            if (dataChache) {
                return JSON.parse(JSON.stringify(dataChache));
            }
            return state.data_v2;
        },
        data_menu: (state) => {
            const dataChache = Chache.get("dataSettingMenu");
            if (dataChache) {
                return JSON.parse(JSON.stringify(dataChache));
            }
            return state.data_menu;
        },
    },
    mutations: {
        SET_SETTING_DATA(state, data) {
            state.data = JSON.parse(JSON.stringify(data));
            Chache.set("dataSetting", state.data);
        },
        SET_SETTING_DATA_V2(state, data) {
            const datas = JSON.parse(JSON.stringify(data));
            // console.log('cek value',datas)
            const title = datas.find((e) => {
                return e.key == "site.title";
            }).value;
            const subTitle = datas.find((e) => {
                return e.key == "site.subTitle";
            }).value;
            const description = datas.find((e) => {
                return e.key == "site.description";
            }).value;
            const bg_image = datas.find((e) => {
                return e.key == "site.bg_image";
            }).value;

            const domain = datas.find((e) => {
                return e.key == "site.domain";
            }).value;
            // console.log("cek value title", domain);

            const alamat = datas.find((e) => {
                return e.key == "site.alamat";
            }).value;
            // console.log("cek value title", alamat);

            const telepon = datas.find((e) => {
                return e.key == "site.telepon";
            }).value;
            // console.log("cek value title", telepon);

            const faks = datas.find((e) => {
                return e.key == "site.faks";
            }).value;
            // console.log("cek value title", faks);

            const email = datas.find((e) => {
                return e.key == "site.email";
            }).value;
            // console.log("cek value title", email);

            const logo = datas.find((e) => {
                return e.key == "site.logo";
            }).value;
            // console.log("cek value title", logo);
            
            const audioLink = datas.find((e) => {
                return e.key == "site.audioLink";
            }).value;
            // console.log("cek value title", audioLink);

            const facebook = datas.find((e) => {
                return e.key == "socialmedia.facebook";
            }).value;
            // console.log("cek value facebook", facebook);

            const instagram = datas.find((e) => {
                return e.key == "socialmedia.instagram";
            }).value;
            // console.log("cek value instagram", instagram);

            const twitter = datas.find((e) => {
                return e.key == "socialmedia.twitter";
            }).value;
            // console.log("cek value twitter", twitter);

            const youtube = datas.find((e) => {
                return e.key == "socialmedia.youtube";
            }).value;
            // console.log("cek value youtube", youtube);

            const tiktok = datas.find((e) => {
                return e.key == "socialmedia.tiktok";
            }).value;
            // console.log("cek value tiktok", tiktok);

            const cekdatD =  {
                title,
                subTitle,
                description,
                bg_image,
                domain,
                alamat,
                telepon,
                faks,
                email,
                logo,
                facebook,
                instagram,
                twitter,
                youtube,
                tiktok,
                audioLink
            };

            state.data_v2 = cekdatD;
            Chache.set("dataSettingV2", state.data_v2);
        },
        SET_SETTING_MENU(state, data) {
            state.data_menu = JSON.parse(JSON.stringify(data));
            Chache.set("dataSettingMenu", state.data_menu);
        },
    },
    actions: {
        async setSettingDataV2({ commit }) {
            const dataChache = Chache.get("dataSettingV2");
            
            if (!dataChache) {
                const {data} = await Seting.getSetting();
                // console.log('dataChache',data);
                commit("SET_SETTING_DATA_V2", data);
                return false;
            }
        },
        async setSettingMenu({ commit }) {
            const dataChache = Chache.get("dataSettingMenu");
            if (!dataChache) {
                const data = await Menu.getNavbar();
                commit("SET_SETTING_MENU", data);
                return false;
            }
        },
        async setSettingData({ commit }) {
            const dataChache = Chache.get("dataSetting");
            if (!dataChache) {
                const data = await TabelSeting.getData("data");
                commit("SET_SETTING_DATA", data);
                return false;
            }
        },
    },
};

export default setting;