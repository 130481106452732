<script>
import ColorBedge from "@/components/Bedge/ColorBedge.vue";
import LogoBedge from "@/components/Bedge/LogoBedge.vue";
import Previwe from "@/components/Depan/Grup.vue";
import { createGrup, updateGrup } from "@/store/fire/grup";
import { isLoading } from "@/store/reactive.js";
export default {
    setup() {
        return { isLoading };
    },
    name: "modal-grup",
    components: {
        LogoBedge,
        ColorBedge,
        Previwe,
    },
    computed: {
        color: {
            get() {
                return this.$store.state.styleClass.color;
            },
            set(color) {
                return this.$store.dispatch("styleClass/setColor", color);
            },
        },
        logo: {
            get() {
                return this.$store.state.styleClass.logo;
            },
            set(logo) {
                return this.$store.dispatch("styleClass/setLogo", logo);
            },
        },
    },
    data() {
        return {
            showModal: false,
            loading: false,
            form: {
                ids: "",
                name: "",
                show: "active",
                status: "active",
            },
        };
    },
    methods: {
        toggleModal(createOrUdate = "", data = null) {
            this.form.ids = "";
            this.form.name = "";
            if (createOrUdate == "edit") {
                this.form.ids = data.id;
                this.form.name = data.name;
                this.form.status = data.status;
                this.form.show = data.show;
                this.color = data.color_logo;
                this.logo = data.logo;
            }
            this.showModal = !this.showModal;
        },
        setColor(color) {
            this.$store.dispatch("styleClass/setColor", color);
        },
        async update(id, data) {
            isLoading.value = true;
            try {
                const update = await updateGrup(id, data);
                const dataAdd = { id, ...data };
                this.$wkToast("Berhasil di simpan");
                if (update) {
                    this.$emit("edit-data", dataAdd);
                    this.$store.dispatch("grupArray/update", dataAdd);
                    this.showModal = false;
                    this.clear();
                }

                isLoading.value = false;
            } catch (error) {
                isLoading.value = false;
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
        async simpan() {
            const grup = {
                name: this.form.name,
                status: this.form.status,
                show: this.form.show,
                logo: this.$store.state.styleClass.logo,
                color_logo: this.$store.state.styleClass.color,
            };
            if (this.form.ids != "") {
                return this.update(this.form.ids, grup);
            }

            isLoading.value = true;

            try {
                const simpanData = await createGrup(grup);
                const dataAdd = { id: simpanData, ...grup };
                this.$wkToast("Berhasil Di simpan");
                if (simpanData) {
                    this.$emit("tambah-data", dataAdd);
                    this.$store.dispatch("grupArray/create", dataAdd);
                    this.showModal = false;
                    this.clear();
                }

                isLoading.value = false;
            } catch (error) {
                isLoading.value = false;
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
        clear() {
            this.form = {
                ids: "",
                name: "",
                status: "active",
                show: "active",
            };
        },
    },
};
</script>
<template>
    <div>
        <div v-if="showModal" class="modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <!--header-->
                    <div class="modal-header">
                        <h6 class="text-blueGray-700 text-xl font-bold">
                            Form Grup
                        </h6>
                        <button
                            class="btn-danger-sm"
                            :disabled="isLoading"
                            type="button"
                            @click="toggleModal()"
                        >
                            <span> × </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="modal-body">
                        <form>
                            <h6
                                class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"
                            >
                                Grup Informasi
                            </h6>
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Name Grup"
                                            v-model="form.name"
                                        />
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Show
                                        </label>
                                        <select
                                            v-model="form.show"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            <option value="active">
                                                active
                                            </option>
                                            <option value="non-active">
                                                non-active
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Status
                                        </label>
                                        <select
                                            v-model="form.status"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            <option value="active">
                                                active
                                            </option>
                                            <option value="non-active">
                                                non-active
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Logo
                                        </label>
                                        <input
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Logo"
                                            v-model="logo"
                                        />
                                        <LogoBedge />
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            color logo
                                        </label>
                                        <input
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="color logo"
                                            v-model="color"
                                        />
                                        <ColorBedge />
                                    </div>
                                </div>

                                <div class="w-full lg:w-4/12 px-4">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Preview
                                    </label>
                                    <Previwe
                                        :data="{
                                            name: form.name,
                                            logo: logo,
                                            color_logo: color,
                                        }"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--footer-->
                    <div class="modal-footer">
                        <div class="p-2">
                            <button
                                @click="toggleModal()"
                                class="btn-danger"
                                :disabled="isLoading"
                                type="button"
                            >
                                Keluar
                            </button>
                            <button
                                @click="simpan"
                                class="btn-success"
                                :disabled="isLoading"
                                type="button"
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
