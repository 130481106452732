<script>
import Artike from "../../store/api/artikel";
import { isLoading } from "@/store/reactive.js";
import {
    BrandFacebookIcon,
    BrandTwitterIcon,
    BrandWhatsappIcon,
} from "vue-tabler-icons";
import dataStrore from "@/store/index";
import { reactive, onMounted, ref, toRefs, watch, onBeforeMount } from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import { useSeoMeta } from "@unhead/vue";

export default {
    components: {
        BrandFacebookIcon,
        BrandTwitterIcon,
        BrandWhatsappIcon,
    },

    setup() {
        const route = useRoute();
        const dataReactive = reactive({
            slugIsi: null,
            data: null,
            pr: [],
            metas: [],
        });
        onBeforeMount(() => {
            setSlug(route.params.slug);
        });
        onMounted(() => {
            setSlug(route.params.slug);
        });
        const setSlug = (data) => {
            dataReactive.slugIsi = data;
            getData(data);
        };
        const getData = async (slug) => {
            isLoading.value = true;
            dataReactive.data = null;
            try {
                const { data, pr } = await Artike.getArtikeBySlug(slug);
                dataReactive.data = data;
                dataReactive.pr = pr;
                useSeoMeta({
                    title: data.title,
                    description: data.body_text.substr(0, 100),
                    ogDescription: data.body_text.substr(0, 100),
                    ogTitle: data.title,
                    ogImage: data.image,
                    twitterCard: "summary_large_image",
                });
            } catch (error) {
            } finally {
                isLoading.value = false;
            }
        };
        watch(
            () => route.params.slug,
            (slug) => {
                setSlug(slug);
            }
        );

        const baseUrl = window.location.origin;
        return { isLoading, baseUrl, ...toRefs(dataReactive) };
    },
};
</script>

<template>
    <section class="text-gray-600 body-font overflow-hidden lg:mx-20 mx-5">
        <div class="container py-10">
            <div
                v-if="data"
                class="flex items-start justify-center flex-col lg:flex-row gap-5"
            >
                <div class="w-full lg:w-1/5 mb-5">
                    <!-- <div
                        class="flex flex-row items-center lg:flex-col gap-2 lg:gap-6"
                    >
                        <div class="whitespace-pre-line">
                            <span class="text-xl">Bagikan artikel</span>
                        </div>
                        <div>
                            <a
                                target="_blank"
                                :href="`https://www.facebook.com/sharer.php?u=${baseUrl}/artikel/${data.slug}`"
                            >
                                <span>
                                    <BrandFacebookIcon
                                        class="bg-blue-600 h-10 w-10 md:h-12 md:w-12 rounded-full text-white p-2"
                                    />
                                </span>
                            </a>
                        </div>
                        <div>
                            <a
                                target="_blank"
                                :href="`https://twitter.com/intent/tweet?text=${baseUrl}/artikel/${data.slug}`"
                            >
                                <span>
                                    <BrandTwitterIcon
                                        class="bg-blue-400 h-10 w-10 md:h-12 md:w-12 rounded-full text-white p-2"
                                    />
                                </span>
                            </a>
                        </div>
                        <div>
                            <a
                                target="_blank"
                                :href="`https://wa.me/?text=${baseUrl}/artikel/${data.slug}`"
                            >
                                <span>
                                    <BrandWhatsappIcon
                                        class="bg-emerald-500 h-10 w-10 md:h-12 md:w-12 rounded-full text-white p-2 text-center"
                                    />
                                </span>
                            </a>
                        </div>
                    </div> -->
                </div>
                <div class="w-full flex flex-col">
                    <span
                        v-if="data.category_id"
                        class="inline-block py-1 px-2 rounded bg-indigo-50 text-indigo-500 text-xs font-medium tracking-widest"
                        >{{ data.category.name }}</span
                    >
                    <h2
                        class="sm:text-3xl text-2xl title-font font-medium text-gray-900 dark:text-slate-200 mt-4 mb-4"
                    >
                        {{ data.title }}
                    </h2>
                    <p
                        class="mb-2 text-coolGray-500 font-medium dark:text-slate-300"
                        data-config-id="auto-txt-10-1"
                    >
                        {{ data.user?.name }} • {{ data.waktu }}
                    </p>
                    <!-- image -->
                    <div class="w-full">
                        <img
                            class="mb-9 w-full"
                            :src="data.image"
                            alt=""
                            data-config-id="auto-img-1-4"
                        />
                        <div class="flex flex-wrap -m-4">
                            <div class="w-full md:w-1/2 p-4">
                                <p
                                    class="mb-3 text-xs text-gray-500 font-bold"
                                    data-config-id="auto-txt-3-4"
                                >
                                    {{ data.excerpt }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- image -->
                    <div class="w-full" id="clear-style">
                        <div
                            class="mb-8 stleBaru clear-style text-black dark:text-slate-400 overflow-x-auto"
                            v-html="data.body"
                        ></div>
                    </div>

                    <div
                        class="flex items-center flex-wrap pb-4 mb-4 border-b-2 border-gray-100 mt-auto w-full"
                    ></div>
                </div>
                <div class="w-full lg:w-1/3">
                    <!-- <div><span class="text-xl">Bagikan artikel</span></div> -->
                    <div
                        class="flex flex-row items-center lg:flex-col gap-2 lg:gap-6"
                    >
                        <div class="whitespace-pre-line">
                            <span class="text-xl dark:text-slate-300"
                                >Artikel Terkait</span
                            >
                            <div class="mt-5">
                                <div
                                    class="flex flex-col items-start rounded-md py-10 px-5 gap-2"
                                >
                                    <a
                                        v-for="publicRelation in pr"
                                        :key="publicRelation"
                                        class="group"
                                    >
                                        <!-- @mouseenter="hendelBaca" -->
                                        <router-link
                                            :to="`/artikel/${publicRelation.slug}`"
                                        >
                                            <div
                                                class="flex flex-wrap items-center -m-4"
                                            >
                                                <div class="w-auto p-4">
                                                    <div
                                                        class="overflow-hidden rounded-xl"
                                                    >
                                                        <img
                                                            v-if="
                                                                publicRelation.image
                                                            "
                                                            class="transform hover:scale-110 transition ease-out duration-500"
                                                            :src="
                                                                publicRelation.image
                                                            "
                                                            :alt="
                                                                publicRelation.image
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="flex-1 p-4">
                                                    <p
                                                        class="mb-4 font-heading font-medium text-lg text-gray-900 group-hover:underline dark:text-slate-300"
                                                    >
                                                        {{
                                                            publicRelation.title
                                                        }}
                                                    </p>

                                                    <h4
                                                        class="font-heading font-medium text-xs uppercase text-gray-500 tracking-px"
                                                        data-config-id="auto-txt-6-3"
                                                    >
                                                        <span
                                                            class="inline-block mb-3 text-xs px-2 py-1 bg-blue-50 rounded uppercase text-blue-400 font-semibold"
                                                            v-for="categorie in publicRelation.categories"
                                                            :key="categorie"
                                                        >
                                                            {{ categorie }}
                                                        </span>
                                                        {{
                                                            publicRelation.date
                                                        }}
                                                    </h4>
                                                </div>
                                            </div>
                                        </router-link>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style>
#clear-style p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
#clear-style > .mce-item-table:not([border]), .mce-item-table:not([border]) caption, .mce-item-table:not([border]) td, .mce-item-table:not([border]) th, .mce-item-table[border="0"], .mce-item-table[border="0"] caption, .mce-item-table[border="0"] td, .mce-item-table[border="0"] th, table[style*="border-width: 0px"], table[style*="border-width: 0px"] caption, table[style*="border-width: 0px"] td, table[style*="border-width: 0px"] th {
    border: 1px dashed #bbb;
}
#clear-style table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
#clear-style colgroup {
    display: table-column-group;
}

#clear-style tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

#clear-style tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

#clear-style table[border]:not([border="0"]):not([style*="border-color"]) th,
table[border]:not([border="0"]):not([style*="border-color"]) td {
    border-color: #000;
}
#clear-style table[border]:not([border="0"]):not([style*="border-style"]) th,
table[border]:not([border="0"]):not([style*="border-style"]) td {
    border-style: solid;
}
#clear-style table[border]:not([border="0"]):not([style*="border-width"]) th,
table[border]:not([border="0"]):not([style*="border-width"]) td {
    border-width: 1px;
}
#clear-style table:not([cellpadding]) th,
table:not([cellpadding]) td {
    padding: 0.4rem;
}
#clear-style td {
    display: table-cell;
    vertical-align: inherit;
}
#clear-style .mce-content-body [contentEditable="false"][data-mce-selected] {
    cursor: not-allowed;
    outline: 3px solid #b4d7ff;
}
#clear-style .mce-content-body figure.align-left {
    float: left;
}
#clear-style .mce-content-body figure.align-left {
    float: left;
}
#clear-style .mce-content-body [contentEditable="false"] {
    cursor: default;
}
#clear-style figure {
    display: table;
    margin: 1rem auto;
}

#clear-style .mce-content-body [contentEditable="false"][data-mce-selected] {
    cursor: not-allowed;
    outline: 3px solid #b4d7ff;
}
#clear-style .mce-content-body [contentEditable="true"] {
    cursor: text;
}
#clear-style figure figcaption {
    color: #999;
    display: block;
    margin-top: 0.25rem;
    text-align: center;
}
#clear-style ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
#clear-style li {
    display: list-item;
    text-align: -webkit-match-parent;
}
#clear-style a:-webkit-any-link:read-write {
    cursor: text;
}
#clear-style a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}
#clear-style ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
#clear-style strong {
    font-weight: bold;
}
</style>
