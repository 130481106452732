<script>
export default {
    data() {
        return {
            logos: [
                { key: "fab fa-facebook" },
                { key: "fab fa-google" },
                { key: "fab fa-whatsapp" },
                { key: "fab fa-instagram" },
                { key: "fab fa-telegram" },
                { key: "fab fa-twitter" },
                { key: "fab fa-youtube" },
                { key: "fas fa-heart" },
            ],
        };
    },
};
</script>

<template>
    <div class="relative w-full mb-3">
        <a
            target="_blank"
            href="https://fontawesome.com/v5/search?m=free&s=solid"
            >Icon Reverensi</a
        >
        <span> `fas fa-heart`</span> =
        <i class="fas fa-heart"></i>
        <div></div>
        <button
            type="button"
            class="my-1 mx-1 rounded w-7 h-7 text-center"
            :class="$store.state.styleClass.color"
            v-for="(logo, key) in logos"
            :key="`logo${key}`"
            @click="$emit('get', logo.key)"
        >
            <span class="text-xs text-white py-2 px-1">
                <i :class="logo.key"></i>
            </span>
        </button>
    </div>
</template>
