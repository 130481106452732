<script>
export default {
    data() {
        return {
            colors: [
                { name: "Slate", key: "bg-slate-500" },
                { name: "Gray", key: "bg-gray-500" },
                { name: "Zinc", key: "bg-zinc-500" },
                { name: "Neutral", key: "bg-neutral-500" },
                { name: "Stone", key: "bg-stone-500" },
                { name: "Red", key: "bg-red-500" },
                { name: "Orange", key: "bg-orange-500" },
                { name: "Amber", key: "bg-amber-500" },
                { name: "Yellow", key: "bg-yellow-500" },
                { name: "Lime", key: "bg-lime-500" },
                { name: "Green", key: "bg-green-500" },
                { name: "Emerald", key: "bg-emerald-500" },
                { name: "Teal", key: "bg-teal-500" },
                { name: "Cyan", key: "bg-cyan-500" },
                { name: "Sky", key: "bg-sky-500" },
                { name: "Blue", key: "bg-blue-500" },
                { name: "Indigo", key: "bg-indigo-500" },
                { name: "Violet", key: "bg-violet-500" },
                { name: "Purple", key: "bg-purple-500" },
                { name: "Fuchsia", key: "bg-fuchsia-500" },
                { name: "Pink", key: "bg-pink-500" },
                { name: "Rose", key: "bg-rose-500" },
            ],
        };
    },
};
</script>

<template>
    <div class="relative w-full mb-3">
        <a
            class="mr-1"
            href="#"
            v-for="(color, key) in colors"
            :key="`color${key}`"
            @click="$store.dispatch('styleClass/setColor', color.key)"
        >
            <span
                class="text-xs font-semibold inline-block py-1 px-2 mr-1 mt-1 mb-1 uppercase rounded text-black uppercase last:mr-0"
                :class="color.key"
            >
                {{ color.name }}
            </span>
        </a>
    </div>
</template>
