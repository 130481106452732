<script>
import { isShowInfo } from "@/store/reactive.js";

export default {
    setup() {
        return { isShowInfo };
    },
    props: {
        image: {
            type: String,
            default: ""
        },
        link: {
            type: String,
            default: ""
        },
        in_link: {
            type: String,
            default: ""
        }
    },
    methods: {
        hendelClose() {
            isShowInfo.value = false;
        }
    }
};
</script>

<template>
    <div>
        <template v-if="in_link">
            <div v-if="isShowInfo" id="iconhut" class="posisi">
                <div class="breakout md:h-48 md:w-48 h-20 w-20 md:-ml-10 ml-10"></div>
                <router-link
                    :to="in_link"
                    class="md:h-48 md:w-48 h-20 w-20 md:-ml-4 ml-16 smahkan bg-white bg-no-repeat bg-center bg-cover rounded-full"
                    :style="`background-image: url('${image}');
        `"
                ></router-link>
                <a @click="hendelClose()">
                    <div id="cls" class="md:left-40 md:top-16 left-36 top-5">
                        <span class>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-x"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M18 6l-12 12" />
                                <path d="M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                </a>
            </div>
        </template>
        <template v-if="!in_link">
            <div v-if="isShowInfo" id="iconhut" class="posisi">
                <div class="breakout md:h-48 md:w-48 h-20 w-20 md:-ml-10 ml-10"></div>
                <a
                    :href="link"
                    target="_blank"
                    class="md:h-48 md:w-48 h-20 w-20 md:-ml-4 ml-16 smahkan bg-white bg-no-repeat bg-center bg-cover rounded-full"
                    :style="`background-image: url('${image}');
        `"
                ></a>
                <a @click="hendelClose()">
                    <div id="cls" class="md:left-40 md:top-16 left-36 top-5">
                        <span class>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-x"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M18 6l-12 12" />
                                <path d="M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                </a>
            </div>
        </template>
    </div>
</template>
<style scoped>
#cls {
    position: absolute;
    /* left: 160px; */
    /* top: 70px; */
    z-index: 9999999;
    color: #f00;
    font-size: 30px;
}
.posisi {
    position: fixed;
    /* right: 15vw;
    top: 15vw; */
    right: 230px;
    top: 200px;
    z-index: 99999;
}
.smahkan {
    position: absolute;
    /* height: 200px; */
    /* width: 200px; */

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@-webkit-keyframes breakout {
    0%,
    40% {
        border-width: 25px;
    }
    60% {
        border-color: red;
        border-width: 6px;
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        border-color: red;
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}
@keyframes breakout {
    0%,
    40% {
        border-width: 25px;
    }
    60% {
        border-color: red;
        border-width: 6px;
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        border-color: red;
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

.breakout {
    position: absolute;
    /* height: 150px; */
    /* width: 150px; */

    margin: auto;
    top: 0;
    bottom: 0;
    left: 25px;
    right: 0;
}
.breakout:before {
    -webkit-animation: breakout 1.5s infinite;
    animation: breakout 1.5s infinite;
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    border: 18px solid red;
    border-radius: 100%;
}
</style>
