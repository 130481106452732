<script>
import { ZoomQuestionIcon } from "vue-tabler-icons";
import Helper from "@/store/helper";
// import Seting from "@/store/api/seting";
export default {
    components: {
        ZoomQuestionIcon,
    },
    data() {
        return {
            form: {
                cari: null,
            },
        };
    },
    methods: {
        hendelBaca(e) {
            if (!this.$store.state.voice.isVoice) {
                return true;
            }
            let text = e.target.textContent;
            if (!text.trim()) {
                text = e.target.getAttribute("alt");
                if (!text) {
                    text = e.target.getAttribute("title");
                }
            }
            Helper.startVoice(text);
        },
        hendelCari(e) {
            e.preventDefault();
            if (this.form.cari != "") {
                this.$router.push({
                    name: "cari",
                    params: { judul: this.form.cari },
                });
            }
        },
    },
};
</script>

<template>
    <section
        data-section-id="1"
        data-share=""
        data-category="headers"
        data-component-id="44d0b9e2_01_awz"
        class="bg-white bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden"
        :style="`background-image: url('${$store.getters['setting/data_v2'].bg_image}');
        `"
    >
        <!-- height: 85vh -->
        <div
            class="bg-black bg-opacity-10 pt-16 px-14 md:20"
            style="backdrop-filter: blur(0px)"
        >
            <div class="container px-4 mx-auto">
                <div class="flex flex-wrap -m-8">
                    <div class="w-full md:w-1/2 mx-2 sm:mx-20 md:mx-32">
                        <div
                            class="mb-20 md:mb-60 inline-block px-2 py-1 font-semibold bg-green-100 rounded-full"
                        >
                            <div
                                class="flex flex-wrap items-center -m-1 opacity-100"
                            >
                                <div class="w-auto p-1">
                                    <a
                                        @mouseenter="hendelBaca"
                                        target="_blank"
                                        href="https://berita.gorontaloprov.go.id"
                                        ><span class="text-sm text-slate-800"
                                            >👋 Wololo Habari?</span
                                        ></a
                                    >
                                </div>
                                <div class="w-auto p-1">
                                    <span>
                                        <svg
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-config-id="auto-svg-3-1"
                                        >
                                            <path
                                                d="M8.66667 3.41675L12.75 7.50008M12.75 7.50008L8.66667 11.5834M12.75 7.50008L2.25 7.50008"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            ></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- drop-shadow-xl  -->
                        <h1
                            class="mb-2 text-3xl md:text-6xl lg:text-10xl font-bold font-heading md:max-w-xl leading-none dark:text-slate-200 text-white opacity-100 text-shadow-custom"
                            data-config-id="auto-txt-12-1"
                        >
                            {{ $store.getters["setting/data_v2"].subTitle }}
                        </h1>
                        <p
                            class="mb-2 text-md text-slate-200 font-medium md:max-w-md dark:text-slate-300 opacity-100 text-shadow-custom"
                            data-config-id="auto-txt-13-1"
                        >
                            {{ $store.getters["setting/data_v2"].description }}
                        </p>
                        <div class="flex flex-wrap mb-5">
                            <div class="w-full my-2">
                                <div class="relative">
                                    <div
                                        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                                    >
                                        <ZoomQuestionIcon />
                                    </div>
                                    <form @submit="hendelCari">
                                        <div class="w-full">
                                            <div class="w-full">
                                                <input
                                                    v-model="form.cari"
                                                    type="search"
                                                    id="default-search"
                                                    class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Apa Yang Anda Cari ?"
                                                />
                                                <button
                                                    title="Tombol Aksi Pencarian"
                                                    @mouseenter="hendelBaca"
                                                    type="submit"
                                                    class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                >
                                                    <div class="inline-flex">
                                                        Cari
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 p-8 mb-10"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.blur-image {
    background-image: url("https://gorontaloprov.go.id/wp-content/uploads/2022/10/banner-s.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 1);
    opacity: 0.8;
    background-size: cover;
}
</style>
