import axios from "axios";
import { apiPortal } from "../../config";
const point = apiPortal + "/wilayah-gorontalo";
const WilayahGorontalo = {
    async getData() {
        try {
            const data = await axios.get(point);
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
};
export default WilayahGorontalo;
