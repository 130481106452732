<script>
import Hero from "@/components/Hero/Default.vue";
// import InfoTerkini from "@/components/Slaider/v2/Default.vue";
import InfoTerkini from "@/components/Depan/v2/InfoTerkiniSlaider.vue";
import InformasiLayanan from "@/components/Depan/v2/InformasiLayanan.vue";
import PortalLayanan from "@/components/Depan/v2/PortalLayanan.vue";
import SectionRH from "@/components/Depan/v2/SectionRH.vue";
import Wilayah from "@/components/Depan/v2/Wilayah.vue";
import Bener from "@/components/Depan/v2/Bener.vue";
import DestinasiDanAtraksi from "@/components/Depan/v2/DestinasiDanAtraksi.vue";
import Laporan from "@/components/Depan/v2/Laporan.vue";
import SectionBerita from "@/components/Depan/v2/SectionBerita.vue";
import SectionSocialMedia from "@/components/Depan/v2/SectionSocialMedia.vue";
import dataStrore from "@/store/index";
import { reactive, onMounted, ref, toRefs, onBeforeMount } from "vue";
import { useHead } from "@unhead/vue";
import BagroundSvg from "@/assets/svg/pattern-white.svg";
import BagroundDestinasiJpg from "@/assets/jpg/Destinasi-dan-Atraksi.jpg";
import PortalLayananJpg from "@/assets/jpg/Portal-Layanan.jpg";
import SocialMediaJpg from "@/assets/jpg/social-media.jpg";
import PrimasSvg from "../../assets/svg/prisma.svg";
import PrimasWhiteSvg from "../../assets/svg/prisma-white.svg";
import RadientGradientSvg from "../../assets/svg/radiant-gradient.svg";
import AnimetSvg from "../../assets/svg/animent.svg";
import LayerdWavesSvg from "../../assets/svg/layered-waves-haikei.svg";
import { useRouter, useRoute } from "vue-router";

export default {
	setup() {
		const router = useRouter();
		const route = useRoute();
		const stateRef = reactive({
			title: "",
			meta: [],
		});
		onBeforeMount(() => {
			useHead({
				title: "Provinsi Gorontalo",
				meta: [
					{
						name: "description",
						content: "Portal Provinsi Gorontalo",
					},
				],
			});
		});
		onMounted(async () => {
			const queryParam = route.query;
			if (Object.keys(queryParam).length) {
				// router.replace({ name: "NotFound" });
                window.location = '404.html'
			}
			await dataStrore.dispatch("setting/setSettingDataV2");
			await setMeta();
		});
		const setMeta = async () => {
			const dataTitle = dataStrore.state.setting.data_v2.title;
			const dataDescription = dataStrore.state.setting.data_v2.description;
			stateRef.title = dataTitle ? dataTitle : " -- Provinis Gorontalo --";
			stateRef.meta = [
				{
					name: "description",
					content: dataDescription ? dataDescription : "Portal Resmi Provinsi Gorontalo",
				},
			];
		};

		return {
			...toRefs(stateRef),
			BagroundSvg,
			PrimasSvg,
			PrimasWhiteSvg,
			RadientGradientSvg,
			AnimetSvg,
			LayerdWavesSvg,
			BagroundDestinasiJpg,
			PortalLayananJpg,
			SocialMediaJpg,
		};
	},
	name: "landing Page",
	components: {
		Hero,
		InfoTerkini,
		InformasiLayanan,
		PortalLayanan,
		Wilayah,
		Bener,
		DestinasiDanAtraksi,
		Laporan,
		SectionBerita,
		SectionSocialMedia,
		SectionRH,
	},
};
</script>
<template>
	<div>
		<!-- <teleport to="#teleportasiMeta">
        <title>{{title}}</title>
        </teleport>-->
		<Hero />
		<!-- <div class="py-10" style="background-color: #335f91;"> -->
		<div
			class="py-10"
			style="background-position: center"
			:style="`background-image: url('${BagroundDestinasiJpg}');`"
		>
			<div class="md:my-10 mx-5">
				<DestinasiDanAtraksi />
			</div>
		</div>
		<!-- slaider -->
		<InfoTerkini />
		<!-- end slaider -->
		<!-- informasi layanan -->
		<div>
			<InformasiLayanan />
		</div>
		<!-- end informasi layanan -->

		<div>
			<SectionRH />
		</div>
		<div
			class="bg-blue-800"
			style="background-position: center"
			:style="`background-image: url('${PortalLayananJpg}');`"
		>
			<PortalLayanan />
		</div>
		<!-- style=" background-position: center;"
        :style="`background-image: url('${BagroundSvg}');`" -->
		<div>
			<div class="lg:mx-60 md:my-10 mx-5 my-2">
				<Wilayah />
			</div>
		</div>
		<div class="md:mt-10 mt-2">
			<Bener />
		</div>
		<!-- <div class="py-10" style="background-color: #335f91;">
        <div class=" md:my-10 mx-5 ">
            <DestinasiDanAtraksi />
        </div>
        </div>-->
		<div
			class="md:py-10 py-5"
			style="
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			"
			:style="`background-image: url('${AnimetSvg}');`"
		>
			<div class="mx-5">
				<SectionBerita />
			</div>
		</div>
		<div
			class="md:py-10 py-5"
			style="
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			"
			:style="`background-image: url('${SocialMediaJpg}');`"
		>
			<div class="mx-5">
				<SectionSocialMedia />
			</div>
		</div>
		<!--  -->
		<div
			class="py-5 md:py-10"
			style="background-position: center"
			:style="`background-image: url('${PrimasSvg}');`"
		>
			<div class="mx-5">
				<Laporan />
			</div>
		</div>
	</div>
</template>
