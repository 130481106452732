<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Navigation, Slide } from "vue3-carousel";
export default {
    props: {
        datas: {
            type: Array,
            default: [],
        },
    },
    components: {
        Carousel,
        Navigation,
        Slide,
    },
};
</script>

<template>
    <!-- :autoplay="3500" -->
    <Carousel
        :autoplay="5500"
        :itemsToShow="2.5"
        :wrapAround="true"
        v-if="datas"
    >
        <Slide
            v-for="(dataPortalLayaan, key) in datas"
            :key="`portalLayananCosual${key}`"
        >
            <div class="carousel__item">
                <template v-if="dataPortalLayaan.in_link">
                    <router-link class="group" :to="dataPortalLayaan.in_link">
                        <div
                            class="group flex flex-col justify-center h-full relative overflow-hidden rounded-10"
                        >
                            <div
                                class="transform ease-out duration-500 rounded-lg bg-cover bg-center md:w-80 w-40 md:h-44 h-24"
                                :style="`background-image : url('${dataPortalLayaan.image}');`"
                            ></div>
                            <div class="absolute top w-full">
                                <div
                                    class="p-5 w-full bg-opacity-80 rounded-md"
                                >
                                    <p
                                        class="font-heading font-semibold text-2xl text-white group-hover:underline text-shadow-custom"
                                    >
                                        {{
                                            dataPortalLayaan.name.toUpperCase()
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </template>
                <template v-if="!dataPortalLayaan.in_link">
                    <a class="group" :href="dataPortalLayaan.link">
                        <div
                            class="group flex flex-col justify-center h-full relative overflow-hidden rounded-10"
                        >
                            <div
                                class="transform ease-out duration-500 rounded-lg bg-cover bg-center md:w-80 w-40 md:h-44 h-24"
                                :style="`background-image : url('${dataPortalLayaan.image}');`"
                            ></div>
                            <div class="absolute top w-full">
                                <div
                                    class="p-5 w-full bg-opacity-80 rounded-md"
                                >
                                    <p
                                        class="font-heading font-semibold text-lg md:text-3xl text-white group-hover:underline text-shadow-custom"
                                    >
                                        {{
                                            dataPortalLayaan.name.toUpperCase()
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </a>
                </template>
            </div>
        </Slide>

        <template #addons>
            <Navigation />
        </template>
    </Carousel>
</template>
<style scoped>
.carousel__slide > .carousel__item {
    transform: scale(1);
    opacity: 0.5;
    transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
    opacity: 1;
    transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
    transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
    transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
    transform: scale(1.1);
}
</style>
