<script>
import ApiData from "@/store/api/klipingBerita";
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { isLoading } from "@/store/reactive.js";
import { useRouter } from "vue-router";

export default {
    name: "Portal Layanan All",
    setup() {
        const router = useRouter();
        const app = getCurrentInstance();
        const globalProperties = app.appContext.config.globalProperties;
        const state = reactive({
            datas: [],
            page: 1,
            isNext: false
        });
        onMounted(async () => {
            await getData();
        });
        const getData = async () => {
            // state.datas = [];
            isLoading.value = true;
            try {
                const page = state.page;
                const { data, paginate } = await ApiData.getData(page);
                state.datas = [...state.datas, ...data];
                state.isNext = paginate.isNext;
                state.page = page + 1;
            } catch (error) {
                globalProperties.$wkToast(error, {
                    className: "wk-alert"
                });
            } finally {
                isLoading.value = false;
            }
        };
        const hendelLink = data => {
            // console.log("data", data);
            // router.push(`/majalah/${data.id}/baca`);
            if (data.in_link) {
                router.push(data.in_link);
            } else {
                return window.open(data.link, "_blank");
            }
        };
        const hendelNext = async () => {
            await getData();
        };

        return {
            ...toRefs(state),
            isLoading,
            hendelLink,
            hendelNext
        };
    }
};
</script>
<template>
    <section class="text-gray-600 body-font overflow-hidden mx-20">
        <div class="container py-10" v-if="datas.length > 0">
            <div class="flex flex-col items-center justify-center gap-5">
                <div class="w-full">
                    <div class="grid lg:grid-cols-4 grid-cols-1 gap-5">
                        <div class="w-full" v-for="(data, key) in datas" :key="`dataMajalah${key}`">
                            <div class="flex items-center justify-center">
                                <div class="max-w-sm mx-auto w-full">
                                    <div class="mb-6 overflow-hidden rounded-xl w-full">
                                        <div
                                            class="transform hover:scale-105 transition ease-in-out duration-1000 bg-cover bg-center md:w-80 w-40 md:h-52 h-24"
                                            :style="`background-image : url('${data.image}');`"
                                        ></div>
                                    </div>
                                    <a
                                        class="mb-2 inline-block hover:text-gray-800 hover:underline"
                                    >
                                        <h3
                                            class="text-xl font-bold font-heading leading-normal dark:text-slate-300"
                                            data-config-id="auto-txt-5-2"
                                        >
                                            <a
                                                @click="hendelLink(data)"
                                                @mouseenter="hendelBaca"
                                            >{{ data.name }}</a>
                                        </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full flex flex-start justify-center">
                    <div>
                        <button
                            v-if="isNext && !isLoading"
                            @click="hendelNext"
                            class="bg-blue-600 text-white py-2 px-5 rounded-lg"
                        >Selanjutnya</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
