<script>
import FormGrup from "@/components/Belakan/Grup/Form.vue";
import DataGrup from "@/components/Belakan/Grup/Data.vue";
import { saveAs } from "file-saver";
import { useLoadGrups, importGrups } from "@/store/fire/grup.js";
import dataGrupsJson from "@/data/grups.json";
import { isLoading } from "@/store/reactive.js";
export default {
    setup() {
        return { isLoading };
    },
    components: {
        DataGrup,
        FormGrup,
    },
    computed: {},
    data() {
        return {
            loading: false,
            dataGrups: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        tambah() {
            this.$refs.trigerModal.toggleModal();
        },
        async getData() {
            this.dataGrups = [];
            try {
                const grups = await useLoadGrups();
                this.dataGrups = grups;
                this.$store.dispatch("grupArray/setAll", this.dataGrups);
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
        exportData() {
            var blob = new Blob([JSON.stringify(this.dataGrups)], {
                type: "application/json;charset=utf-8",
            });
            saveAs(blob, "grups.json");
        },
        async importData() {
            const dataGrup = dataGrupsJson;
            if (JSON.parse(JSON.stringify(this.dataGrups)).length > 0) {
                return this.$wkToast("Tidak Data di import", {
                    className: "wk-alert",
                });
            }
            if (dataGrup.length < 1) {
                return this.$wkToast("Tidak Data Grup Yang di impor", {
                    className: "wk-alert",
                });
            }

            isLoading.value = true;
            try {
                const importData = await importGrups(dataGrup);
                this.$wkToast("Berhasil Import");
                this.dataGrups = dataGrup;
                this.$store.dispatch("grupArray/setAll", this.dataGrups);
                isLoading.value = false;
            } catch (error) {
                isLoading.value = false;
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
        tambahData(data) {
            const dataGrups = JSON.parse(JSON.stringify(this.dataGrups));
            this.dataGrups = dataGrups.concat(data);
        },
        editData(data) {
            const dataGrups = JSON.parse(JSON.stringify(this.dataGrups));
            this.dataGrups = dataGrups.map((el) => {
                if (el.id == data.id) {
                    return data;
                } else {
                    return el;
                }
            });
        },
        edit(data) {
            this.$refs.trigerModal.toggleModal("edit", data);
        },
        hapusData(data) {
            const dataGrups = JSON.parse(JSON.stringify(this.dataGrups));
            const find = dataGrups.find((el) => {
                return el.id == data;
            });
            const filter = dataGrups.filter((el) => {
                return el != find;
            });
            this.dataGrups = filter;
        },
    },
};
</script>

<template>
    <div>
        <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        >
            <div class="rounded-t bg-white mb-0 px-6 py-6">
                <div class="text-center flex justify-end">
                    <div>
                        <h6 class="text-blueGray-700 text-xl font-bold">
                            <!-- Grups -->
                        </h6>
                    </div>
                    <button @click="tambah" class="btn-success" type="button">
                        Tambah
                    </button>
                    <button
                        :disabled="isLoading"
                        @click="importData"
                        class="btn-success"
                        type="button"
                    >
                        <!-- Import -->
                        Set Default Data
                    </button>
                    <button
                        @click="exportData"
                        class="btn-success"
                        type="button"
                    >
                        export
                    </button>
                </div>
            </div>
        </div>
        <DataGrup :datas="dataGrups" @hapus-data="hapusData" @edit="edit" />
        <teleport to="#teleportasi">
            <FormGrup
                ref="trigerModal"
                @tambah-data="tambahData"
                @edit-data="editData"
            />
        </teleport>
    </div>
</template>
