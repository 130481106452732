import { dbFireStore } from "@/firebase.js";
import striptags from "striptags";
import {
    collection,
    query,
    getDocs,
    addDoc,
    Timestamp,
    deleteDoc,
    doc,
    updateDoc,
    deleteField,
    setDoc,
    orderBy,
    where,
} from "firebase/firestore";
const nameTable = "aplikasis";
const Colletion = collection(dbFireStore, nameTable);
const q = query(Colletion);
const queryPrivate = query(Colletion, orderBy("waktuInser", "desc"));
const validasi = (dataValidasi) => {
    if (dataValidasi.name == "") {
        throw "Name tidak boleh ksong";
    }
    if (dataValidasi.link == "") {
        throw "Link tidak boleh ksong";
    }
    if (dataValidasi.image_url == "") {
        throw "Image url tidak boleh ksong";
    }
    if (dataValidasi.status == "") {
        throw "status url tidak boleh ksong";
    }
    if (dataValidasi.show == "") {
        throw "show url tidak boleh ksong";
    }
    if (dataValidasi.grup == "") {
        throw "pilihan grup  tidak boleh ksong";
    }
};

const TableAplikasi = {
    async create(data) {
        validasi(data);
        try {
            const waktuInser = { waktuInser: Timestamp.fromDate(new Date()) };
            const dataInsert = {
                name: data.name,
                image_url: data.image_url,
                link: data.link,
                status: data.status,
                show: data.show,
                grup: data.grup,
                keterangan: striptags(data.keterangan),
                ...waktuInser,
            };
            const simpan = await addDoc(Colletion, dataInsert);
            return { id: simpan.id, ...waktuInser };
        } catch (error) {
            const errorCode = error.code;
            // const errorMessage = error.message;

            throw errorCode;
        }
    },
    async useLoadByGrupPublic(id) {
        let datas = [];
        try {
            const close = await getDocs(
                query(
                    Colletion,
                    where("grup", "==", id),
                    where("status", "==", "active")
                )
            );
            close.forEach((doc) => {
                let data = {
                    id: doc.id,
                    ...doc.data(),
                };
                datas.push(data);
            });
            if (datas.length < 1) {
                throw { code: "Belum Ada Data" };
            }
            return datas;
        } catch (error) {
            const errorCode = error.code;
            throw errorCode;
        }
    },
    async userLoadsPublic() {
        let datas = [];
        try {
            const close = await getDocs(queryPrivate);
            close.forEach((doc) => {
                let data = {
                    id: doc.id,
                    ...doc.data(),
                };
                datas.push(data);
            });
            return datas;
        } catch (error) {
            const errorCode = error.code;
            // const errorMessage = error.message;
            throw errorCode;
        }
    },
    async useLoads() {
        let datas = [];
        try {
            const close = await getDocs(queryPrivate);
            close.forEach((doc) => {
                let data = {
                    id: doc.id,
                    ...doc.data(),
                };
                datas.push(data);
            });
            return datas;
        } catch (error) {
            const errorCode = error.code;
            // const errorMessage = error.message;
            throw errorCode;
        }
    },

    async delete(id) {
        try {
            await updateDoc(doc(dbFireStore, nameTable, id), {
                image_url: deleteField(),
                keterangan: deleteField(),
                link: deleteField(),
                waktuInser: deleteField(),
                name: deleteField(),
                status: deleteField(),
                show: deleteField(),
                grup: deleteField(),
            });
            await deleteDoc(doc(dbFireStore, nameTable, id));
            return true;
        } catch (error) {
            const errorCode = error.code;
            // const errorMessage = error.message;
            throw errorCode;
        }
    },

    async imports(data = []) {
        for (let index = 0; index < data.length; index++) {
            try {
                await setDoc(doc(dbFireStore, nameTable, data[index].id), {
                    name: data[index].name,
                    link: data[index].link,
                    image_url: data[index].image_url,
                    waktuInser: data[index].waktuInser,
                    status: data[index].status,
                    show: data[index].show,
                    grup: data[index].grup,
                    keterangan: striptags(data[index].keterangan),
                });
            } catch (error) {
                const errorCode = error.code;
                // const errorMessage = error.message;
                throw errorCode;
            }
        }
        return "Berhasil import";
    },

    async update(id, data) {
        validasi(data);
        try {
            const waktuInser = { waktuInser: Timestamp.fromDate(new Date()) };
            const dataUpdate = await setDoc(doc(dbFireStore, nameTable, id), {
                image_url: data.image_url,
                link: data.link,
                name: data.name,
                status: data.status,
                show: data.show,
                grup: data.grup,
                keterangan: striptags(data.keterangan),
                ...waktuInser,
            });
            return {...waktuInser };
        } catch (error) {
            const errorCode = error.code;
            // const errorMessage = error.message;
            throw errorCode;
        }
    },
};
export default TableAplikasi;