<script>
export default {
    components: {},
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    methods: {
        openAplikasi(data) {
            this.$emit("get-data", data);
        },
    },
};
</script>
<template>
    <div class="my-2 mx-2 hover:opacity-90">
        <a href="#" @click="openAplikasi(data)">
            <div class="text-center relative bg-white shadow-lg rounded-lg">
                <div class="px-4 py-5 flex-auto">
                    <div
                        class="text-white text-center inline-flex items-center justify-center w-20 h-20 shadow-lg rounded-full shadow-zinc-800 hover:shadow-lg bg-cover bg-center"
                        :class="data.color_logo"
                    >
                        <i class="text-2xl" :class="data.logo"></i>
                    </div>
                    <h6
                        class="mt-5 text-xl font-semibold text-slate-900 break-words hover:text-slate-300"
                    >
                        {{ data.name }}
                    </h6>
                </div>
            </div>
        </a>
    </div>
</template>
