import unor from "@/data/unor.json";
const TableUnor = {
    getOpdStatus: async(status) => {
        let data = [];
        try {
            const dataUnor = await unor;
            data = dataUnor.filter((el) => {
                return el.status == status;
            });
            return data;
        } catch (error) {}
    },
    getOpdActive: async() => {
        let data = [];
        try {
            const dataUnor = await unor;
            data = dataUnor.filter((el) => {
                return el.status == "active";
            });
            return data;
        } catch (error) {}
    },
    getOpdNonActive: async() => {
        let data = [];
        try {
            const dataUnor = await unor;
            data = dataUnor.filter((el) => {
                return el.status == "non-active";
            });
            return data;
        } catch (error) {}
    },
    getAll: async() => {
        let data = [];
        try {
            const dataUnor = await unor;
            if (!dataUnor || dataUnor.length < 0) {
                throw "Tidak Ada Data";
            }
            data = dataUnor
                .map((el) => {
                    return {
                        id: el.id,
                        name: el.name,
                        image_name: "./image/svg/" + el.logo,
                        keterangan: el.keterangan,
                        status: el.status == "non-active" ? false : true,
                    };
                })
                .sort((a, b) => {
                    return a.id - b.id;
                })
                .sort((a, b) => {
                    return b.status - a.status;
                });
            return data;
        } catch (error) {
            throw error;
        }
    },
};

export default TableUnor;