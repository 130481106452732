import axios from "axios";
import { apiPortal } from "../../config";
const point = apiPortal + "/artikel";
const Artikel = {
    async getArtikeBySlug(slug) {
        try {
            const data = await axios.get(`${point}/${slug}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
    async getArtike(cari) {
        try {
            const data = await axios.get(`${point}`, {
                params: {
                    cari: cari,
                },
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
};
export default Artikel;
