<script>
export default {
    props: {
        target: {
            type: String,
            default: "_parent",
        },
        data: {
            type: Object,
            default: null,
        },
    },
};
</script>
<template>
    <div class="my-2 mx-2">
        <!-- mr-10  relative flex flex-col break-words bg-white mb-8 shadow-lg rounded-lg -->
        <div
            class="text-center relative shadow-lg rounded-lg hover:opacity-70 bg-cover bg-center"
            :style="`background-image: url('${data.image_url}')`"
        >
            <div class="px-4 py-5 flex-auto">
                <a
                    :title="data.name"
                    :href="data.link"
                    :target="target"
                    class="text-white text-center inline-flex items-center justify-center w-20 h-20 shadow-lg rounded-full shadow-zinc-800 hover:shadow-lg bg-cover bg-center"
                    :style="`background-image: url('${data.image_url}')`"
                >
                    <!-- <i :class="data.logo"></i> -->
                </a>
                <div
                    class="mt-5 bg-slate-800 rounded backdrop-opacity-10 space-y-1 mx-auto"
                >
                    <a
                        :title="data.name"
                        :href="data.link"
                        :target="target"
                        class="text-xl font-semibold text-white break-words hover:text-slate-300"
                    >
                        {{ data.name }}
                    </a>
                    <blockquote>
                        <p
                            class="p-1 text-sm font-medium text-slate-300 text-center break-words"
                        >
                            {{ data.keterangan }}
                        </p>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</template>
