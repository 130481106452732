<script>
import { isLoading } from "@/store/reactive.js";
import TableUnor16 from "@/store/sqlLite/unor/opd16.js";
import TableUnor28 from "@/store/sqlLite/unor/opd28.js";
import TableUnor36 from "@/store/sqlLite/unor/opd36.js";

export default {
    name: "modal-Unor",

    setup() {
        return { isLoading };
    },
    components: {},
    props: {
        datas: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            dataSets: [],
            loading: false,
            showModal: false,
        };
    },

    methods: {
        toggleModal(data) {
            if (data) {
                this.dataSets = data.map((el) => {
                    return {
                        id: el.id,
                        name: el.name,
                        status: el.status,
                        koneksi: "connect",
                    };
                });
            }
            this.showModal = !this.showModal;
        },
        async cekKoneksi(id) {
            this.dataSets[id].koneksi = "Loading....";
            this.loading = true;
            try {
                switch (this.dataSets[id].id) {
                    case 16:
                        await TableUnor16.cekKoneksi();
                        break;
                    case 28:
                        await TableUnor28.cekKoneksi();
                        break;
                    case 36:
                        await TableUnor36.cekKoneksi();
                        break;
                    default:
                        await true;
                        break;
                }
                this.dataSets[id].koneksi = "connect";
                this.loading = false;
            } catch (error) {
                this.dataSets[id].koneksi = "disconnect";
                this.loading = false;
            }
        },
        ping() {
            for (let index = 0; index < this.dataSets.length; index++) {
                this.cekKoneksi(index);
            }
        },
    },
};
</script>
<template>
    <div>
        <div v-if="showModal" class="modal modal-full">
            <div class="modal-dialog">
                <div class="modal-content">
                    <!--header-->
                    <div class="modal-header">
                        <h6 class="text-blueGray-700 text-xl font-bold">
                            Ping
                        </h6>
                        <button
                            class="btn-danger-sm"
                            :disabled="isLoading"
                            type="button"
                            @click="toggleModal()"
                        >
                            <span> × </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="modal-body">
                        <table
                            class="items-center w-full bg-transparent border-collapse"
                            border="1"
                        >
                            <thead>
                                <tr>
                                    <th
                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    >
                                        OPD/Instansi
                                    </th>
                                    <th
                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    >
                                        Status
                                    </th>
                                    <th
                                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    >
                                        Koneksi
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(data, key) in dataSets"
                                    :key="`dataOpdAktive${key}`"
                                >
                                    <td>{{ data.name }}</td>
                                    <td class="align-middle text-center">
                                        {{ data.status }}
                                    </td>
                                    <td class="align-middle text-center">
                                        {{ data.koneksi }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button
                            :disabled="loading"
                            @click="ping"
                            class="bg-emerald-500 rounded py-1 px-5 text-white disabled:opacity-50"
                        >
                            Ping
                        </button>
                    </div>
                    <!--footer-->
                    <div class="modal-footer">
                        <div class="p-2">
                            <button
                                @click="toggleModal()"
                                class="btn-danger"
                                :disabled="isLoading"
                                type="button"
                            >
                                Keluar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
