import { dbFireStore } from "@/firebase.js";
import { setDoc, doc, getDoc, getDocs } from "firebase/firestore";
const nameTable = "setting";
const TabelSeting = {
    async setData(data) {
        try {
            const set = await setDoc(doc(dbFireStore, nameTable, "data"), data);
            return set;
        } catch (error) {
            const errorCode = error.code;
            throw errorCode;
        }
    },
    async getData(id) {
        try {
            const ref = doc(dbFireStore, nameTable, id);
            const docSnap = await getDoc(ref);
            if (docSnap.exists()) {
                return docSnap.data();
            } else {
                throw { code: "tidak ada data" };
            }
        } catch (error) {
            const errorCode = error.code;
            throw errorCode;
        }
    },
};
export default TabelSeting;