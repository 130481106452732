<script>
import Lansia from "@/components/Sidebar/Lansia.vue";
import NavBar from "@/components/Navbars/v2/DepanNavbar.vue";
import Footer from "@/components/Footers/v2/DepanFooter.vue";
import Helper from "@/store/helper";
import Loading from "@/components/Loading/Default.vue";
import { isLoading } from "@/store/reactive.js";
import { onMounted } from "@vue/runtime-core";
import dataStrore from "@/store/index";

export default {
    setup() {
        onMounted(async () => {
            isLoading.value = true;
            try {
                await dataStrore.dispatch("setting/setSettingDataV2");
                setTimeout(() => {
                    isLoading.value = false;
                }, 1000);
            } catch (error) {
                isLoading.value = false;
            }
        });
        const hedelSelectText = () => {
            if (!dataStrore.state.voice.isVoice) {
                return true;
            }
            Helper.startVoice(getText());
        };
        const getText = () => {
            return Helper.getText();
        };
        return { hedelSelectText };
    },
    components: {
        Loading,
        Lansia,
        NavBar,
        Footer,
    },
    // mounted() {
    //     // this.setSetting();
    // },
    // methods: {
    //     getSetting() {},
    //     // async setSetting() {
    //     //     isLoading.value = true;
    //     //     try {
    //     //         await this.$store.dispatch("setting/setSettingDataV2");
    //     //         setTimeout(() => {
    //     //             isLoading.value = false;
    //     //         }, 1000);
    //     //     } catch (error) {
    //     //         isLoading.value = false;
    //     //     }
    //     // },
    //     // hedelSelectText() {
    //     //     if (!this.$store.state.voice.isVoice) {
    //     //         return true;
    //     //     }
    //     //     Helper.startVoice(this.getText());
    //     // },
    //     // getText() {
    //     //     return Helper.getText();
    //     // },
    // },
};
</script>
<template>
    <Loading />
    <div
        @mouseup="hedelSelectText"
        :class="`
            ${$store.state.modeMalam.isModeMalam ? 'dark' : ''} ${
            $store.state.styleClass.fontSize
        } ${$store.state.styleClass.abuAbu} ${
            $store.state.styleClass.kontraks
        } ${$store.state.styleClass.klise} ${
            $store.state.styleClass.penerangan
        } 
        ${$store.state.styleClass.garisBawah}
        ${$store.state.styleClass.textTegas}
        `"
    >
        <div class="dark:bg-gray-900 bg-white">
            <Lansia />
            <NavBar />
            <div class="mb-32"></div>
            <router-view />
            <Footer />
        </div>
    </div>
</template>
