<script>
// import { VuePdf } from "vue3-pdfjs";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import { onMounted, reactive, toRefs } from "vue";
import { isLoading } from "../../store/reactive";
import Flipbook from "flipbook-vue";
import ApiMajalah from "../../store/api/majalah";
import { getCurrentInstance } from "vue";

import {
    ArrowLeftIcon,
    ArrowRightIcon,
    ZoomInIcon,
    ZoomOutIcon,
} from "vue-tabler-icons";
import { useRoute } from "vue-router";

export default {
    created() {
        GlobalWorkerOptions.workerSrc = "/js/pdf.worker.min.js";
    },
    setup() {
        const app = getCurrentInstance();
        const globalProperties = app.appContext.config.globalProperties;
        const route = useRoute();

        const state = reactive({
            numPages: 0,
            pagesLimit: 10,
            pageTampung: [],
            data: null,
            get pages() {
                const dataSentara = state.pageTampung;
                // console.log("dataSentara.length", dataSentara.length);
                const noDuplicate = Array.from(
                    new Set(dataSentara.map(JSON.stringify))
                ).map(JSON.parse);
                // if (state.pagesLimit < dataSentara.length) {
                //     state.pageTampung = noDuplicate;
                // }

                return noDuplicate
                    .sort((a, b) => {
                        return a.key - b.key;
                    })
                    .map((el) => {
                        return el.gambar;
                    });
            },
            pagesHiRes: [null],
        });

        onMounted(async () => {
            const id = route.params.id;
            await getData(id);
        });

        const getData = async (id) => {
            isLoading.value = true;
            try {
                const { data } = await ApiMajalah.getById(id);
                state.data = data;
            } catch (error) {
            } finally {
                isLoading.value = false;
            }
        };
        const hendelBaca = (pageSet = 1) => {
            // console.log("hendel baca", pageSet, "/js/pdf.worker.min.js");
            isLoading.value = true;
            // const urlSet =
            // ("https://cdn.gorontaloprov.go.id/storage/portal/testing-majalahs/2023/2/9/20230309103625-lItCekrNCbDgLF94TslvAGe91xXhjQnN.jpeg");
            // try {
            const urlSet = state.data.link;
            // console.log(
            //     "hendel baca2",
            //     pageSet,
            //     urlSet,
            //     "/js/pdf.worker.min.js"
            // );
            getDocument(urlSet)
                .promise.then((pdf) => {
                    state.numPages = pdf.numPages;

                    const numPages = state.pagesLimit;
                    // state.pageTampung = [
                    //     { key: 0, gambar: null },
                    //     ...state.pageTampung,
                    // ];
                    // const pages = [];
                    for (let i = pageSet; i <= numPages; i++) {
                        pdf.getPage(i).then((page) => {
                            const canvas = document.createElement("canvas");
                            const viewport = page.getViewport({
                                scale: 1.5,
                            });
                            canvas.width = viewport.width;
                            canvas.height = viewport.height;
                            const ctx = canvas.getContext("2d");

                            page.render({
                                canvasContext: ctx,
                                viewport: viewport,
                            }).promise.then(() => {
                                let imageData = canvas.toDataURL("image/png");
                                state.pageTampung = [
                                    { key: i, gambar: imageData },
                                    ...state.pageTampung,
                                ];
                                // console.log("imageData", imageData);
                            });
                        });
                    }

                    // isLoading.value = false;
                })
                .catch((error) => {
                    console.log("error2", error);
                    globalProperties.$wkToast(
                        "Dokumen Tidak Dapat di baca di sini, Silaja Download Dokumen",
                        {
                            className: "wk-alert",
                        }
                    );
                })
                .finally(() => {
                    isLoading.value = false;
                });
            // console.log("apap", apap);
            // } catch (error) {
            //     console.log("error", error);
            //     globalProperties.$wkToast(
            //         "Dokumen Tidak Dapat di baca di sini, Silaja Download Dokumen",
            //         {
            //             className: "wk-alert",
            //         }
            //     );
            // } finally {
            //     isLoading.value = false;
            // }
        };
        const hendeldidLoadImage = (number) => {
            // console.log("number tidak tau hendel load imahe", number);
            const nilaiCek = state.pagesLimit - number;
            let nilaiAkhir = state.pagesLimit + 10;
            if (nilaiAkhir > state.statenumPages) {
                nilaiAkhir = numPages;
            }
            if (nilaiCek < 2) {
                state.pagesLimit = nilaiAkhir;
                hendelBaca(number);
            }
        };
        return {
            hendelBaca,
            hendeldidLoadImage,
            ...toRefs(state),
        };
    },
    components: {
        // PdfViewer: VuePdf,
        Flipbook,
        ArrowLeftIcon,
        ArrowRightIcon,
        ZoomInIcon,
        ZoomOutIcon,
    },
};
</script>
<template>
    <section class="text-gray-600 body-font lg:mx-60 mx-2 mt-20">
        <div
            class="flex flex-col items-center justify-center gap-1"
            v-if="data"
        >
            <div class="w-full flex justify-center mt-10">
                <button
                    @click="hendelBaca(1)"
                    class="bg-blue-500 px-10 py-2 rounded-lg text-white text-center"
                    type="button"
                >
                    Baca Di sini
                </button>
                <a
                    :href="data.link"
                    target="_blank"
                    class="bg-red-500 px-10 py-2 rounded-lg text-white text-center"
                >
                    Download
                </a>
            </div>
            <div class="w-full mb-5" v-if="pages.length < 1">
                <h3
                    class="text-center text-lg lg:text-2xl dark:text-slate-300 mb-5"
                >
                    {{ data.name }}
                </h3>
                <img class="w-full" :src="data.image" alt="" />
            </div>
            <div class="w-full" v-if="pages.length > 0">
                <Flipbook
                    class="flipbook mb-5"
                    :pages="pages"
                    :startPage="null"
                    ref="flipbook"
                    v-slot="flipbook"
                    @flip-left-end="hendeldidLoadImage"
                    @flip-right-end="hendeldidLoadImage"
                >
                    <div
                        class="flex items-center justify-center gap-2 m-10 dark:text-slate-300"
                    >
                        {{ flipbook.page }} - {{ flipbook.numPages }} dari
                        {{ numPages }}
                    </div>
                </Flipbook>
            </div>
        </div>
        <div
            class="flex flex-col items-center justify-center gap-1"
            v-if="!data"
        >
            <div class="w-full mb-5 mt-5">
                <h3
                    class="text-center text-lg lg:text-2xl dark:text-slate-300 mb-5"
                >
                    Tidak Ada Data
                </h3>
            </div>
        </div>
    </section>
</template>
<style>
.flipbook .viewport {
    /* width: 90vw !important; */
    height: calc(100vh - 50px - 40px) !important;
}
.flipbook .bounding-box {
    box-shadow: 0 0 20px #000;
}
.credit {
    font-size: 12px;
    line-height: 20px;
    margin: 10px;
}
</style>
