<script>
import {
    BrandFacebookIcon,
    BrandInstagramIcon,
    BrandTwitterIcon,
    BrandYoutubeIcon,
    BrandTiktokIcon
} from "vue-tabler-icons";
import Helper from "@/store/helper";
import LoopingNabar from "./LoopingNabar.vue";
// import Menu from "@/store/api/menu.js";
import setting from "../../../store/modules/setting";
import dataStrore from "@/store/index";
import { reactive, toRefs, onMounted, watch } from "vue";
import { Dropdown } from "flowbite";
// import { initCollapses } from "flowbite";

export default {
    setup() {
        // const iniDropDwon = initDropdowns();
        const state = reactive({});
        onMounted(async () => {
            await dataStrore.dispatch("setting/setSettingMenu");
            // initCollapses();
        });
        const hendelBaca = (e) => {
            if (!dataStrore.state.voice.isVoice) {
                return true;
            }
            let text = e.target.textContent;
            if (!text.trim()) {
                text = e.target.getAttribute("alt");
                if (!text) {
                    text = e.target.getAttribute("title");
                }
            }
            Helper.startVoice(text);
        };

        const hendelOpenNavbar = (e) => {
            // set the element that trigger the dropdown menu on click
            const $triggerEl = e.target;
            const idTarget = $triggerEl.getAttribute("data-dropdown-toggle");
            // set the dropdown menu element
            const $targetEl = document.getElementById(idTarget);
            const options = {
                placement: "bottom",
                triggerType: "click",
                offsetSkidding: 0,
                offsetDistance: 10,
                delay: 300,
                // onHide: () => {
                //     console.log("dropdown has been hidden");
                // },
                // onShow: () => {
                //     console.log("dropdown has been shown");
                // },
                // onToggle: () => {
                //     console.log("dropdown has been toggled");
                // },
            };
            const dropdown = new Dropdown($targetEl, $triggerEl, options);
            // console.log("dropdowndropdown", dropdown);
            // console.log("dropdown targetEl", $targetEl);
            // console.log(
            //     "dropdown",
            //     e.target.getAttribute("data-dropdown-toggle")
            // );
            dropdown.toggle();
        };

        return {
            ...toRefs(state),
            hendelBaca,
            hendelOpenNavbar,
        };
    },

    components: {
        BrandFacebookIcon,
        BrandInstagramIcon,
        BrandTwitterIcon,
        BrandYoutubeIcon,
        BrandTiktokIcon,
        LoopingNabar,
    },
    // data() {
    //     return {
    //         menus: null,
    //     };
    // },

    // methods: {
    //     hendelBaca(e) {
    //         if (!this.$store.state.voice.isVoice) {
    //             return true;
    //         }
    //         let text = e.target.textContent;
    //         if (!text.trim()) {
    //             text = e.target.getAttribute("alt");
    //             if (!text) {
    //                 text = e.target.getAttribute("title");
    //             }
    //         }
    //         Helper.startVoice(text);
    //     },
    //     async getNavbar() {
    //         try {
    //             const data = await Menu.getNavbar();
    //             this.menus = data;
    //             // console.log(data);
    //         } catch (error) {
    //             this.$wkToast(error, {
    //                 className: "wk-alert",
    //             });
    //         }
    //     },
    // },
    // mounted() {
    //     this.getNavbar();
    // },
};
</script>

<template v-if="$store.getters['setting/data_menu'].length > 0">
    <div class="fixed w-full z-30 top-0 left-0">
        <!-- {{ dataMenus }} -->
        <nav
            class="bg-white px-2 sm:px-4 py-2.5 dark:bg-gray-900 border-b border-gray-200 dark:border-gray-600"
        >
            <div
                class="container flex flex-wrap items-center justify-between mx-auto"
            >
                <router-link
                    @mouseenter="hendelBaca"
                    to="/"
                    class="flex items-center"
                >
                    <img
                        :src="$store.getters['setting/data_v2'].logo"
                        class="h-6 mr-3 sm:h-9"
                        alt="Icon Logo"
                    />
                    <span
                        class="not-efek self-center text-xl font-semibold whitespace-nowrap dark:text-white"
                        >{{ $store.getters["setting/data_v2"].title }}</span
                    >
                </router-link>
                <div class="flex md:order-2">
                    <a
                        @mouseenter="hendelBaca"
                        :href="$store.getters['setting/data_v2'].facebook"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-1 py-1 text-center mr-3 md:mr-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 m-1"
                        title="Facebook"
                    >
                        <BrandFacebookIcon />
                    </a>
                    <a
                        @mouseenter="hendelBaca"
                        :href="$store.getters['setting/data_v2'].instagram"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-1 py-1 text-center mr-3 md:mr-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 m-1"
                        title="Instagram"
                    >
                        <BrandInstagramIcon />
                    </a>
                    <a
                        @mouseenter="hendelBaca"
                        :href="$store.getters['setting/data_v2'].twitter"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-1 py-1 text-center mr-3 md:mr-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 m-1"
                        title="Twitter"
                    >
                        <BrandTwitterIcon />
                    </a>
                    <a
                        @mouseenter="hendelBaca"
                        :href="$store.getters['setting/data_v2'].youtube"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-1 py-1 text-center mr-3 md:mr-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 m-1"
                        title="You tube"
                    >
                        <BrandYoutubeIcon />
                    </a>
                    <a
                        @mouseenter="hendelBaca"
                        :href="$store.getters['setting/data_v2'].tiktok"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-1 py-1 text-center mr-3 md:mr-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 m-1"
                        title="Tiktok"
                    >
                        <BrandTiktokIcon />
                    </a>
                    <button
                        data-collapse-toggle="navbar-stickyTop"
                        type="button"
                        class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-stickyTop"
                        aria-expanded="false"
                        @mouseenter="hendelBaca"
                    >
                        <span class="sr-only">Buka Main Menu</span>
                        <svg
                            class="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
        </nav>

        <nav
            class="bg-white px-2 sm:px-4 py-2.5 dark:bg-gray-900 border-b border-gray-200 dark:border-gray-600"
        >
            <div
                class="container flex flex-wrap items-center justify-between mx-auto"
            >
                <div></div>
                <div class="flex md:order-2"></div>
                <div
                    class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
                    id="navbar-stickyTop"
                >
                    <ul
                        class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
                    >
                        <!-- v-for="menu in menus" -->
                        <li
                            v-for="menu in $store.getters['setting/data_menu']"
                            :key="`menuNavbar${menu.id}`"
                            class="not-efek"
                        >
                            <template v-if="menu.children.length < 1">
                                <template v-if="menu.url2">
                                    <router-link
                                        @mouseenter="hendelBaca"
                                        :to="menu.url2"
                                        class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                                        >{{ menu.title }}</router-link
                                    >
                                </template>
                                <template v-if="!menu.url2"
                                    ><a
                                        @mouseenter="hendelBaca"
                                        :target="menu.target"
                                        :href="menu.url"
                                        class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                                        >{{ menu.title }}</a
                                    ></template
                                >
                            </template>
                            <template v-if="menu.children.length > 0">
                                <button
                                    @click="hendelOpenNavbar"
                                    :id="`dropdownNavbar1Link-${menu.id}`"
                                    :data-dropdown-toggle="`dropdownNavbar1-${menu.id}`"
                                    @mouseenter="hendelBaca"
                                    class="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-gray-400 dark:hover:text-white dark:focus:text-whsite dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                                    :title="menu.title"
                                >
                                    {{ menu.title }}
                                    <svg
                                        class="w-4 h-4 ml-1"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                                <div
                                    :id="`dropdownNavbar1-${menu.id}`"
                                    class="z-20 hidden font-normal bg-white divide-y divide-gray-100 rounded shadow w-80 dark:bg-gray-700 dark:divide-gray-600"
                                >
                                    <ul
                                        class="py-1 text-sm text-gray-700 dark:text-gray-400"
                                        aria-labelledby="dropdownLargeButton"
                                        @mouseover="hendelBaca"
                                    >
                                        <li
                                            v-for="subMenu1 in menu.children"
                                            :key="`menuSubKey${subMenu1.id}`"
                                            class="not-efek"
                                        >
                                            <template
                                                v-if="
                                                    subMenu1.children.length > 0
                                                "
                                            >
                                                <LoopingNabar
                                                    :data="subMenu1"
                                                />
                                            </template>
                                            <template
                                                v-if="
                                                    subMenu1.children.length < 1
                                                "
                                            >
                                                <template v-if="subMenu1.url2">
                                                    <router-link
                                                        :to="subMenu1.url2"
                                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                        >{{
                                                            subMenu1.title
                                                        }}</router-link
                                                    >
                                                </template>
                                                <template v-if="!subMenu1.url2">
                                                    <a
                                                        :target="
                                                            subMenu1.target
                                                        "
                                                        :href="subMenu1.url"
                                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                        >{{ subMenu1.title }}</a
                                                    >
                                                </template>
                                            </template>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>
