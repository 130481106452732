const modeMalam = {
    namespaced: true,
    state: {
        isModeMalam: false,
    },
    mutations: {
        SET_MODEMALAM(state) {
            state.isModeMalam = !state.isModeMalam;
        },
    },
    actions: {
        setModeMalam({ commit }) {
            commit("SET_MODEMALAM");
        },
    },
};
export default modeMalam;
