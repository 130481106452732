<script>
import IgSlaider from "./IgSlaider.vue";
import ComponenBerita from "./Berita.vue";
import CompoenenMajalah from "./MajalahSlaider.vue";
import CompoenenInfoPublic from "./InfoPublicSlaider.vue";
import {
    BrandFacebookIcon,
    BrandInstagramIcon,
    BrandTwitterIcon,
    BrandYoutubeIcon,
    BrandTiktokIcon
} from "vue-tabler-icons";

export default {
    components: {
        IgSlaider,
        BrandFacebookIcon,
        BrandTwitterIcon,
        BrandYoutubeIcon,
        BrandTiktokIcon,
        ComponenBerita,
        CompoenenMajalah,
        CompoenenInfoPublic
    },
    setup() {}
};
</script>

<template>
    <div class="mx-2 md:mx-20 lg:mx-72">
        <h2
            class="mb-10 mt-10 md:text-5xl text-3x text-center font-bold font-heading tracking-px-n leading-tight dark:text-slate-200"
        >Sosial Media</h2>

        <div class="flex flex-col lg:flex-row items-center justify-center gap-10 mt-5">
            <div class="lg:w-3/6 w-full flex flex-col items-center justify-center gap-5">
                <div
                    class="w-full flex flex-col items-center justify-center shadow-lg rounded-md pt-10 px-2 gap-2 bg-white dark:bg-gray-700"
                >
                    <h3 class="text-center text-xl dark:text-slate-300">Instagram</h3>
                    <hr class="h-px mt-8 bg-gray-200 border-0 dark:bg-gray-700" />
                    <IgSlaider />
                </div>
            </div>
            <div class="lg:w-2/6 w-full flex flex-col gap-5">
                <div class="bg-red-600 py-2 px-2 rounded-lg w-full text-center text-white text-5xl">
                    <a class="group" :href="$store.getters['setting/data_v2'].youtube">
                        <BrandYoutubeIcon :size="45" style="display: inline" />Youtube
                    </a>
                </div>
                <div
                    class="bg-blue-500 py-2 px-2 rounded-lg w-full text-center text-white text-4xl"
                >
                    <a class="group" :href="$store.getters['setting/data_v2'].facebook">
                        <BrandFacebookIcon :size="40" style="display: inline" />Facebook
                    </a>
                </div>
                <div
                    class="bg-blue-400 py-2 px-2 rounded-lg w-full text-center text-white text-5xl"
                >
                    <a class="group" :href="$store.getters['setting/data_v2'].twitter">
                        <BrandTwitterIcon :size="45" style="display: inline" />Twitter
                    </a>
                </div>
                <div class="bg-black py-2 px-2 rounded-lg w-full text-center text-white text-5xl">
                    <a class="group" :href="$store.getters['setting/data_v2'].tiktok">
                        <BrandTiktokIcon :size="45" style="display: inline" />TikTok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
