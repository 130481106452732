<script>
export default {
    props: {
        target: {
            type: Object,
            default: null,
        },
    },
    methods: {
        aksiHapus(target) {
            this.$emit("hapus", target);
        },
        aksiEdit(target) {
            this.$emit("edit", target);
        },
    },
};
</script>

<template>
    <div>
        <button class="btn-danger-sm " title="hapus" @click="aksiHapus(target)">
            Hapus
        </button>
        <button class="btn-warning-sm" title="edit" @click="aksiEdit(target)">
            Edit
        </button>
    </div>
</template>
