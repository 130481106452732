<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import ApiIg from "@/store/api/ig";
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";

import "vue3-carousel/dist/carousel.css";
export default {
    name: "Slaider Ig",
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    setup() {
        const app = getCurrentInstance();
        const globalProperties = app.appContext.config.globalProperties;
        const state = reactive({
            datas: [],
        });
        onMounted(async () => {
            await getData();
        });
        const getData = async () => {
            state.datas = [];
            try {
                const { data } = await ApiIg.getData();
                // console.log(data);
                state.datas = data;
            } catch (error) {
                globalProperties.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        };
        const hendelLink = (data) => {
            return window.open(data.data.permalink, "_blank");
        };
        return {
            ...toRefs(state),
            hendelLink,
        };
    },
};
</script>
<template>
    <div class="w-full hidden lg:block" v-if="true">
        <div class="grid grid-cols-3 gap-2">
            <div class="w-full" v-for="ig in datas" :key="ig">
                <!-- <img
                    @click="hendelLink(ig)"
                    class="bg-white bg-no-repeat bg-center bg-cover"
                    :src="`${
                        ig.data.media_type == 'VIDEO'
                            ? ig.data.thumbnail_url
                            : ig.data.media_url
                    }`"
                /> -->
                <div
                    @click="hendelLink(ig)"
                    class="bg-white bg-no-repeat bg-center bg-cover"
                    :style="`background-image: url('${
                        ig.data.media_type == 'VIDEO'
                            ? ig.data.thumbnail_url
                            : ig.data.media_url
                    }');min-height: 250px;width: 100%;`"
                ></div>
            </div>
        </div>
    </div>
    <div
        class="w-full lg:hidden block"
        style="text-align: -webkit-center"
        v-if="true"
    >
        <div class="grid grid-cols-1">
            <!-- style="width: 300px" -->
            <Carousel
                :settings="{
                    itemsToShow: 1,
                    snapAlign: 'center',
                }"
                :autoplay="5000"
                :wrap-around="true"
            >
                <template #slides>
                    <Slide v-for="ig in datas" :key="ig">
                        <img
                            @click="hendelLink(ig)"
                            class="carousel__item bg-white bg-no-repeat bg-center bg-cover"
                            :src="`${
                                ig.data.media_type == 'VIDEO'
                                    ? ig.data.thumbnail_url
                                    : ig.data.media_url
                            }`"
                        />
                        <!-- <div
                            @click="hendelLink(ig)"
                            class="carousel__item bg-white bg-no-repeat bg-center bg-cover"
                            :style="`background-image: url('${
                                ig.data.media_type == 'VIDEO'
                                    ? ig.data.thumbnail_url
                                    : ig.data.media_url
                            }');`"
                        ></div> -->
                    </Slide>
                </template>

                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
        </div>
    </div>
</template>

<style scoped>
.carousel__item {
    /* min-height: 150px; */
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding: 10px;
}
</style>
