<script>
import LaporanSlaide from "./LaporanSlaide.vue";
import ApiLaporan from "@/store/api/laporan";
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";

export default {
    name: "Laporan",
    components: {
        LaporanSlaide,
    },
    setup() {
        const app = getCurrentInstance();
        const globalProperties = app.appContext.config.globalProperties;
        const state = reactive({
            datas: [],
        });
        onMounted(async () => {
            await getData();
        });
        const getData = async () => {
            state.datas = [];
            try {
                const { data } = await ApiLaporan.getData();
                state.datas = data;
            } catch (error) {
                globalProperties.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        };
        return {
            ...toRefs(state),
        };
    },
};
</script>

<template>
    <div class="mx-2 sm:mx-20 md:mx-72 " v-if="datas.length > 0">
        <h2
            class="mb-10 mt-10 md:text-4xl text-2xl text-center font-bold font-heading tracking-px-n leading-tight dark:text-slate-200"
        >
            Laporan Kinerja Pemerintah
        </h2>
        <div>
            <div
                class="flex md:flex-row items-start justify-center gap-2 flex-col"
            >
                <div class="w-full" v-if="datas.length <= 2"></div>
                <div
                    class="w-full"
                    v-for="categoryLaporan in datas"
                    :key="categoryLaporan"
                >
                    <LaporanSlaide :data="categoryLaporan" />
                </div>
                <div class="w-full" v-if="datas.length <= 2"></div>
            </div>
            <div class="grid grid-col-1">
                <div class="mt-5 mb-5 relative">
                    <router-link
                        to="/laporan"
                        class="bg-blue-500 dark:bg-blue-800 py-2 px-5 rounded-lg text-white dark:text-slate-200 absolute right-0"
                    >
                        Selengkapnya
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
