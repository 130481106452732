import { createStore } from "vuex";
import { authFire } from "@/firebase";
import createPersistedState from "vuex-persistedstate";
import { useCookies } from "vue3-cookies";
import {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    getIdToken,
    signOut,
} from "firebase/auth";
import router from "@/router";
import style from "@/store/modules/styleClass.js";
import grupArray from "@/store/modules/grupArray.js";
import setting from "@/store/modules/setting";
import modeMalam from "@/store/modules/modeMalam";
import voice from "@/store/modules/voice";
const { cookies } = useCookies();
const keySekret = "jalfadgtaeuit";
export default createStore({
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        user: (state) => {
            const myCoookie = cookies.get("token");
            if (myCoookie) {
                try {
                    const use = atob(atob(myCoookie)).split(keySekret)[1];
                    return JSON.parse(use);
                } catch (error) {
                    cookies.set("error", error);
                    console.log(error);
                    return null;
                }
            }
            return null;
        },
    },
    mutations: {
        SET_USER(state, user) {
            // state.user = btoa(btoa(keySekret + JSON.stringify(user)));
            const use = btoa(btoa(keySekret + JSON.stringify(user)));
            const time = 60 * 60;
            // const time = 5;
            const myCoookie = cookies.set("token", use, time);
            state.user = myCoookie;
        },
        CLEAR_USER(state) {
            // state.user = null;
            cookies.remove("token");
        },
    },
    actions: {
        isLogin({ commit }) {
            const auth = authFire;
            return onAuthStateChanged(auth, (user) => {
                if (!user) {
                    this.dispatch("logout");
                    // router.push("/auth");
                }
            });
        },
        logout({ commit }) {
            return signOut(authFire)
                .then(() => {
                    commit("CLEAR_USER");
                    router.push("/auth");
                })
                .catch((error) => {
                    commit("CLEAR_USER");
                    router.push("/auth");
                });
        },
        login({ commit }, data) {
            let email = data.email;
            let password = data.password;
            if (!email || !password) {
                throw "Email dan Password tidak boleh kosong";
            }

            return signInWithEmailAndPassword(authFire, email, password)
                .then((userCredential) => {
                    commit("SET_USER", userCredential.user);
                    router.push("/admin");
                })
                .catch((error) => {
                    const errorCode = error.code;

                    switch (errorCode) {
                        case "auth/invalid-email":
                            throw "Masukan Email Valid";
                            break;
                        case "auth/user-not-found":
                            throw "Penguna Tidak Terdaftar";
                            break;
                        case "auth/wrong-password":
                            throw "Password Salah";
                            break;
                        case "auth/user-disabled":
                            throw "Pengguna non aktif";
                            break;
                        case "auth/network-request-failed":
                            throw "Periksa Koneksi Internat anda.";
                            break;
                        default:
                            throw errorCode;
                            break;
                    }
                });
        },
    },
    // plugins: [createPersistedState()],
    modules: {
        styleClass: style,
        grupArray: grupArray,
        setting: setting,
        modeMalam: modeMalam,
        voice: voice,
    },
});
