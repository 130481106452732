<script>
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";

export default {
    name: "Slaider Destinasi",
    props: {
        datas: {
            type: Array,
            default: []
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const state = reactive({
            datas: []
        });

        const hendelLink = data => {
            // console.log("data", data);
            if (data.in_link) {
                router.push(data.in_link);
            } else {
                return window.open(data.link, "_blank");
            }
        };
        return {
            ...toRefs(state),
            hendelLink
        };
    }
};
</script>
<template>
    <div v-if="datas.length > 0">
        <Carousel :itemsToShow="3.95" :wrapAround="true" :transition="500" :autoplay="5500">
            <Slide v-for="dataSet in datas" :key="dataSet" style="margin-bottom: 100px;">
                <div
                    @click="hendelLink(dataSet)"
                    class="carousel__item bg-white bg-no-repeat bg-center bg-cover"
                    :style="`background-image: url('${dataSet.image}');border-radius: 10px;`"
                >
                    <span
                        class="mb-2 font-bold font-heading md:max-w-xl leading-none dark:text-slate-200 text-white opacity-100 text-shadow-custom"
                    >{{ dataSet.name }}</span>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
                <!-- <Pagination /> -->
            </template>
        </Carousel>
    </div>
</template>
<style scoped>
.carousel__track {
    padding-bottom: 50px !important;
}
.carousel__item {
    min-height: 150px;
    width: 223px;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    /* border-radius: 8px; */
    display: flex;
    justify-content: center;
    align-items: center;
    --tw-shadow: 13px 14px 21px 11px rgb(0 0 0 / 90%) !important;
    --tw-shadow: 13px 14px 21px 11px rgb(0 0 0 / 90%) !important;
    --tw-shadow-colored: 13px 14px 21px 11px rgb(0 0 0 / 90%) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.carousel__slide {
    padding: 5px;
}

.carousel__viewport {
    perspective: 2000px;
}

.carousel__track {
    transform-style: preserve-3d;
}

.carousel__slide--sliding {
    transition: 0.5s;
}

.carousel__slide {
    opacity: 0.9;
    transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
    transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
    opacity: 1;
    transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
    opacity: 1;
    transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1.1);
}
</style>
