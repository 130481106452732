<script>
import FormAplikasi from "@/components/Belakan/Aplikasi/Form.vue";
import DataAplikasi from "@/components/Belakan/Aplikasi/Data.vue";
import { saveAs } from "file-saver";
import TableAplikasi from "@/store/fire/aplikasi";
import dataAplikasiJson from "@/data/aplikasis.json";
import { isLoading } from "@/store/reactive.js";
export default {
    setup() {
        return { dataAplikasiJson, isLoading };
    },
    components: {
        DataAplikasi,
        FormAplikasi,
    },
    data() {
        return {
            loading: false,
            dataGrups: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        tambah() {
            this.$refs.trigerModal.toggleModal();
        },
        async getData() {
            this.dataGrups = [];
            try {
                const grups = await TableAplikasi.useLoads();
                this.dataGrups = grups;
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert",
                });
            }
        },
        exportData() {
            var blob = new Blob([JSON.stringify(this.dataGrups)], {
                type: "application/json;charset=utf-8",
            });
            saveAs(blob, "aplikasis.json");
        },
        async importData() {
            const dataGrup = dataAplikasiJson;
            if (JSON.parse(JSON.stringify(this.dataGrups)).length > 0) {
                return this.$wkToast("Tidak Data di import", {
                    className: "wk-alert",
                });
            }
            if (dataGrup.length < 1) {
                return this.$wkToast("Tidak Data Grup Yang di impor", {
                    className: "wk-alert",
                });
            }

            isLoading.value = true;

            try {
                const importData = await TableAplikasi.imports(dataGrup);
                this.dataGrups = dataGrup;

                isLoading.value = false;
                this.$wkToast("Berhasil Import data");
            } catch (error) {
                isLoading.value = false;
            this.$wkToast(error, {
                className: "wk-alert",
            });
            }
        },
        orderData(key) {
            this.dataGrups = JSON.parse(JSON.stringify(this.dataGrups)).sort(
                (a, b) => {
                    if (a[key] + a[key] < b[key] + b[key]) {
                        return 1;
                    }
                    if (a[key] + a[key] > b[key] + b[key]) {
                        return -1;
                    }
                    return 0;
                }
            );
        },
        windowTop(id) {
            var element = this.$el.querySelector(`#${id}`);
            var top = element.offsetTop;
            window.scrollTo(0, top + 310);
        },
        tambahData(data) {
            const dataGrups = JSON.parse(JSON.stringify(this.dataGrups));
            this.dataGrups = dataGrups.concat(data);
            setTimeout(() => {
                this.windowTop(data.id);
            }, 10);
        },

        editData(data) {
            const dataGrups = JSON.parse(JSON.stringify(this.dataGrups));
            this.dataGrups = dataGrups.map((el) => {
                if (el.id == data.id) {
                    return data;
                } else {
                    return el;
                }
            });
            this.windowTop(data.id);
        },

        edit(data) {
            this.$refs.trigerModal.toggleModal("edit", data);
        },
        hapusData(data) {
            const dataGrups = JSON.parse(JSON.stringify(this.dataGrups));
            const find = dataGrups.find((el) => {
                return el.id == data;
            });
            const filter = dataGrups.filter((el) => {
                return el != find;
            });
            this.dataGrups = filter;
        },
    },
};
</script>

<template>
    <div>
        <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        >
            <div class="rounded-t bg-white mb-0 px-6 py-6">
                <div class="text-center flex justify-end">
                    <div>
                        <h6 class="text-blueGray-700 text-xl font-bold">
                            <!-- Grups -->
                        </h6>
                    </div>
                    <button @click="tambah" class="btn-success" type="button">
                        Tambah
                    </button>
                    <button
                        :disabled="isLoading"
                        @click="importData"
                        class="btn-success"
                        type="button"
                    >
                        <!-- Import -->
                        Set Default Data
                    </button>
                    <button
                        @click="exportData"
                        class="btn-success"
                        type="button"
                    >
                        export
                    </button>
                </div>
            </div>
        </div>
        <DataAplikasi :datas="dataGrups" @hapus-data="hapusData" @edit="edit" />
        <teleport to="#teleportasi">
            <FormAplikasi
                ref="trigerModal"
                @tambah-data="tambahData"
                @edit-data="editData"
            />
        </teleport>
    </div>
</template>
