<script>
import logoKominfo124 from "@/assets/img/custom/kominfo_logo_124.png";
import logoGorntalo124 from "@/assets/img/custom/gorontalo_logo_124.png";
export default {
    data() {
        return {
            navbarOpen: false,
            logoKominfo124,
            logoGorntalo124,
        };
    },
    methods: {
        setNavbarOpen: function () {
            this.navbarOpen = !this.navbarOpen;
        },
    },
    components: {},
};
</script>
<template>
    <nav
        class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
    >
        <div
            class="container px-4 mx-auto flex flex-wrap items-center justify-between"
        >
            <div
                class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
            >
                <router-link
                    class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                    to="/"
                >
                    {{ $store.getters["setting/data"].judul }}
                </router-link>
            </div>
            <div
                class="lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none"
                id="example-navbar-warning"
                :class="[navbarOpen ? 'block rounded shadow-lg' : 'hidden']"
            >
                <ul class="flex flex-col lg:flex-row list-none mr-auto">
                    <li class="flex items-center">
                        <router-link
                            class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                            to="/auth/login"
                        >
                            Login
                        </router-link>
                    </li>
                </ul>
                <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
                    <li class="flex items-center">
                        <router-link to="/">
                            <img
                                :src="logoGorntalo124"
                                class="h-11 w-11"
                                alt="logo gorontalo"
                            />
                        </router-link>
                    </li>
                    <li class="flex items-center">
                        <router-link to="/">
                            <img
                                :src="logoKominfo124"
                                class="h-10 w-10"
                                alt="logo dinas kominfo dan statistik provinsi gorontalo"
                            />
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
