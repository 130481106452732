import axios from "axios";
import { apiPortal } from "../../config";
const point = apiPortal + "/info-terkini";
const InfoTerkini = {
    async getDataLimit(limit = 5) {
        try {
            const data = await axios.get(point);
            return data.data;
        } catch (error) {
            if (errro.response) {
                throw error.response.data.message;
            }
            throw error;
        }
    },
};
export default InfoTerkini;
