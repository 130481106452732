<script>
import { reactive, onMounted, toRefs } from "vue";
import SlaiderDestinasi from "@/components/Slaider/v2/Destinasi.vue";
import ApiklipingBerita from "@/store/api/klipingBerita";

export default {
    components: {
        SlaiderDestinasi
    },
    setup() {
        const state = reactive({
            datas: []
        });
        onMounted(async () => {
            await getData();
        });
        const getData = async () => {
            state.datas = [];
            try {
                const { data } = await ApiklipingBerita.getData();
                state.datas = data;
            } catch (error) {
                this.$wkToast(error, {
                    className: "wk-alert"
                });
            }
        };
        return {
            ...toRefs(state)
        };
    }
};
</script>
<template>
    <!-- class="mb-20 mt-10"> -->
    <div class="grid grid-cols-1 gap-4 w-full">
        <section v-if="datas.length > 0" class="mb-20 mt-10">
            <SlaiderDestinasi :datas="datas" />
            <div class="mt-5 relative">
                <router-link
                    to="/kliping-berita"
                    class="bg-blue-500 dark:bg-blue-800 py-2 px-5 rounded-lg text-white dark:text-slate-200 absolute right-0"
                >Selengkapnya</router-link>
            </div>
        </section>
    </div>
</template>
