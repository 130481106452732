const voice = {
    namespaced: true,
    state: {
        isVoice: false,
    },
    mutations: {
        SET_VOICE(state, bool) {
            state.isVoice = bool;
        },
    },
    actions: {
        setVoice({ commit }, data) {
            commit("SET_VOICE", data);
        },
    },
};
export default voice;
