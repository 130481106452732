import { createApp } from "vue";
import store from "@/store";

import Toast from "vue3-toast-single";
import "vue3-toast-single/dist/toast.css";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import "@/assets/styles/build.css";

import App from "@/App.vue";

// meta inport
import { createHead } from "@unhead/vue";
// import { createHead } from "@vueuse/head";
// import { createMetaManager } from "vue-3-meta";
// import VueMetaPlugin from "vue-meta";
// import { createMetaManager } from "vue-meta";
// import { createMetaPlugin } from "vite-plugin-meta";

// layouts
import router from "@/router";
//  start pwa
// import { useRegisterSW } from "virtual:pwa-register/vue";

const intervalMS = 60 * 60 * 1000;

// const updateServiceWorker = useRegisterSW({
//     onRegistered(r) {
//         r &&
//             setInterval(() => {
//                 r.update();
//             }, intervalMS);
//     },
// });
// end pwa
const app = createApp(App);
// meta setup
const head = createHead();
app.use(head);
// const head = createHead();
// app.use(head);
// const metaManager = createMetaManager();
// app.use(metaManager);
// app.use(createMetaPlugin());
app.use(router);
app.use(store);

app.use(Toast, {
    verticalPosition: "top",
    duration: 3000,
    // horizontalPosition: "right",
    closeable: true,
});
app.use(Loading);

// className: "wk-warn",
// className: "wk-info",
// className: "wk-alert",
app.mount("#app");
